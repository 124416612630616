import {
  DELETE_BOOKMARKS_FAILED,
  // DELETE_BOOKMARKS_INIT,
  DELETE_BOOKMARKS_SUCCESS,
  GET_BOOKMARKS_BY_TYPE_FAILED,
  GET_BOOKMARKS_BY_TYPE_INIT,
  GET_BOOKMARKS_BY_TYPE_SUCCESS,
  GET_BOOKMARKS_LIST_FAILED,
  GET_BOOKMARKS_LIST_SUCCESS,
  SAVE_BOOKMARKS_FAILED,
  SAVE_BOOKMARKS_INIT,
  SAVE_BOOKMARKS_SUCCESS,
} from "redux/actionTypes/bookmarks.actionTypes";

const iniitialState = {
  bookmarkList: {},
  bookmarkTypeData: null,
  error: false,
  message: "",
  loading: false,
};

export default function BookmarkReducer(state = iniitialState, action) {
  switch (action.type) {
    // get bookmarks by type
    case GET_BOOKMARKS_BY_TYPE_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKMARKS_BY_TYPE_SUCCESS:
      return {
        ...state,
        error: false,
        message: action.payload.message,
        bookmarkTypeData: action.payload.bookmarkTypeData,
      };
    case GET_BOOKMARKS_BY_TYPE_FAILED:
      return {
        ...state,
        error: true,
        message: action.payload.message,
        loading: false,
      };

    // get bookmarks list data
    case GET_BOOKMARKS_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKMARKS_LIST_SUCCESS:
      return {
        ...state,
        error: false,
        bookmarkList: action.payload.bookmarkList,
        message: action.payload.message,
      };
    case GET_BOOKMARKS_LIST_FAILED:
      return {
        ...state,
        error: true,
        message: action.payload.message,
      };

    // save bookmarks
    case SAVE_BOOKMARKS_INIT:
      return {
        ...state,
        loading: true,
      };
    case SAVE_BOOKMARKS_SUCCESS:
      return {
        ...state,
        error: false,
        message: action.payload.message,
      };
    case SAVE_BOOKMARKS_FAILED:
      return {
        ...state,
        error: true,
        message: action.payload.message,
      };

    // delete bookmarks
    // case DELETE_BOOKMARKS_INIT:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    case DELETE_BOOKMARKS_SUCCESS:
      return {
        ...state,
        error: false,
        message: action.payload.message,
      };
    case DELETE_BOOKMARKS_FAILED:
      return {
        ...state,
        error: true,
        message: action.payload.message,
      };

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
