export const START_CALL = "START_CALL_QUIZ";
export const CALL_COMPLETED = "CALL_COMPLETED_QUIZ";
export const CATCH_ERROR = "CATCH_ERROR_QUIZ";

// commands
export const FETCH_ACTIVE_QUIZZES = "FETCH_ACTIVE_QUIZZES";
export const FETCH_PREVIOUS_QUIZZES = "FETCH_PREVIOUS_QUIZZES";
export const FETCH_UPCOMING_QUIZZES = "FETCH_UPCOMING_QUIZZES";
export const FETCH_QUIZ_PLAY = "FETCH_QUIZ_PLAY";
export const SELECT_OPTION = "SELECT_OPTION";
export const SAVE_USER_RESPONSE = "SAVE_USER_RESPONSE";
export const FETCH_QUIZ_SCORE = "FETCH_QUIZ_SCORE";
export const FETCH_QUIZ_LEADERBOARD = "FETCH_QUIZ_LEADERBOARD";
export const FETCH_REWARD_HISTORY= "FETCH_REWARD_HISTORY";
export const FETCH_REWARD_SUMMARY = "FETCH_REWARD_SUMMARY";
export const CLOSE_LIVE_QUIZ="CLOSE_LIVE_QUIZ";

// export const FETCH_CURRENT_QUIZ = "FETCH_CURRENT_QUIZ";

// events
export const QUIZZES_FETCHED = "QUIZZES_FETCHED";
