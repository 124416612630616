// get feedback list

export const GET_FEEDBACK_LIST_INIT = "GET_FEEDBACK_LIST_INIT";
export const GET_FEEDBACK_LIST_SUCCESS = "GET_FEEDBACK_LIST_SUCCESS";
export const GET_FEEDBACK_LIST_FAILED = "GET_FEEDBACK_LIST_FAILED";

// SAVE Feedback
export const SAVE_FEEDBACK_INIT = "SAVE_FEEDBACK_INIT";
export const SAVE_FEEDBACK_SUCCESS = "SAVE_FEEDBACK_SUCCESS";
export const SAVE_FEEDBACK_FAILED = "SAVE_FEEDBACK_FAILED";

//clear feedback
export const CLEAR_FEEDBACK_SUCCESS = "CLEAR_FEEDBACK_SUCCESS";
