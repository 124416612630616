import {
  START_CALL,
  CATCH_ERROR,
  FETCH_ACTIVE_QUIZZES,
  FETCH_PREVIOUS_QUIZZES,
  FETCH_UPCOMING_QUIZZES,
  QUIZZES_FETCHED,
  FETCH_QUIZ_PLAY,
  SELECT_OPTION,
  SAVE_USER_RESPONSE,
  CALL_COMPLETED,
  FETCH_QUIZ_SCORE,
  FETCH_QUIZ_LEADERBOARD,
  FETCH_REWARD_HISTORY,
  FETCH_REWARD_SUMMARY,
  CLOSE_LIVE_QUIZ
  // FETCH_CURRENT_QUIZ
} from "redux/actionTypes/quiz.actionTypes";

//quiz actions
const createAction = (type, payload = null) => ({
  type,
  payload,
});

export const startCall = () => createAction(START_CALL);
export const callCompleted = (payload) => createAction(CALL_COMPLETED, payload);
export const catchError = (error) => createAction(CATCH_ERROR, { error });

export const fetchActiveQuizzes = (payload) =>
  createAction(FETCH_ACTIVE_QUIZZES, payload);

export const fetchPreviousQuizzes = (payload) =>
  createAction(FETCH_PREVIOUS_QUIZZES, payload);

export const fetchRewardHistory = (payload) =>
  createAction(FETCH_REWARD_HISTORY, payload);

export const fetchRewardSummary = (payload) =>
  createAction(FETCH_REWARD_SUMMARY, payload);

export const fetchUpcomingQuizzes = (payload) =>
  createAction(FETCH_UPCOMING_QUIZZES, payload);

export const fetchQuizPlay = (payload) =>
  createAction(FETCH_QUIZ_PLAY, payload);

export const selectOption = (payload) => createAction(SELECT_OPTION, payload);

export const saveUserResponse = (payload) =>
  createAction(SAVE_USER_RESPONSE, payload);

export const fetchQuizScore = (payload) =>
  createAction(FETCH_QUIZ_SCORE, payload);

export const fetchQuizLeaderboard = (payload) =>
  createAction(FETCH_QUIZ_LEADERBOARD, payload);

export const quizClose = (payload) =>
  createAction(CLOSE_LIVE_QUIZ, payload);

// events
export const quizzesFetched = (payload) =>
  createAction(QUIZZES_FETCHED, payload);

// export const fetchCurrentQuiz = (payload) =>
//   createAction(FETCH_CURRENT_QUIZ,payload)
