import {
  START_CALL,
  CATCH_ERROR,
  QUIZZES_FETCHED,
  SELECT_OPTION,
  CALL_COMPLETED,
} from "redux/actionTypes/quiz.actionTypes";

const iniitialState = {
  error: false,
  loading: false,
  message: null,
  activeQuizzes: [],
  previousQuizzes: [],
  upcomingQuizzes: [],
  quizPlay: {
    quizInfo: {},
    timeStamps: [],
    questions: [],
    userResponse: {}
  },
  quizScore: { scores: [] },
  quizLeaderboard: { scores: [] }
};

export default function QuizReducer(state = iniitialState, action) {
  switch (action.type) {
    // Do something here based on the different types of actions

    case START_CALL:
      return {
        ...state,
        message: null,
        loading: true,
        error: false,
      };

    case CALL_COMPLETED:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false,
      };

    case CATCH_ERROR:
      return {
        ...state,
        message: action.payload.error,
        loading: false,
        error: true,
      };

    case QUIZZES_FETCHED:
      return {
        ...state,
        ...action.payload,
      };

    case SELECT_OPTION:
      return {
        ...state,
        quizPlay: { ...state.quizPlay, ...action.payload },
        loading: false,
      };

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
