export const Auth_INIT = "auth/init";

// Signup Actiontypes
export const AUTH_SIGNUP_INIT = "AUTH_SIGNUP_INIT";
export const AUTH_SIGNUP_CHECK_EMAIL = "AUTH_SIGNUP_CHECK_EMAIL";
export const AUTH_SIGNUP_OTP_INIT = "AUTH_SIGNUP_OTP_INIT";
export const AUTH_SIGNUP_USER_DETAILS = "AUTH_SIGNUP_USER_DETAILS";
export const AUTH_SIGNUP_SUCCESS = "AUTH_SIGNUP_SUCCESS";
export const AUTH_SIGNUP_FAILED = "AUTH_SIGNUP_FAILED";
export const AUTH_SIGNUP_SUBMIT = "AUTH_SIGNUP_SUBMIT";

// Login ActionTypes
export const AUTH_LOGGEDIN_INIT = "AUTH_LOGGEDIN_INIT";
export const AUTH_LOGGEDIN_SUCCESS = "AUTH_LOGGEDIN_SUCCESS";
export const AUTH_LOGGEDIN_FAILED = "AUTH_LOGGEDIN_FAILED";

export const AUTH_OTP_INIT = "AUTH_OTP_INIT";
export const AUTH_OTP_SUCCESS = "AUTH_OTP_SUCCESS";
export const AUTH_OTP_FAILED = "AUTH_OTP_FAILED";

export const AUTH_OTP_RESEND_INIT = "AUTH_OTP_RESEND_INIT";
export const AUTH_OTP_RESEND_SUCCESS = "AUTH_OTP_RESEND_SUCCESS";
export const AUTH_OTP_RESEND_FAILED = "AUTH_OTP_RESEND_FAILED";

export const AUTH_OTP_SUBMIT_INIT = "AUTH_OTP_SUBMIT_INIT";
export const AUTH_OTP_SUBMIT_SUCCESS = "AUTH_OTP_SUBMIT_SUCCESS";
export const AUTH_OTP_SUBMIT_FAILED = "AUTH_OTP_SUBMIT_FAILED";

export const AUTH_SIGNIN_INIT = "AUTH_SIGNIN_INIT";
export const AUTH_SIGNIN_SUBMIT = "AUTH_SIGNIN_SUBMIT";
export const AUTH_SIGNIN_SUCCESS = "AUTH_SIGNIN_SUCCESS";
export const AUTH_SIGNIN_FAILED = "AUTH_SIGNIN_FAILED";

// Signout ActionType
export const AUTH_SIGNOUT = "AUTH_SIGNOUT";
export const AUTH_DELETE_ACCOUNT = "AUTH_DELETE_ACCOUNT"

// Mobile POP UP
export const AUTH_MOBILE_POPUP_INIT = "AUTH_MOBILE_POPUP_INIT";
export const AUTH_MOBILE_POPUP_SUCCESS = "AUTH_MOBILE_POPUP_SUCCESS";
export const AUTH_MOBILE_POPUP_FAILED = "AUTH_MOBILE_POPUP_FAILED";
