import {
  START_CALL,
  CATCH_ERROR,
  // SELECT_OPTION,
  CALL_COMPLETED,
} from "redux/actionTypes/review.actionTypes";

const iniitialState = {
  error: false,
  loading: false,
  message: null,
  rateConfirm: false,
  rateResult: {},
  likeResult: {},
};

export default function ReviewReducer(state = iniitialState, action) {
  switch (action.type) {
    // Do something here based on the different types of actions

    case START_CALL:
      return {
        ...state,
        message: null,
        loading: true,
        error: false,
      };

    case CALL_COMPLETED:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false,
      };

    case CATCH_ERROR:
      return {
        ...state,
        message: action.payload.error,
        loading: false,
        error: true,
      };

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
