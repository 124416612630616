export const PHONE_LOGIN = "1";
export const FACEBOOK_LOGIN = "2";
export const GOOGLE_LOGIN = "3";


export const getTokenFromCookies = (cookie) => {
    let crimetakCookie = cookie.replace(/(?:(?:^|.*;\s*)lallantop\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    const decodedString = decodeURIComponent(crimetakCookie);
    if (crimetakCookie && typeof crimetakCookie === "string") {
      crimetakCookie = JSON.parse(decodedString);
      if (crimetakCookie.encryptedToken) {
        return crimetakCookie.encryptedToken;
      }
    } else if (typeof crimetakCookie === "object") {
      if (crimetakCookie.encryptedToken) {
        return crimetakCookie.encryptedToken;
      }
    }
    return null
  }
  export const getUserIdFromCookies = (cookie) => {
    let crimetakCookie = cookie.replace(/(?:(?:^|.*;\s*)lallantop\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    const decodedString = decodeURIComponent(crimetakCookie);
    if (crimetakCookie && typeof crimetakCookie === "string") {
      crimetakCookie = JSON.parse(decodedString);
      if (crimetakCookie.userId) {
        return crimetakCookie.userId
      }
    } else if (typeof crimetakCookie === "object") {
      if (crimetakCookie.userId) {
        return crimetakCookie.userId
      }
    }
    return null
  }

export const errorHelper = {
  "Your account has been blocked, Please contact Lallantop Team": "आपका अकाउंट ब्लॉक कर दिया गया है, कृपया लल्लनटॉप टीम से संपर्क करें",
  "Your account has been suspended, Please contact Lallantop Team": "आपका अकाउंट ससपेंड कर दिया गया है, कृपया लल्लनटॉप टीम से संपर्क करें",
  "OTP resend limit exceeded": "OTP पुन: भेजने की सीमा पार हो गई",
  "Please enter the OTP sent on your mobile number.": "कृपया अपने मोबाइल नंबर पर भेजे गए ओटीपी को दर्ज करें।",
  "Otp does not match": "यह OTP ग़लत है. फिर से कोशिश करें",
  "OTP cannot be sent more than 3 times in 1 hour": "OTP 1 घंटे में 3 बार से ज्यादा नहीं भेजा जा सकता",
  "OTP cannot be sent more than 1 times in 2 minutes": "OTP 2 मिनट में 1 बार से अधिक नहीं भेजा जा सकता",
  "OTP limit exceeded": "OTP भेजने की सीमा पार हो गई"
}