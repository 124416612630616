import { getAuthorizedApi } from "api/apiInstance";
import { baseUrl } from "api/constants";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getTokenFromCookies } from "constants/loginConstants";
import {
  FOLLOW_UNFOLLOW_HASTAGS_FAILED,
  FOLLOW_UNFOLLOW_HASTAGS_INIT,
  FOLLOW_UNFOLLOW_HASTAGS_SUCCESS,
  HASHTAGS_GET_FAILED,
  HASHTAGS_GET_INIT,
  HASHTAGS_GET_SUCCESS,
} from "redux/actionTypes/hashtag.actionTypes";
import { logger } from "logging/log-util";
// import { SHOW_MODAL_POPUP } from "../actionTypes/common.actionTypes";

function* getHashtag() {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // })
  //   .then((res) => res.json())
  //  /* .then(({ encryptedToken }) => encryptedToken || "")*/;
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  //   if(!Object.keys(token).length) {
  //     token = null;
  //   }

  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/hashtag?limit=10&skip=1`
    );
    if (data.status) {
      yield put({
        type: HASHTAGS_GET_SUCCESS,
        payload: {
          entity: data?.result,
        },
      });
    } else {
      yield put({
        type: HASHTAGS_GET_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    // yield put({
    //   type: SHOW_MODAL_POPUP,
    //   payload: {
    //     error: true,
    //     message: new Error(e).message,
    //   },
    // });
  }
}

function* followHastag({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // })
  //   .then((res) => res.json())
  //  /* .then(({ encryptedToken }) => encryptedToken || "")*/;
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/hashtag/follow-unfollow`,
      payload
    );
    if (data.status) {
      yield put({
        type: FOLLOW_UNFOLLOW_HASTAGS_SUCCESS,
        payload: {
          message: data.message,
        },
      });
      yield put({
        type: HASHTAGS_GET_INIT,
        token,
      });
    } else {
      yield put({
        type: FOLLOW_UNFOLLOW_HASTAGS_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    // yield put({
    //   type: SHOW_MODAL_POPUP,
    //   payload: {
    //     error: true,
    //     message: new Error(e).message,
    //   },
    // });
  }
}

function* hashtagSaga() {
  yield takeLatest(HASHTAGS_GET_INIT, getHashtag);
  yield takeEvery(FOLLOW_UNFOLLOW_HASTAGS_INIT, followHastag);
}

export default hashtagSaga;
