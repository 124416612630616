// get Bookmarks list actionType
export const GET_BOOKMARKS_LIST_INIT = "GET_BOOKMARKS_LIST_INIT";
export const GET_BOOKMARKS_LIST_SUCCESS = "GET_BOOKMARKS_LIST_SUCCESS";
export const GET_BOOKMARKS_LIST_FAILED = "GET_BOOKMARKS_LIST_FAILED";

// get Bookmarks list actionType
export const GET_BOOKMARKS_BY_TYPE_INIT = "GET_BOOKMARKS_BY_TYPE_INIT";
export const GET_BOOKMARKS_BY_TYPE_SUCCESS = "GET_BOOKMARKS_BY_TYPE_SUCCESS";
export const GET_BOOKMARKS_BY_TYPE_FAILED = "GET_BOOKMARKS_BY_TYPE_FAILED";

// SAVE Bookmarks actionType
export const SAVE_BOOKMARKS_INIT = "SAVE_BOOKMARKS_INIT";
export const SAVE_BOOKMARKS_SUCCESS = "SAVE_BOOKMARKS_SUCCESS";
export const SAVE_BOOKMARKS_FAILED = "SAVE_BOOKMARKS_FAILED";

// delete Bookmarks actionType
export const DELETE_BOOKMARKS_INIT = "DELETE_BOOKMARKS_INIT";
export const DELETE_BOOKMARKS_SUCCESS = "DELETE_BOOKMARKS_SUCCESS";
export const DELETE_BOOKMARKS_FAILED = "DELETE_BOOKMARKS_FAILED";
