import React, { useEffect, useState } from "react";
import { InputGroup, Button, FormControl } from "react-bootstrap";
// import Link from "next/link";
import { useRouter } from "next/router";
import { getLanguage } from "localization/i8intl";
import DOMPurify from "dompurify";

const SearchBox = ({ searchText, setSearchText }) => {
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(getLanguage());
  }, []);
  const router = useRouter();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // ClevertapReact.event("header_search");
      // ClevertapReact.event("search",{"Search text":searchText});
      router.push({
        pathname: "/search",
        query: { title: searchText },
      });
      setSearchText("");
    }
  };

  return (
    <>
      <div className="sarch-box">
        <InputGroup className="mb-3">
          <FormControl
            // placeholder="Search here"
            placeholder={language === "hi" ? "यहाँ सर्च करें" : "Search here"}
            aria-label="Search here"
            aria-describedby="basic-addon2"
            onChange={(event) => {
              const sanitizedValue = DOMPurify.sanitize(event.target.value)
              setSearchText(sanitizedValue)
            }}
            value={searchText}
            onKeyDown={handleKeyDown}
            name="search-text"
          />
          {searchText.length > 0 && (
            <a
              // href={{
              //   pathname: "/search",
              //   query: { title: searchText },
              // }}
              href={`/search?title=${searchText}`}

              passHref
            >
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={() => {
                  setSearchText("");
                  // ClevertapReact.event("header_search");
                }}
              >
                <span className="icon-search srch-icon"></span>
              </Button>
            </a>
          )}
        </InputGroup>
      </div>
    </>
  );
};

export default SearchBox;
