import {
  CLEAR_FEEDBACK_SUCCESS,
  GET_FEEDBACK_LIST_FAILED,
  GET_FEEDBACK_LIST_INIT,
  GET_FEEDBACK_LIST_SUCCESS,
  SAVE_FEEDBACK_FAILED,
  SAVE_FEEDBACK_SUCCESS,
} from "../actionTypes/feedbackTypes";

const iniitialState = {
  feedbackList: [],
  message: "",
  error: false,
  loading: false,
  feedbackSuccess: false,
};

export default function FeedbackReducer(state = iniitialState, action) {
  switch (action.type) {
    // get feedback list
    case GET_FEEDBACK_LIST_INIT:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case GET_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        message: action.payload.message,
        feedbackList: action.payload.feedbacks,
      };
    case GET_FEEDBACK_LIST_FAILED:
      return {
        ...state,
        error: true,
        message: action.payload.message,
        loading: false,
      };

    case SAVE_FEEDBACK_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        message: action.payload.message,
        feedbackSuccess: true,
      };
    case SAVE_FEEDBACK_FAILED:
      return {
        ...state,
        error: true,
        message: action.payload.message,
        loading: false,
        feedbackSuccess: false,
      };

    case CLEAR_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackSuccess: false,
      };

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
