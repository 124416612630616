import {
  HIDE_MODAL_POPUP,
  SHOW_MODAL_POPUP,
  REFRESH_PAGE_INIT,
  ROUTE_CHANGED,
  EPISODE_ID_CHANGED,
} from "../actionTypes/common.actionTypes";

const iniitialState = {
  show: false,
  error: false,
  message: "",
  refreshedDate: "",
  callbackurl: "",
  transition: false,
};

export default function CommonReducer(state = iniitialState, action) {
  switch (action.type) {
    // Do something here based on the different types of actions

    case SHOW_MODAL_POPUP:
      return {
        ...state,
        show: true,
        message: action.payload.message,
        callbackurl: action.payload.callbackurl,
        error: action.payload.error,
      };

    case ROUTE_CHANGED:
      //console.log("router dispatched", action);
      return {
        ...state,
        transition: action.payload,
      };

    case HIDE_MODAL_POPUP:
      return {
        ...state,
        show: false,
        message: "",
        callbackurl: "",
        error: "",
      };
    case REFRESH_PAGE_INIT:
      return {
        ...state,
        refreshedDate: new Date().toISOString(),
      };

    case EPISODE_ID_CHANGED:
      //console.log("router dispatched", action);
      return {
        ...state,
        transition: action.payload,
      };

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
