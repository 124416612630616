import {
  GET_SEARCH_DROPDOWN_SUCCESS,
  GET_SEARCH_DROPDOWN_FAILED,
  RESET_SEARCH_DROPDOWN
} from "redux/actionTypes/search.actionTypes";

const iniitialState = {
  dropdown: {},
  result: {},
  error: false,
  message: "",
};

export default function SearchReducer(state = iniitialState, action) {
  switch (action.type) {
    // Do something here based on the different types of actions

    case RESET_SEARCH_DROPDOWN:
      return iniitialState;

    case GET_SEARCH_DROPDOWN_SUCCESS:
      return {
        ...state,
        dropdown: action.payload.dropdown,
        error: false,
      };

    case GET_SEARCH_DROPDOWN_FAILED:
      return {
        ...state,
        ...action.payload,
        message: action.payload.message,
        error: true,
      };
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
