import {
  START_CALL,
  CATCH_ERROR,
  CALL_COMPLETED,
  RATE_APP,
  LIKE_APP,
} from "redux/actionTypes/review.actionTypes";

//quiz actions
const createAction = (type, payload = null) => ({
  type,
  payload,
});

export const startCall = () => createAction(START_CALL);
export const callCompleted = (payload) => createAction(CALL_COMPLETED, payload);
export const catchError = (error) => createAction(CATCH_ERROR, { error });

export const rateApp = (payload) => createAction(RATE_APP, payload);
export const likeApp = (payload) => createAction(LIKE_APP, payload);
