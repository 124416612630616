import {
  GET_ALL_FEATURED_POST_FAILED,
  GET_ALL_FEATURED_POST_SUCCESS,
  GET_ALL_POST_FAILED,
  GET_ALL_POST_SUCCESS,
  GET_POST_BY_ID_FAILED,
  GET_POST_BY_ID_SUCCESS,
  GET_TRENDING_POST_FAILED,
  GET_TRENDING_POST_SUCCESS,
  LIVESHOWPOST_GET_FAILED,
  // LIVESHOWPOST_GET_INIT,
  LIVESHOWPOST_GET_SUCCESS,
  LIVE_STREAM_DATA_FAILED,
  LIVE_STREAM_DATA_SUCCESS,
  POST_LIKE_FAILED,
  POST_LIKE_INIT,
  POST_LIKE_SUCCESS,
  POST_VIEW_COUNT_FAILED,
  POST_VIEW_COUNT_SUCCESS,
  GET_VISUALSTORY_BY_ID_SUCCESS,
  GET_VISUALSTORY_BY_ID_FAILED,
  CLEAR_VISUALSTORY,
  HOME_CATEGORY_POST,
  HOME_TOP_SHOWS,
  GET_RELATED_ARTICLES_SUCCESS,
  LIVEPOSTSOCKET_GET_SUCCESS,
  GET_STATISTICS_INIT,
} from "redux/actionTypes/post.actionTypes";
import { STORY_LIKE_SUCCESS } from "redux/actionTypes/stories.actionTypes";

const iniitialState = {
  liveShowpostData: {
    totalCount: 0,
    scheduledCount: 0,
    entities: [],
    liveLinkHashmap: {},
  },
  postDetails: {},
  trendingPosts: [],
  allPosts: [],
  featuredPosts: [],
  allCategoryHomePosts: [],
  homeTopShows: [],
  error: false,
  message: "",
  storyById: {},
  visualStory: null,
  visualStoryError: false,
  alreadyPlaying: false,
  alreadyLiveFetched: false,
  statistics: {},
};

export default function PostReducer(state = iniitialState, action) {
  switch (action.type) {
    // Do something here based on the different types of actions

    case LIVESHOWPOST_GET_SUCCESS: {
      console.log(" LIVESHOWPOST_GET_SUCCESS 1");
      // if (state.alreadyLiveFetched) {
      //   const prevState = state.liveShowpostData;
      //   if (prevState.entities !== action.payload.entity?.entities) {
      //     let isUniqueLive = false;
      //     const newLiveItems = action.payload.entity?.entities;
      //     for (let index = 0; index < newLiveItems?.length; index++) {
      //       if (
      //         !prevState.liveLinkHashmap.hasOwnProperty(newLiveItems[index]._id)
      //       ) {
      //         isUniqueLive = true;
      //         break;
      //       }
      //     }
      //     if (
      //       isUniqueLive ||
      //       action.payload.entity?.entities.length === 0 ||
      //       action.payload.entity?.entities.length < prevState.entities.length
      //     ) {
      //       let hashmap = {};
      //       newLiveItems?.forEach(
      //         (live) =>
      //           (hashmap[live._id] = live.vimeoResponse.m3u8_playback_url)
      //       );
      //       return {
      //         ...state,
      //         liveShowpostData: {
      //           ...action.payload.entity,
      //           liveLinkHashmap: hashmap,
      //         },
      //         error: false,
      //         alreadyLiveFetched: true,
      //       };
      //     } else {
      //       return state;
      //     }
      //   } else {
      //     return state;
      //   }
      // } else {
      //   let hashmap = {};
      //   action.payload.entity?.entities?.forEach(
      //     (live) =>
      //       (hashmap[live?._id] = live?.vimeoResponse?.m3u8_playback_url)
      //   );
      //   return {
      //     ...state,
      //     liveShowpostData: {
      //       ...action?.payload?.entity,
      //       liveLinkHashmap: hashmap,
      //     },
      //     error: false,
      //     alreadyLiveFetched: true,
      //   };
      // }
      return {
        ...state,
        liveShowpostData: {
          ...action?.payload?.entity,
        },
        error: false,
        alreadyLiveFetched: true,
      };
    }
    case LIVEPOSTSOCKET_GET_SUCCESS: {
      console.log("reducer", action);
      console.log(" LIVESHOWPOST_GET_SUCCESS 2");

      // const liveIds = state.liveShowpostData?.entities?.map((live) => live._id)
      // let tmp = []
      // for (let item of action?.payload?.entities) {
      //   let index = state.liveShowpostData.entities.findIndex(item2 => item2._id == item._id)
      //   if (index === -1) {
      //     tmp.push(item)
      //   }
      // }
      // console.log('first', tmp)

      return {
        ...state,
        liveShowpostData: {
          ...state.liveShowpostData,
          entities: [...action?.payload?.entities],
        },
        error: false,
        alreadyLiveFetched: true,
      };
    }

    case LIVESHOWPOST_GET_FAILED:
      return {
        ...state,
        ...action?.payload,
        message: action?.payload?.message,
        error: true,
      };
    //live streaming data
    case LIVE_STREAM_DATA_SUCCESS: {
      if (state?.alreadyPlaying) {
        return state;
      } else {
        return {
          ...state,
          liveStreamingData: action?.payload?.entity,
          error: false,
          alreadyPlaying: true,
        };
      }
    }
    case LIVE_STREAM_DATA_FAILED:
      return {
        ...state,
        message: action.payload.message,
        error: true,
        liveStreamingData: {},
      };

    case HOME_CATEGORY_POST:
      return {
        ...state,
        message: action.payload.message,
        error: false,
        allCategoryHomePosts: action.payload.categoryPosts,
      };
    case HOME_TOP_SHOWS:
      return {
        ...state,
        message: action.payload.message,
        error: false,
        homeTopShows: action.payload.topShows,
      };
    //detailed posts
    case GET_POST_BY_ID_SUCCESS:
      return {
        ...state,
        postDetails: action.payload.postDetails,
        error: false,
      };
    case GET_POST_BY_ID_FAILED:
      return {
        ...state,
        message: action.payload.message,
        error: true,
      };

    //Trending posts
    case GET_TRENDING_POST_SUCCESS:
      return {
        ...state,
        trendingPosts: action.payload.trendingPost,
        error: false,
      };
    case GET_TRENDING_POST_FAILED:
      return {
        ...state,
        message: action.payload.message,
        error: true,
      };
    // like-unlike posts
    case POST_LIKE_INIT:
      return {
        ...state,
        postDetails: {
          ...action.postDetails,
          isLiked: !action.postDetails.isLiked,
          likeCount:
            action.postDetails.likeCount +
            (action.postDetails.isLiked ? -1 : 1),
        },
      };
    case GET_STATISTICS_INIT:
      return {
        ...state,
        statistics: action.payload.stats,
      };
    case POST_LIKE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: false,
      };
    case POST_LIKE_FAILED:
      return {
        ...state,
        message: action.payload.message,
        postDetails: {
          ...state.postDetails,
          isLiked: !state.postDetails.isLiked,
          likeCount:
            state.postDetails.likeCount + (state.postDetails.isLiked ? -1 : 1),
        },
        error: true,
      };
    // viewCount story
    case POST_VIEW_COUNT_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: false,
      };
    case POST_VIEW_COUNT_FAILED:
      return {
        ...state,
        message: action.payload.message,
        error: true,
      };
    // allpostData
    case GET_ALL_POST_SUCCESS:
      return {
        ...state,
        allPosts: action.payload.allPosts,
        message: action.payload.message,
        error: false,
      };
    case GET_ALL_POST_FAILED:
      return {
        ...state,
        message: action.payload.message,
        error: true,
      };
    // featuredData
    case GET_ALL_FEATURED_POST_SUCCESS:
      return {
        ...state,
        featuredPosts: action.payload.featuredPosts,
        message: action.payload.message,
        error: false,
      };
    case GET_ALL_FEATURED_POST_FAILED:
      return {
        ...state,
        message: action.payload.message,
        error: true,
      };
    case STORY_LIKE_SUCCESS:
      return {
        ...state,
        storyById: action.payload.storyById,
      };

    // visual story
    case GET_VISUALSTORY_BY_ID_SUCCESS:
      return {
        ...state,
        visualStory: action.payload.story,
        visualStoryError: false,
      };
    case GET_VISUALSTORY_BY_ID_FAILED:
      return {
        ...state,
        visualStory: null,
        visualStoryError: true,
      };
    case CLEAR_VISUALSTORY:
      return {
        ...state,
        visualStory: null,
        visualStoryError: false,
      };
    case GET_RELATED_ARTICLES_SUCCESS:
      return {
        ...state,
        relatedNews: action.payload.entities,
      };

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
