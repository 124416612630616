import app from "./firebase";
import { initializeAppCheck, onTokenChanged } from 'firebase/app-check';
import { ReCaptchaV3Provider } from "firebase/app-check";
import { setCookie } from "utils/cookieHelper";
let appCheck
if (typeof window !== "undefined") {
// window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
 appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6Ld9DIImAAAAAAxcQ2sRVNXTLMcRf1413q3bAL7q'),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
    
});
onTokenChanged( appCheck,(token) =>{
    console.log(token?.token)
    setCookie("appCheckToken",token?.token)
  });
}
export default appCheck;