import { GET_ADS_BY_SIZE_SUCCESS,GET_ADS_BY_SIZE_FAILED } from "../actionTypes/ads.actionTypes";

  
  const iniitialState = {
    adPlacement300_300: [],
    adPlacement300_250: [],
    adPlacement300_600: [],
    adPlacement970_250: [],
    adPlacement480x320: [],
    error: false,
    message: ""
  };
  
  export default function AdsReducer(state = iniitialState, action) {
    switch (action.type) {
      // get ads list
      case GET_ADS_BY_SIZE_SUCCESS:
        return {
          ...state,
          error: false,
          adPlacement300_300: action.payload.adPlacement300_300,
          adPlacement300_250: action.payload.adPlacement300_250,
          adPlacement300_600: action.payload.adPlacement300_600,
          adPlacement970_250: action.payload.adPlacement970_250,
          adPlacement480x320: action.payload.adPlacement480x320,
        };
        case GET_ADS_BY_SIZE_FAILED:
        return {
          ...state,
          error: true
        };
      default:
        // If this reducer doesn't recognize the action type, or doesn't
        // care about this specific action, return the existing state unchanged
        return state;
    }
  }
  