import { Navbar, Nav } from "react-bootstrap";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import MainMenu from "./menu";
import MainMenuDesktop from "./menuDesktop";
import { getCategory } from "../../../redux/actions/categoryActions";
import { FormattedMessage } from "react-intl";
// import ClevertapReact from "utils/clevertapHelper";
import { handleLogout as reduxLogout } from "redux/actions/authActions";
// import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
// import "overlayscrollbars/css/OverlayScrollbars.css";
import SearchBox from "./searchComponent";
import styled from "styled-components";
// import useMobileDetect from "hooks/useMobileDetect";
import { getSearchDropdown } from "redux/actions/searchActions";
// import { isHindiText } from "api/commonConstants";
// import { baseUrl } from "api/constants";
import { useSession, signOut } from "next-auth/react";
import { getProfileById } from "redux/actions/userActions";
import { getLanguage, languages, setLanguage } from "localization/i8intl";
import { useWindowSize } from "api/commonConstants";
import { isHindiText } from "api/commonConstants";

import {
  // createEditProfilePayload,
  // createLogoutPayload,
  // createOpenSettingPayload,
  MyLink,
} from "api/cleverTap";
import { getUserIdFromCookies } from "constants/loginConstants";
// import { useWindowSize } from "api/commonConstants";

import { baseUrl } from "api/constants";
import { debounce } from "debounce";
// const PriorityNav = dynamic(() => import("react-priority-navigation"), {
//   ssr: false,
// });
// import { ToggleButton } from "react-priority-navigation";

// const priorityNav = dynamic(() => import("priority-nav"), {
//   ssr: false,
// });
// import priorityNav from "priority-nav";

const DropdownDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  span:first-child {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .option-dropdown {
    display: flex;
    align-items: center;
  }
  .dropdownList {
    position: absolute;
    background-color: #fff;
    top: 26px;
    right: 0px;
    z-index: 4;
    padding: 8px;
    box-shadow: 0px 0px 8px 0px #0000003d;
    i {
      margin-right: 5px;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      font-weight: 700;
      cursor: pointer;
    }
  }
`;

const CategoryNav = ({ activeTab, setActiveTab }) => {
  const size = useWindowSize();

  const dispatch = useDispatch();
  const { userData } = useSelector(({ UserReducer }) => UserReducer);
  const { userLocalDetail } = useSelector(({ AuthReducer }) => AuthReducer);
  // const dropdownList = useSelector(
  //   ({ SearchReducer }) => SearchReducer.dropdown
  // );

  // const [dropdownList, setDropdownList] = useState([])

  const [showHeader, setShowHeader] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSuggestDropdown, setShowSuggestDropdown] = useState(false);
  const { data: session, status: sessionStatus } = useSession();
  const [searchText, setSearchText] = useState("");
  const [profileImage, setProfileImage] = useState();
  const [showLangDropdown, setShowLangDropdown] = useState(false);
  const [activeHindi, setActiveHindi] = useState(false);
  const [activeEnglish, setActiveEnglish] = useState(false);
  const [showMoreCategoryDropdown, setShowMoreCategoryDropdown] =
    useState(false);
  const [dropdownListText, setDropdownListText] = useState([]);
  const [dropdownListVideo, setDropdownListVideo] = useState([]);
  const [dropdownListAudio, setDropdownListAudio] = useState([]);
  const [showElectionSub, setShowElectionSub] = useState(false);

  const router = useRouter();

  const [activeCat, setActiveCat] = useState(
    router?.pathname === "/" ? "home" : ""
  );

  // const { category1 } = useSelector(({ CategoryReducer }) => CategoryReducer);
  const isActive = useCallback(
    (val) => {
      return activeCat === val ? "active" : "";
    },
    [activeCat]
  );
  let preLoadCategory = [
    {
      name: "News",
      nameHn: "न्यूज़",
      slug: "news",
      postsCount: 21323,
    },
    {
      name: "Election",
      nameHn: "चुनाव",
      slug: "election",
      postsCount: 21323,
    },
    {
      name: "Sports",
      nameHn: "स्पोर्ट्स ",
      slug: "sports",
      postsCount: 21323,
    },
    {
      name: "Entertainment",
      nameHn: "एंटरटेनमेंट",
      slug: "entertainment",
      postsCount: 223323,
    },
    {
      name: "Lallankhas",
      nameHn: "लल्लनख़ास",
      slug: "lallankhas",
      postsCount: 1232323,
    },
    {
      name: "Technology",
      nameHn: "टेक्नोलॉजी",
      slug: "technology",
      postsCount: 23233,
    },
    {
      name: "Oddnaari",
      nameHn: "ऑडनारी",
      slug: "oddnari",
      postsCount: 232323,
    },
    {
      name: "Fact Check",
      nameHn: "पड़ताल",
      slug: "factcheck",
      postsCount: 32323,
    },
    {
      name: "Business",
      nameHn: "बिज़नेस",
      slug: "business",
      postsCount: 923232,
    },
    {
      name: "Education",
      nameHn: "रंगरूट",
      slug: "education",
      postsCount: 232323,
    },
  ];
  let preLoadDataForCategory = {
    categories: preLoadCategory,
    totalCategoriesCount: 3,
  };
  const category2 = useSelector(({ CategoryReducer }) => CategoryReducer);
  const [category1, setCategory1] = useState(preLoadDataForCategory);
  console.log("new category ", category2);
  useEffect(() => {
    if (category2.category1.categories?.length > 0) {
      setCategory1(category2.category1);
    }
  }, [category2]);
  useEffect(() => {
    dispatch(getCategory());
    // (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    //   (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    //   m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    //   })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  }, [dispatch]);
  useEffect(() => {
    // console.log("Search Text changed to", searchText);
    if (searchText) {
      fetchSearchSuggestions(searchText);
    }
  }, [searchText]);
  // useEffect(() => {
  //   const { contentType, ...restQuery } = router.query;
  //   // const as =
  //   //   router.pathname === "/login"
  //   //     ? undefined
  //   //     : router.pathname === "/stories" && router.query?.categorySlugName
  //   //     ? `/${router.query?.categorySlugName}`
  //   //     : `/${router.query?.categorySlug || router.pathname}/${
  //   //         router.query?.postSlug || ""
  //   //         //router.pathname.includes("/postdetail") ? "postDetail" : ""
  //   //       }`;
  //   // if (!contentType) {
  //   //   const hash = router.asPath.split("#")[1];
  //   //   const newQuery = { ...restQuery, contentType: activeTab };
  //   //   router.replace(
  //   //     { pathname: router.pathname, query: newQuery, hash: hash ? hash : "" },
  //   //     router?.pathname,
  //   //     { scroll: false }
  //   //   );
  //   // }
  // }, [activeTab, router.pathname]);
  // useEffect(() => {
  //   const { contentType, ...restQuery } = router.query;
  //   console.log("contentType",contentType, router)
  //   if (contentType) {
  //     const newQuery = { ...restQuery, contentType: activeTab };
  //     // Always do navigations after the first render
  //     router.replace({ pathname: router.pathname, query: newQuery }, undefined, {
  //       shallow: true,
  //     });
  //   }
  // }, [activeTab, router.pathname]);
  useEffect(() => {
    console.log("set show search set to false", router);
    setShowSearch(false);
    setShowMoreCategoryDropdown(false);
    if (activeCat === "" && router.query?.categoryName) {
      setActiveCat(router.query?.categoryName);
    } else if (activeCat === "" && !router.query?.categoryName) {
      if (router.pathname === "/") {
        setActiveCat("home");
      } else if (router.pathname.includes("latest")) {
        setActiveCat("trending");
      } else if (router.pathname.includes("featured")) {
        setActiveCat("featured");
      } else if (router.asPath.includes("show/all")) {
        setActiveCat("shows");
      } else if (router.asPath.includes("visualstories")) {
        setActiveCat("visualstories");
      }
    }
  }, [router?.query?.categoryName, activeCat, router.pathname]);

  useEffect(
    () =>
      setShowHeader(
        router.pathname !== "/login" && router.pathname !== "/preferences"
      ),
    [router.pathname]
  );

  console.log("All categories ", category2);
  useEffect(() => {
    const userId = getUserIdFromCookies(document.cookie);
    if (userId) {
      dispatch(getProfileById({ _id: userId }));
    }
  }, [dispatch]);

  const [showSearch, setShowSearch] = useState(false);
  // const isShow = useMobileDetect().isDesktop();
  const fetchSearchSuggestions = debounce(async (text) => {
    fetch(
      `${baseUrl}/search/dropdownWithSlug?page=1&limit=10${
        isHindiText(text) ? `&language=Hindi` : ""
      }&search=${encodeURIComponent(text)}`
    )
      .then((res) => res.json())
      .then((d) => {
        console.log("dis", d?.result?.data);
        setDropdownListText(d?.result?.data?.textSuggestion);
        setDropdownListVideo(d?.result?.data?.videoSuggestion);
        setDropdownListAudio(d?.result?.data?.audioSuggestion);
      });
  }, 300);

  const handleLogout = () => {
    fetch("/api/clearToken", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ encryptedToken: "" }),
    }).then((res) => {
      if (res.status === 200) {
        reduxLogout();
        localStorage.removeItem("lallantopSession2");
        localStorage.removeItem("imageData");
        signOut({ callbackUrl: `${window.location.origin}` });
        //setCookie("lallantop", "", 0, "1970-01-01T00:00:00.000Z");
      }
    });
  };

  const dropdown = useRef(null);
  const searchRef = useRef(null);
  const langDropdown = useRef(null);
  const moreCategoryDropdown = useRef(null);

  useEffect(() => {
    // only add the event listener when the dropdown is opened
    if (!showDropdown) return;
    function handleClick(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setShowDropdown("");
      }
    }
    window.addEventListener("click", handleClick);

    function handleScroll(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setShowDropdown("");
      }
    }
    window.addEventListener("scroll", handleScroll);
    // clean up
    return () => {
      window.removeEventListener("click", handleClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showDropdown]);

  useEffect(() => {
    // only add the event listener when the langDropdown is opened
    if (!showLangDropdown) return;
    function handleClick(event) {
      if (
        langDropdown.current &&
        !langDropdown.current.contains(event.target)
      ) {
        setShowLangDropdown("");
      }
    }
    window.addEventListener("click", handleClick);

    function handleScroll(event) {
      if (
        langDropdown.current &&
        !langDropdown.current.contains(event.target)
      ) {
        setShowLangDropdown("");
      }
    }
    window.addEventListener("scroll", handleScroll);
    // clean up
    return () => {
      window.removeEventListener("click", handleClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showLangDropdown]);

  useEffect(() => {
    // only add the event listener when the moreCategoryDropdown is opened
    if (!showMoreCategoryDropdown) return;
    function handleClick(event) {
      if (
        moreCategoryDropdown.current &&
        !moreCategoryDropdown.current.contains(event.target)
      ) {
        setShowMoreCategoryDropdown("");
      }
    }
    window.addEventListener("click", handleClick);

    function handleScroll(event) {
      if (
        moreCategoryDropdown.current &&
        !moreCategoryDropdown.current.contains(event.target)
      ) {
        setShowMoreCategoryDropdown("");
      }
    }
    window.addEventListener("scroll", handleScroll);
    // clean up
    return () => {
      window.removeEventListener("click", handleClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showMoreCategoryDropdown]);

  useEffect(() => {
    // only add the event listener when the searchRef is opened
    if (!showSearch) return;
    function handleClick(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        //setShowSearch("");
      }
    }
    window.addEventListener("click", handleClick);

    function handleScroll(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        //setShowSearch("");
      }
    }
    window.addEventListener("scroll", handleScroll);
    // clean up
    return () => {
      window.removeEventListener("click", handleClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showSearch]);

  useEffect(() => {
    if (searchText.length > 0) {
      dispatch(getSearchDropdown(searchText));
      // fetch(`${baseUrl}/search/dropdown?page=1&limit=25${isHindiText(searchText) ? `&language=Hindi` : ""}&search=${searchText}`)
      //   .then((res) => res.json())
      //   .then((d) => {
      //     setDropdownList(d?.result);
      //   });
      if (!showSuggestDropdown) {
        setShowSuggestDropdown(true);
      }
    } else {
      if (showSuggestDropdown) {
        setShowSuggestDropdown(false);
      }
    }
  }, [searchText, setSearchText]);

  // useEffect(() => ClevertapReact.initialize("TEST-775-R7R-9R6Z"), []);
  useEffect(() => {
    // const random = Date.now().toString();
    // const sessionUser = sessionStatus === "authenticated" ? session.user : null;
    // const appUser = Object.keys(userData) > 0 ? userData : null;
    // if (!clevertap) return;
    // ClevertapReact.profile({
    //   Site: {
    //     Name: appUser ? appUser.fullname : "Guest_" + random,
    //     Identity: appUser ? appUser._id : random, // String or number
    //     Email: sessionUser
    //       ? sessionUser?.email
    //       : `guest_user_${random}@lallantop.com`,
    //     Phone: appUser ? appUser.phoneNumber : "",
    //     DOB: new Date(0),
    //     "MSG-push": true,
    //   },
    // });
  }, [session?.user, sessionStatus, userData]);

  useEffect(() => {
    if (activeCat === "" && router.query?.categoryName) {
      setActiveCat(router.query?.categoryName);
    } else if (activeCat === "" && !router.query?.categoryName) {
      if (router.pathname === "/") {
        setActiveCat("home");
      } else if (router.pathname.includes("trending")) {
        setActiveCat("trending");
      } else if (router.pathname.includes("featured")) {
        setActiveCat("featured");
      }
      // switch (router.pathname) {
      //   case "/":
      //     setActiveCat("home");
      //     break;
      //   case "/trending":
      //     setActiveCat("trending");
      //     break;
      //   case "/featured":
      //     setActiveCat("featured");
      //     break;
      //   default:
      //     break;
      // }
    }
  }, [router?.query?.categoryName, activeCat, router.pathname]);

  useEffect(() => {
    router.events.on("routeChangeComplete", (router) => {
      const tab = router.split(/[/?]/);
      if (!tab || !tab.length) {
        return void 0;
      }
      if (tab[2] && tab[2] !== "" && tab[2].indexOf("contentType") === -1) {
        setActiveCat(tab[2]);
      } else {
        setActiveCat("");
      }
    });
    return () => {
      router.events.off("routeChangeStart", () => {
        setActiveCat("");
      });
    };
  }, [router.events]);

  const loginRedirectUrl = router.pathname
    .replace("[categoryName]", router.query?.categoryName)
    .replace("[slug]", router.query?.slug);

  useEffect(() => {
    const image =
      userData?.profileImage || userLocalDetail?.image
        ? userData?.profileImage || userLocalDetail?.image
        : session?.user?.image
        ? session?.user?.image
        : "/assets/images/user-circle.svg";
    if (image !== profileImage) {
      setProfileImage(image);
    }
  }, [session?.user?.image, userData?.profileImage, userLocalDetail?.image]);

  useEffect(() => {
    setActiveHindi(getLanguage() === languages.hi);
    setActiveEnglish(getLanguage() === languages.en);
  }, []);

  var i = 5;
  if (typeof window !== "undefined") {
    let width = window.innerWidth;
    const langFinder = document.querySelector(".lang-finder");

    if (
      getLanguage() === languages.hi &&
      !/[a-zA-Z]/.test(langFinder?.textContent)
    ) {
      if (width > 1600) {
        i = 10;
      } else if (width > 1365) {
        i = 9;
      } else if (width > 1265) {
        i = 8;
      } else {
        i = 7;
      }
    } else {
      if (width > 1719) {
        i = 10;
      } else if (width > 1600) {
        i = 9;
      } else if (width > 1365) {
        i = 7;
      } else if (width > 1265) {
        i = 6;
      } else {
        i = 5;
      }
    }
  }

  //Handling Scrolling and SubMenus of Chunav 2024
  useEffect(() => {
    let listener;
    if (showElectionSub) {
      const overlayDiv = document.querySelector(".default-overlay");
      const image = document.getElementById("chunav-drop");

      // Get the left position of both elements relative to the viewport
      const overlayLeft = overlayDiv.getBoundingClientRect().left;
      const imageLeft = image.getBoundingClientRect().left;

      // Calculate the distance of the image from the left side of the overlay
      const distanceFromOverlayLeft = imageLeft - overlayLeft;
      const divToBeMoved = document.querySelector(".election-sub-btn-div");

      let dynamicMargin = distanceFromOverlayLeft - 60; // Replace with your dynamic margin value

      // Get the viewport width and the div's width
      const viewportWidth = window.innerWidth;
      const divWidth = divToBeMoved.offsetWidth;

      // Calculate the maximum allowable margin-left
      const maxMarginLeft = viewportWidth - divWidth;

      // Apply the dynamic margin-left, but cap it if it exceeds the maxMarginLeft
      const marginLeftToApply = Math.min(dynamicMargin, maxMarginLeft);

      // Apply the calculated margin-left
      divToBeMoved.style.marginLeft = `${marginLeftToApply}px`;
      const header = document.querySelector(".default-overlay"); // Replace with your actual class or id

      // Add a scroll event listener to the header div
      listener = header.addEventListener("scroll", function () {
        console.log("Header is scrolling!");

        setShowElectionSub(false);
      });
    }
    return () => {
      removeEventListener("scroll", listener);
    };
  }, [showElectionSub]);
  return (
    <>
      <Navbar key={activeCat} id="responsive-navbar-nav">
        {size.width <= 500 ? (
          <div className="slidenav-btn">
            <MainMenu />
          </div>
        ) : (
          <div className="slidenav-btn-desktop">
            <MainMenuDesktop />
          </div>
        )}

        <div className="cat-logo">
          {/* <a href="/" passHref> */}
          <a href="/">
            <img
              src="/assets/images/m-logo.svg"
              width="32"
              loading="lazy"
              height="33"
              alt="Lallantop"
            />
          </a>
          {/* </a> */}
        </div>
        {/* <a href="/social-arena" passHref>
          <a aria-label="social arena">
            <div className="arena-btn">
              <img
                src="/assets/images/social-arena.svg"
                width="27"
                height="27"
                alt="Social Arena"
              />
              <span>
                <FormattedMessage id="social_news" defaultMessage="सोशल फीड" />
              </span>
            </div>
          </a>
        </a> */}

        {/* <div className="nav-dynamic"> */}
        {/* <div> */}
        <Nav role="navigation" className="priority-nav greedy-nav m-visible">
          <div className="default-overlay">
            <ul>
              <li>
                <a href="/" passHref>
                  <span
                    onClick={() => {
                      if (typeof window.ga !== "undefined") {
                        ga("create", "G-RXRPKR4310", "auto");
                        ga("send", "event", {
                          eventCategory: "category_navigation",
                          eventAction: "Click",
                          eventLabel: "home",
                          eventValue: "https://www.thelallantop.com/",
                        });
                      }

                      setActiveCat("home");
                    }}
                    className={isActive("home")}
                  >
                    <FormattedMessage id="home" defaultMessage="होम" />
                  </span>
                  {/* <a className="nav-link">होम</a> */}
                </a>
              </li>
              <li>
                <a
                  href={`${
                    router?.pathname.includes("/text")
                      ? "/text"
                      : router?.pathname.includes("video")
                      ? "/video"
                      : router?.pathname.includes("audio")
                      ? "/all"
                      : "/all"
                  }/latest`}
                >
                  <span
                    onClick={() => {
                      if (typeof window.ga !== "undefined") {
                        ga("create", "G-RXRPKR4310", "auto");
                        ga("send", "event", {
                          eventCategory: "category_navigation",
                          eventAction: "Click",
                          eventLabel: "trending",
                          eventValue:
                            "https://www.thelallantop.com/all/trending",
                        });
                      }

                      setActiveCat("trending");
                      if (activeTab === "audio") {
                        setActiveTab("all");
                      }
                    }}
                    className={isActive("trending")}
                  >
                    {activeHindi ? "लेटेस्ट" : "Latest"}
                  </span>
                  {/* <a className="nav-link">ट्रेंडिंग</a> */}
                </a>
              </li>
              <li>
                <a href={`/show/all`} passHref>
                  <span
                    onClick={() => {
                      if (typeof window.ga !== "undefined") {
                        ga("create", "G-RXRPKR4310", "auto");
                        ga("send", "event", {
                          eventCategory: "category_navigation",
                          eventAction: "Click",
                          eventLabel: `shows`,
                          eventValue: `https://www.thelallantop.com/show/all`,
                        });
                      }

                      setActiveCat("shows");
                      if (activeTab === "audio") {
                        setActiveTab("shows");
                      }
                    }}
                    className={isActive("shows")}
                  >
                    {activeHindi ? "शोज़" : "Shows"}
                  </span>
                </a>
              </li>
              <li>
                <a href={`/visualstories`} passHref>
                  <span
                    onClick={() => {
                      if (typeof window.ga !== "undefined") {
                        ga("create", "G-RXRPKR4310", "auto");
                        ga("send", "event", {
                          eventCategory: "category_navigation",
                          eventAction: "Click",
                          eventLabel: `visualstories`,
                          eventValue: `https://www.thelallantop.com/visualstories`,
                        });
                      }
                      setActiveCat("visualstories");
                      if (activeTab === "audio") {
                        setActiveTab("all");
                      }
                    }}
                    className={isActive("visualstories")}
                  >
                    {activeHindi ? "विज़ुअल स्टोरीज़" : "Visual Stories"}
                  </span>
                </a>
              </li>
              <li>
                  <a href={`/weather/delhi-weather-forecast-today/`} passHref>
                    <span
                      onClick={() => {
                        if (typeof window.ga !== "undefined") {
                          ga("create", "G-RXRPKR4310", "auto");
                          ga("send", "event", {
                            eventCategory: "category_navigation",
                            eventAction: "Click",
                            eventLabel: `Weather`,
                            eventValue: `https://www.thelallantop.com/weather`,
                          });
                        }
                        setActiveCat("visualstories");
                        if (activeTab === "audio") {
                          setActiveTab("all");
                        }
                      }}
                      className={isActive("visualstories")}
                    >
                      {activeHindi ? "मौसम" : "Weather"}
                    </span>
                  </a>
                </li>

              {/* <li>
                  <a
                    href={{
                      pathname: `/${
                        router?.pathname.includes("/text")
                          ? "text"
                          : router?.pathname.includes("video")
                          ? "/video"
                          : "/all"
                      }/featured`,
                    }}
                    passHref
                  >
                    <Nav.Link
                      onClick={() => setActiveCat("featured")}
                      className={isActive("featured")}
                    >
                      <FormattedMessage id="featured" defaultMessage="फीचर्ड" />
                    </Nav.Link>
                  </a>
                </li> */}
              {category1?.categories?.map(
                (cat) =>
                  !!cat?.postsCount && (
                    <div key={cat?._id} style={{ position: "relative" }}>
                      <li key={cat?._id} style={{ display: "flex" }}>
                        <a
                          href={`${
                            activeTab == "audio"
                              ? `/all/${cat.slug.toLowerCase()}`
                              : cat?.name === "Election"
                              ? `/all/election`
                              : `/${
                                  activeTab === "show" ? "all" : activeTab
                                }/${cat.slug.toLowerCase()}`
                          }`}
                          // href={{
                          //   pathname:
                          //     activeTab == "audio"
                          //       ? `/all/${cat.slug.toLowerCase()}`
                          //       : `/${activeTab}/${cat.slug.toLowerCase()}`,
                          // query: {
                          //   categoryId: cat?._id,
                          //   categoryName: cat?.name,
                          //   categoryNameHn: cat?.nameHn,
                          // },
                          // }}

                          key={cat._id}
                        >
                          <span
                            onClick={() => {
                              const payload = {
                                "Category Name": cat.name,
                                "Category ID": cat._id,
                              };
                              if (typeof window.ga !== "undefined") {
                                ga("create", "G-RXRPKR4310", "auto");
                                ga("send", "event", {
                                  eventCategory: "category_navigation",
                                  eventAction: "Click",
                                  eventLabel: `${cat.name}`,
                                  eventValue: `https://www.thelallantop.com/${cat.slug.toLowerCase()}`,
                                });
                              }

                              // ClevertapReact.event(
                              //   "homepage_categories",
                              //   payload
                              // );
                              setActiveCat(cat.slug.toLowerCase());
                              if (activeTab === "audio") {
                                setActiveTab("all");
                              }
                            }}
                            className={isActive(cat.slug.toLowerCase())}
                          >
                            {activeHindi ? (
                              cat.name === "Election" ? (
                                "चुनाव"
                              ) : (
                                cat.nameHn
                              )
                            ) : cat.name === "Election" ? (
                              <>
                                Election{" "}
                                {/* <img
                                  src="/assets/images/arrow-down.png"
                                  width="13px"
                                  height="10px"
                                ></img> */}
                              </>
                            ) : (
                              cat.name
                            )}
                          </span>
                        </a>
                        {cat.name === "Election" && (
                          <img
                            id="chunav-drop"
                            onClick={() => {
                              if (cat.name === "Election") {
                                setShowElectionSub((prev) => !prev);
                              }
                            }}
                            style={{
                              alignSelf: "center",
                              marginRight: "15px",
                            }}
                            src="/assets/images/arrow-down.png"
                            width="10px"
                            height="9px"
                          ></img>
                        )}
                      </li>
                      {/* {cat.name === "Election" && showElectionSub && (
                        <div
                          className="election-sub-btn-div"
                          // onMouseLeave={() => {
                          //   setShowElectionSub(false);
                          // }}
                        >
                          <div
                            onClick={() =>
                              (window.location.href =
                                "/all/election/haryana-assembly-chunav")
                            }
                          >
                            हरियाणा चुनाव
                          </div>
                          <div
                            onClick={() =>
                              (window.location.href =
                                "/all/election/jammu-kashmir-assembly-chunav")
                            }
                          >
                            जम्मू कश्मीर चुनाव
                          </div>
                          <div
                            onClick={() =>
                              (window.location.href =
                                "/all/election/haryana-assembly-chunav/schedule")
                            }
                          >
                            चुनाव शेड्यूल
                          </div>
                          <div
                            onClick={() =>
                              (window.location.href =
                                "/all/election/lok-sabha-chunav-2024")
                            }
                          >
                            लोक सभा चुनाव{" "}
                          </div>
                        </div>
                      )} */}
                    </div>
                  )
              )}
            </ul>
            {showElectionSub && (
              <div
                className="election-sub-btn-div"
                // onMouseLeave={() => {
                //   setShowElectionSub(false);
                // }}
              >
                <div
                              onClick={() =>
                                (window.location.href =
                                  "/all/election/maharashtra-assembly-chunav")
                              }
                            >
                              महाराष्ट्र चुनाव
                            </div>
                            <div
                              onClick={() =>
                                (window.location.href =
                                  "/all/election/jharkhand-assembly-chunav")
                              }
                            >
                              झारखंड चुनाव
                            </div>
                            <div
                              onClick={() =>
                                (window.location.href =
                                  "/all/election/maharashtra-assembly-chunav/schedule")
                              }
                            >
                              चुनाव शेड्यूल
                            </div>
                            <div
                              onClick={() =>
                                (window.location.href =
                                  "/all/election/lok-sabha-chunav-2024")
                              }
                            >
                              लोक सभा चुनाव{" "}
                            </div>
              </div>
            )}
          </div>
          {/* <a href="/column" passHref>
            <Nav.Link className={isActive(`/column`)}>कॉलम </Nav.Link>
          </a> */}
          {/* <a href="/gallery" passHref>
            <Nav.Link className={isActive(`/gallery`)}>
              गैलरी{" "}
            </Nav.Link>
          </a> */}
          {/* {category1.categories?.length > i && (
            <div className="more-cate">
              <div className="cate-btn">
                <i className="icon-arrow-right"></i>
              </div>
            </div>
          )} */}
        </Nav>
        {/* </div> */}

        <div className="mob-nav">
          <Nav role="navigation" className="priority-nav greedy-nav d-visible">
            <div className="default-overlay">
              <ul>
                <li>
                  <a href="/" passHref>
                    <span
                      onClick={() => {
                        if (typeof window.ga !== "undefined") {
                          ga("create", "G-RXRPKR4310", "auto");
                          ga("send", "event", {
                            eventCategory: "category_navigation",
                            eventAction: "Click",
                            eventLabel: `home`,
                            eventValue: `https://www.thelallantop.com/`,
                          });
                        }

                        setActiveCat("home");
                      }}
                      className={isActive("home")}
                    >
                      <FormattedMessage id="home" defaultMessage="होम" />
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      router?.pathname.includes("/text")
                        ? "/text"
                        : router?.pathname.includes("video")
                        ? "/video"
                        : router?.pathname.includes("audio")
                        ? "/all"
                        : "/all"
                    }/latest`}
                    passHref
                  >
                    <span
                      onClick={() => {
                        if (typeof window.ga !== "undefined") {
                          ga("create", "G-RXRPKR4310", "auto");
                          ga("send", "event", {
                            eventCategory: "category_navigation",
                            eventAction: "Click",
                            eventLabel: `trending`,
                            eventValue: `https://www.thelallantop.com/all/trending`,
                          });
                        }

                        setActiveCat("trending");
                        if (activeTab === "audio") {
                          setActiveTab("all");
                        }
                      }}
                      className={isActive("trending")}
                    >
                      {activeHindi ? "लेटेस्ट" : "Latest"}
                    </span>
                  </a>
                </li>
                <li>
                  <a href={`/show/all`} passHref>
                    <span
                      onClick={() => {
                        if (typeof window.ga !== "undefined") {
                          ga("create", "G-RXRPKR4310", "auto");
                          ga("send", "event", {
                            eventCategory: "category_navigation",
                            eventAction: "Click",
                            eventLabel: `shows`,
                            eventValue: `https://www.thelallantop.com/show/all`,
                          });
                        }

                        setActiveCat("shows");
                        if (activeTab === "audio") {
                          setActiveTab("shows");
                        }
                      }}
                      className={isActive("shows")}
                    >
                      {activeHindi ? "शोज़" : "Shows"}
                    </span>
                  </a>
                </li>
                <li>
                  <a href={`/visualstories`} passHref>
                    <span
                      onClick={() => {
                        if (typeof window.ga !== "undefined") {
                          ga("create", "G-RXRPKR4310", "auto");
                          ga("send", "event", {
                            eventCategory: "category_navigation",
                            eventAction: "Click",
                            eventLabel: `visualstories`,
                            eventValue: `https://www.thelallantop.com/visualstories`,
                          });
                        }
                        setActiveCat("visualstories");
                        if (activeTab === "audio") {
                          setActiveTab("all");
                        }
                      }}
                      className={isActive("visualstories")}
                    >
                      {activeHindi ? "विज़ुअल स्टोरीज़" : "Visual Stories"}
                    </span>
                  </a>
                </li>
                <li>
                  <a href={`https://www.thelallantop.com/weather/delhi-weather-forecast-today/`} passHref>
                    <span
                      onClick={() => {
                        if (typeof window.ga !== "undefined") {
                          ga("create", "G-RXRPKR4310", "auto");
                          ga("send", "event", {
                            eventCategory: "category_navigation",
                            eventAction: "Click",
                            eventLabel: `Weather`,
                            eventValue: `https://www.thelallantop.com/weather`,
                          });
                        }
                        setActiveCat("weather");
                        if (activeTab === "audio") {
                          setActiveTab("all");
                        }
                      }}
                      className={isActive("weather")}
                    >
                      {activeHindi ? "मौसम" : "Weather"}
                    </span>
                  </a>
                </li>
                {category1?.categories?.slice(0, i).map(
                  (cat) =>
                    !!cat?.postsCount && (
                      <div key={cat?._id}>
                        <li
                          key={cat?._id}
                          onMouseEnter={() => {
                            if (cat.name === "Election") {
                              setShowElectionSub(true);
                            } else {
                              setShowElectionSub(false);
                            }
                          }}
                        >
                          <a
                            href={
                              activeTab == "audio"
                                ? `/all/${cat.slug.toLowerCase()}`
                                : cat?.name === "Election"
                                ? activeTab === "all"
                                  ? `/all/election`
                                  : `/${activeTab}/election`
                                : `/${
                                    activeTab === "show" ? "all" : activeTab
                                  }/${cat.slug.toLowerCase()}`
                              // query: {
                              //   categoryId: cat?._id,
                              //   categoryName: cat?.name,
                              //   categoryNameHn: cat?.nameHn,
                              // },
                            }
                            passHref
                            key={cat._id}
                          >
                            <span
                              onClick={() => {
                                const payload = {
                                  "Category Name": cat.name,
                                  "Category ID": cat._id,
                                };
                                if (typeof window.ga !== "undefined") {
                                  ga("create", "G-RXRPKR4310", "auto");
                                  ga("send", "event", {
                                    eventCategory: "category_navigation",
                                    eventAction: "Click",
                                    eventLabel: `${cat.name}`,
                                    eventValue: `https://www.thelallantop.com/${cat.slug.toLowerCase()}`,
                                  });
                                }

                                // ClevertapReact.event(
                                //   "homepage_categories",
                                //   payload
                                // );
                                setActiveCat(cat.slug.toLowerCase());
                                if (activeTab === "audio") {
                                  setActiveTab("all");
                                }
                              }}
                              className={`${isActive(
                                cat.slug.toLowerCase()
                              )} lang-finder`}
                            >
                              {activeHindi ? (
                                cat.name === "Election" ? (
                                  <>चुनाव</>
                                ) : (
                                  cat.nameHn
                                )
                              ) : cat.name === "Election" ? (
                                <>Election</>
                              ) : (
                                cat.name
                              )}
                            </span>
                            {cat.name === "Election" && (
                              <img
                                style={{
                                  alignSelf: "center",
                                  marginRight: "15px",
                                }}
                                src="/assets/images/arrow-down.png"
                                width="10px"
                                height="9px"
                              ></img>
                            )}
                          </a>
                        </li>
                        {cat.name === "Election" && showElectionSub && (
                          <div
                            className="election-sub-btn-div"
                            onMouseLeave={() => {
                              setShowElectionSub(false);
                            }}
                          >
                            <div
                              onClick={() =>
                                (window.location.href =
                                  "/all/election/maharashtra-assembly-chunav")
                              }
                            >
                              महाराष्ट्र चुनाव
                            </div>

                            <div
                              onClick={() =>
                                (window.location.href =
                                  "/all/election/jharkhand-assembly-chunav")
                              }
                            >
                              झारखंड चुनाव
                            </div>
                            <div
                              onClick={() =>
                                (window.location.href =
                                  "/all/election/maharashtra-assembly-chunav/schedule")
                              }
                            >
                              चुनाव शेड्यूल
                            </div>
                            <div
                              onClick={() =>
                                (window.location.href =
                                  "/all/election/lok-sabha-chunav-2024")
                              }
                            >
                              लोक सभा चुनाव{" "}
                            </div>
                          </div>
                        )}
                      </div>
                    )
                )}
              </ul>
            </div>
          </Nav>

          {i < category1?.categories?.length && (
            <div className="d-visible" style={{ cursor: "pointer" }}>
              <DropdownDiv
                className="more-col"
                onClick={() => {
                  setShowMoreCategoryDropdown((b) => !b);
                }}
                ref={moreCategoryDropdown}
              >
                <img
                  className="arrow-down dots"
                  src="/assets/images/navigation-more.svg"
                  width="20"
                  height="20"
                  alt=""
                />
                <div className="option-dropdown more-cat-dropdown">
                  {showMoreCategoryDropdown && (
                    <div className="dropdown-list">
                      <ul>
                        {category1.categories?.slice(i).map(
                          (cat) =>
                            !!cat?.postsCount && (
                              <li key={cat?._id}>
                                <a
                                  href={
                                    activeTab == "audio"
                                      ? `/all/${cat.slug.toLowerCase()}`
                                      : `/${
                                          activeTab === "show"
                                            ? "all"
                                            : activeTab
                                        }/${cat.slug.toLowerCase()}`
                                    // query: {
                                    //   categoryId: cat?._id,
                                    //   categoryName: cat?.name,
                                    //   categoryNameHn: cat?.nameHn,
                                    // },
                                  }
                                  passHref
                                  key={cat._id}
                                >
                                  <span
                                    onClick={() => {
                                      const payload = {
                                        "Category Name": cat.name,
                                        "Category ID": cat._id,
                                      };
                                      // ClevertapReact.event(
                                      //   "homepage_categories",
                                      //   payload
                                      // );
                                      setActiveCat(cat.slug.toLowerCase());
                                      if (activeTab === "audio") {
                                        setActiveTab("all");
                                      }
                                    }}
                                    className={isActive(cat.slug.toLowerCase())}
                                  >
                                    {activeHindi ? cat.nameHn : cat.name}
                                  </span>
                                </a>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </DropdownDiv>
            </div>
          )}
        </div>

        <div className="srch-lang">
          <div className="category-search d-visible">
            <div className="header-serch">
              <div className="suggest-option" ref={searchRef}>
                <div className="suggest-option-dropdown">
                  {/* {console.log("showSearch", showSearch)} */}
                  {showSearch && (
                    <div className="search-box-container">
                      <SearchBox
                        searchText={searchText}
                        setSearchText={setSearchText}
                      />
                    </div>
                  )}
                  {showSuggestDropdown && (
                    <div className="dropdown-list">
                      <ul>
                        {dropdownListText &&
                          dropdownListText.slice(0, 5).map((item, index) => (
                            <a
                              href={item.permalink}
                              key={index}
                              passHref={true}
                            >
                              <li key={index}>
                                Article:&nbsp;
                                <a onClick={() => setSearchText("")}>
                                  <span>{item?.title}</span>
                                </a>
                              </li>
                            </a>
                          ))}
                        {dropdownListVideo &&
                          dropdownListVideo.slice(0, 5).map((item, index) => (
                            <li key={index}>
                              Video:&nbsp;
                              <a href={item.permalink} passHref={true}>
                                <a onClick={() => setSearchText("")}>
                                  <span>{item?.title}</span>
                                </a>
                              </a>
                            </li>
                          ))}
                        {dropdownListAudio &&
                          dropdownListAudio.slice(0, 5).map((item, index) => (
                            <li key={index}>
                              Audio:&nbsp;
                              <a href={item.permalink} passHref={true}>
                                <a onClick={() => setSearchText("")}>
                                  <span>{item?.title}</span>
                                </a>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div className="search-action">
                {showSearch ? (
                  <button
                    onClick={() => {
                      setShowSearch(false);
                      setSearchText("");
                    }}
                    aria-label="close button"
                  >
                    <i className="icon-close"></i>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      if (size.width < 500) {
                        router.push("/search");
                      } else {
                        setShowSearch(true);
                      }
                    }}
                    aria-label="close button"
                  >
                    <i className="icon-search"></i>
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="lang d-visible">
            <DropdownDiv
              className="more-col"
              onClick={() => {
                setShowLangDropdown((b) => !b);
                setShowSearch(false);
              }}
              ref={langDropdown}
            >
              {/* <img
                className="arrow-down"
                src="/assets/images/arrow_down_pink.svg"
                width="20"
                height="20"
                alt=""
              /> */}
              <span className="lang-name" style={{ marginRight: "1rem" }}>
                {activeHindi ? "हिंदी" : "Eng"}
              </span>
              <i className="icon-globe"></i>
              <div className="option-dropdown">
                {showLangDropdown && (
                  <div className="dropdown-list lang-dropdown">
                    <ul>
                      <li
                        role={"button"}
                        onClick={() => setLanguage(languages.hi)}
                      >
                        <span>
                          <FormattedMessage id="hindi" defaultMessage="हिंदी" />
                        </span>
                      </li>
                      <li
                        role={"button"}
                        onClick={() => setLanguage(languages.en)}
                      >
                        <span>
                          <FormattedMessage
                            id="English"
                            defaultMessage="English"
                          />
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </DropdownDiv>
          </div>

          <div className="user-nav d-visible">
            {userLocalDetail?.id || session ? (
              <DropdownDiv
                className="more-col"
                onClick={() => {
                  if (!showDropdown) {
                    // ClevertapReact.event(
                    //   "profile_settings",
                    //   createOpenSettingPayload("profile_settings", userData)
                    // );
                  }
                  setShowDropdown((b) => !b);
                }}
                ref={dropdown}
              >
                <span className="username">
                  {userData?.firstname ||
                    userLocalDetail?.name ||
                    session?.user?.name ||
                    ""}
                </span>
                <img
                  src={
                    // userData?.profileImage ||
                    // userLocalDetail?.image ||
                    // session?.user?.image ||
                    // "/assets/images/user-circle.svg"
                    profileImage
                  }
                  referrerPolicy="no-referrer"
                  width="24"
                  height="24"
                  alt="user"
                  style={{ borderRadius: 24 }}
                />
                <div className="option-dropdown">
                  {showDropdown && (
                    <div className="dropdown-list user-dropdown">
                      <ul>
                        <li>
                          <a href="/profile" passHref>
                            <MyLink
                              onCustomClick={() => {
                                // ClevertapReact.event(
                                //   "profile_editprofile",
                                //   createEditProfilePayload(
                                //     "profile_editprofile",
                                //     userData
                                //   )
                                // );
                              }}
                            >
                              <span>
                                <FormattedMessage
                                  id="edit_profile"
                                  defaultMessage="एडिट प्रोफाइल"
                                />
                              </span>
                            </MyLink>
                          </a>
                        </li>
                        <li
                          onClick={() => {
                            // ClevertapReact.event(
                            //   "settings_logout",
                            //   createLogoutPayload("settings_logout", userData)
                            // );
                            handleLogout();
                          }}
                        >
                          <a>
                            <span>
                              <FormattedMessage
                                id="logout"
                                defaultMessage="लॉग आउट"
                              />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </DropdownDiv>
            ) : (
              <Link
                href={{
                  pathname: "/api/auth/signin",
                  query: {
                    callbackUrl:
                      router.query?.categoryName && router.query?.categoryName
                        ? loginRedirectUrl
                        : router.asPath,
                  },
                }}
                passHref
              >
                <a className="login">
                  <span className="signin-username">
                    <FormattedMessage
                      id="signin"
                      defaultMessage="साइन इन करें"
                    />
                  </span>
                  <i className="icon-user"></i>
                </a>
              </Link>
            )}
          </div>
        </div>
      </Navbar>
    </>
  );
};
export default CategoryNav;
