import { getAuthorizedApi } from "api/apiInstance";
import { baseUrl } from "api/constants";
import { call, put, takeLatest } from "redux-saga/effects";
import { getTokenFromCookies } from "constants/loginConstants";
import {
  SERIES_GET_FAILED,
  SERIES_GET_INIT,
  SERIES_GET_SUCCESS,
  SERIES_FOLLOWED_INIT,
  SERIES_FOLLOWED_SUCCESS,
  SERIES_FOLLOWED_FAILED,
  SERIES_GET_BY_ID_INIT,
  SERIES_GET_BY_ID_FAILED,
  SERIES_GET_BY_ID_SUCCESS,
} from "redux/actionTypes/series.actionTypes";
import { REFRESH_PAGE_INIT, SHOW_MODAL_POPUP } from "../actionTypes/common.actionTypes";
import { logger } from "logging/log-util";

function* getSeries({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json())
  //     // .then(({encryptedToken})=> encryptedToken || "");
  // if(token &&  typeof token === 'string' ) {
  //    token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/series?limit=100&skip=1&type=${payload.activeTab}&showTop=true`
    );

    if (data.status) {
      yield put({
        type: SERIES_GET_SUCCESS,
        payload: {
          entity: data?.result,
        },
      });
    } else {
      yield put({
        type: SERIES_GET_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
  }
}

function* getSeriesById({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json())
  //     /*.then(({encryptedToken})=> encryptedToken || "")*/;
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/series/series-details?postSlug=${payload.postSlug}&limit=10&skip=1 `
    );

    if (data.status) {
      yield put({
        type: SERIES_GET_BY_ID_SUCCESS,
        payload: {
          entity: data?.result?.series,
        },
      });
    } else {
      yield put({
        type: SERIES_GET_BY_ID_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: e.response?.data?.message,
      },
    });
  }
}

function* getFollowed({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json())
  //     // .then(({encryptedToken})=> encryptedToken || "");
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/series/follow-unfollow`,
      { _id: payload._id }
    );

    if (data.status) {
      yield put({
        type: SERIES_FOLLOWED_SUCCESS,
      });
      yield put({
        type: REFRESH_PAGE_INIT
      });
      yield put({
        type: SERIES_GET_BY_ID_INIT,
        payload: { postSlug: payload.postSlug },
      });
    } else {
      yield put({
        type: SERIES_FOLLOWED_FAILED,
      });
    }
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: e.response?.data?.message,
      },
    });
  }
}

function* seriesSaga() {
  yield takeLatest(SERIES_GET_INIT, getSeries);
  yield takeLatest(SERIES_FOLLOWED_INIT, getFollowed);
  yield takeLatest(SERIES_GET_BY_ID_INIT, getSeriesById);
}

export default seriesSaga;
