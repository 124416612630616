import { getApi, 
  // getAuthorizedApi 
} from "api/apiInstance";
import { logger } from "logging/log-util";
// import { baseUrl } from "api/constants";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_ADS_BY_SIZE_SUCCESS,
  // GET_ADS_BY_SIZE_FAILED,
  GET_ADS_BY_SIZE_INIT,
} from "../actionTypes/ads.actionTypes";

function* getAdsBySize() {
  const client = yield getApi();

  try {
    const adPlacement300_300 = yield call(
      client.get,
      "https://servedbyadbutler.com/adserve/;ID=182408;size=300x300;setID=534178;type=jsonr;click=CLICK_MACRO_PLACEHOLDER"
    );
    const adPlacement300_250 = yield call(
      client.get,
      "https://servedbyadbutler.com/adserve/;ID=182408;size=300x250;setID=534170;type=jsonr;click=CLICK_MACRO_PLACEHOLDER"
    );

    const adPlacement300_600 = yield call(
      client.get,
      "https://servedbyadbutler.com/adserve/;ID=182408;size=300x600;setID=534169;type=jsonr;click=CLICK_MACRO_PLACEHOLDER"
    );

    const adPlacement970_250 = yield call(
      client.get,
      "https://servedbyadbutler.com/adserve/;ID=182408;size=970x250;setID=534171;type=jsonr;click=CLICK_MACRO_PLACEHOLDER"
    );

    const adPlacement480x320 = yield call(
      client.get,
      "https://servedbyadbutler.com/adserve/;ID=182408;size=480x320;setID=541379;type=jsonr;click=CLICK_MACRO_PLACEHOLDER"
    );

    yield put({
      type: GET_ADS_BY_SIZE_SUCCESS,
      payload: {
        adPlacement300_300: adPlacement300_300?.data?.placements || [],
        adPlacement300_250: adPlacement300_250?.data?.placements || [],
        adPlacement300_600: adPlacement300_600?.data?.placements || [],
        adPlacement970_250: adPlacement970_250?.data?.placements || [],
        adPlacement480x320: adPlacement480x320?.data?.placements || [],
      },
    });
  } catch (e) {
    logger.error(e)
  }
}

function* adsSaga() {
  yield takeLatest(GET_ADS_BY_SIZE_INIT, getAdsBySize);
}

export default adsSaga;
