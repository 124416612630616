import { fork } from "@redux-saga/core/effects";
// import dynamic from "next/dynamic";
import authSaga from "./authSaga";
import userSaga from "./userSaga";
import categorySaga from "./categorySaga";
import opinionSaga from "./opinionSaga";
import hashtagSaga from "./hashtagSaga";
import postSaga from "./postSaga";
import bookmarkSaga from "./bookmarkSaga";
import seriesSaga from "./seriesSaga";
import feedbackSaga from "./feedbackSaga";
import quizSaga from "./quizSaga";
import adsSaga from "./adsSaga";
import reviewSaga from "./reviewSaga";
import searchSaga from "./searchSaga";
import liveblogSaga from "./liveblogSaga";

export default function* rootSaga() {
  yield fork(authSaga);
  yield fork(userSaga);
  yield fork(categorySaga);
  yield fork(opinionSaga);
  yield fork(hashtagSaga);
  yield fork(postSaga);
  yield fork(bookmarkSaga);
  yield fork(seriesSaga);
  yield fork(feedbackSaga);
  yield fork(quizSaga);
  yield fork(adsSaga);
  yield fork(reviewSaga);
  yield fork(searchSaga);
  yield fork(liveblogSaga);
  // code after fork-effect
}
