import { OTP_LIMIT, USER_LOGIN_LIMIT } from "api/constants";

export function setCookie(cname, cvalue, exmin,expireDate) {
  const d = new Date();
  d.setTime(d.getTime() + exmin * 60 * 1000);
  let expires = "expires=" + ( expireDate || d.toUTCString());
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const getPhoneItemLimit = (phoneNo) => {
  const previousState = JSON.parse(localStorage.getItem("userLoginState"))
  return previousState && previousState.find((phoneItem) => phoneItem?.phoneNo === phoneNo)
}
export const isUserAllowed = (phoneNo) => {
  const previousState = JSON.parse(localStorage.getItem("userLoginState"))
  let loginCount = Number(localStorage.getItem("loginCount"));
  const findedPhone = previousState && previousState.find((phoneItem) => phoneItem?.phoneNo === phoneNo)
  return findedPhone ? findedPhone?.otpCount <= OTP_LIMIT && loginCount <= USER_LOGIN_LIMIT : true
}

export const incrementOTPLimitCount = (phoneNo) => {
  const previousState = JSON.parse(localStorage.getItem("userLoginState"))
  const findPhoneIndex = previousState && previousState.indexOf((phoneItem) => phoneItem?.phoneNo === phoneNo)
  const currentState = {
    ...previousState[findPhoneIndex]
  }
  currentState["otpCount"] = findPhoneIndex !== -1 ? (previousState[findPhoneIndex].otpCount + 1) : 1
  if (findPhoneIndex !== -1) {
    // updating previous state here if found in storage
    previousState[findPhoneIndex] = currentState;
    localStorage.setItem("userLoginState", JSON.stringify(previousState))
  } else {
    localStorage.setItem("userLoginState", JSON.stringify([...previousState, currentState]))
  }
}

export const clearUserLimit = () => {
  const previousState = JSON.parse(localStorage.getItem("userLoginState"))
  previousState && previousState?.forEach((item) => {
    if (item?.expirationTime && new Date().getTime() > item?.expirationTime) {
      item.expirationTime = null
    }
  })
  console.log("previousState", previousState)
  localStorage.setItem("userLoginState", JSON.stringify([...previousState, previousState]))

}