import {
  Auth_INIT,
  AUTH_LOGGEDIN_FAILED,
  AUTH_LOGGEDIN_INIT,
  AUTH_LOGGEDIN_SUCCESS,
  AUTH_OTP_FAILED,
  AUTH_OTP_INIT,
  AUTH_OTP_RESEND_FAILED,
  AUTH_OTP_RESEND_INIT,
  AUTH_OTP_RESEND_SUCCESS,
  AUTH_OTP_SUBMIT_FAILED,
  AUTH_OTP_SUBMIT_INIT,
  AUTH_OTP_SUBMIT_SUCCESS,
  AUTH_OTP_SUCCESS,
  AUTH_SIGNIN_FAILED,
  AUTH_SIGNIN_INIT,
  AUTH_SIGNIN_SUBMIT,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNOUT,
  AUTH_SIGNUP_FAILED,
  AUTH_SIGNUP_INIT,
  // AUTH_SIGNUP_OTP_INIT,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_USER_DETAILS,
  AUTH_MOBILE_POPUP_SUCCESS,
  AUTH_DELETE_ACCOUNT
} from "redux/actionTypes/auth.actionTypes";

const iniitialState = {
  deviceType: null,
  deviceToken: null,
  userData: null,
  isLoggedIn: false,
  encryptedToken: "",
  showSuccessModal: false,
  typeLoggedIn: false,
  currentStep: null,
  userData: {},
  error: false,
  message: "",
  entity: null,
  otpClear: false,
  mobilePopUp: 0,
  userLocalDetail:
    typeof window === "undefined"
      ? null
      : localStorage.getItem("lallantopSession2")
      ? JSON.parse(localStorage.getItem("lallantopSession2"))
      : null,
};

export default function AuthReducer(state = iniitialState, action) {
  switch (action.type) {
    // Do something here based on the different types of actions

    case Auth_INIT:
      return {
        ...state,
        ...action.payload,
      };

    case AUTH_LOGGEDIN_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_LOGGEDIN_SUCCESS:
      return {
        ...state,
        encryptedToken: action.payload.encryptedToken,
        showSuccessModal: true,
        typeLoggedIn: false,
        error: false,
        entity: action.payload.entity,
        userLocalDetail: action.payload.sessionObj,
      };
    case AUTH_LOGGEDIN_FAILED:
      return {
        ...state,
        ...action.payload,
        isLoggedIn: false,
        message: action.payload.message,
        error: true,
      };
    case AUTH_OTP_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_OTP_SUCCESS:
      return {
        ...state,
        ...action.payload,
        showSuccessModal: false,
        typeLoggedIn: true,
        error: false,
        entity: action.payload.entity,
      };
    case AUTH_OTP_FAILED:
      return {
        ...state,
        ...action.payload,
        isLoggedIn: false,
        message: action.payload.message,
        error: true,
      };

    case AUTH_OTP_RESEND_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_OTP_RESEND_SUCCESS:
      return {
        ...state,
        ...action.payload,
        showSuccessModal: false,
        typeLoggedIn: true,
        error: false,
        entity: action.payload.entity,
      };
    case AUTH_OTP_RESEND_FAILED:
      return {
        ...state,
        ...action.payload,
        isLoggedIn: false,
        message: action.payload.message,
        error: true,
      };

    case AUTH_OTP_SUBMIT_INIT:
      return {
        ...state,
        ...action.payload,
      };

    case AUTH_OTP_SUBMIT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true,
        showSuccessModal: true,
        encryptedToken: action.payload.encryptedToken,
        error: false,
        userLocalDetail: action.payload.sessionObj,
      };

    case AUTH_OTP_SUBMIT_FAILED:
      return {
        ...state,
        ...action.payload,
        otpClear: !state.otpClear,
        // isLoggedIn: false,
        // showSuccessModal: false,
        // message: action.payload.message,
        // error: true,
      };

    case AUTH_SIGNUP_USER_DETAILS:
      return {
        ...state,
        ...action.payload,
        currentStep: "deailedForm",
      };
    case AUTH_SIGNUP_INIT:
      return {
        ...state,
        ...action.payload,
        currentStep: "signup",
      };
    case AUTH_SIGNUP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_SIGNUP_FAILED:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_SIGNIN_INIT:
      return {
        ...state,
        currentStep: "login",
      };
    case AUTH_SIGNIN_SUBMIT:
      return {
        ...state,
        currentStep: "login",
        showSuccessModal: true,
      };
    case AUTH_SIGNIN_SUCCESS:
      return {
        ...action.payload,
        isLoggedIn: true,
        showSuccessModal: false,
      };
    case AUTH_SIGNIN_FAILED:
      return {
        ...action.payload,
        isLoggedIn: false,
        showSuccessModal: false,
      };
    case AUTH_SIGNOUT:
      return {
        ...iniitialState,
      };

    case AUTH_DELETE_ACCOUNT:
      return {
        ...iniitialState
      }

    case AUTH_MOBILE_POPUP_SUCCESS:
      return {
        ...state,
        mobilePopUp: state.mobilePopUp + 1,
      };

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
