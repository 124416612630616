import { getAuthorizedApi, getAuthorizedApiForQuiz } from "api/apiInstance";
import {
  call,
  put,
  takeEvery,
  // takeLatest
} from "redux-saga/effects";
import {
  // FETCH_ACTIVE_QUIZZES,
  // FETCH_PREVIOUS_QUIZZES,
  // FETCH_QUIZ_LEADERBOARD,
  // FETCH_QUIZ_PLAY,
  // FETCH_QUIZ_SCORE,
  // FETCH_UPCOMING_QUIZZES,
  SAVE_USER_RESPONSE,
  FETCH_REWARD_HISTORY,
  FETCH_REWARD_SUMMARY,
  // CLOSE_LIVE_QUIZ
  // FETCH_CURRENT_QUIZ
} from "redux/actionTypes/quiz.actionTypes";
import { getTokenFromCookies } from "constants/loginConstants";
import * as actions from "redux/actions/quizActions";
import { SHOW_MODAL_POPUP } from "../actionTypes/common.actionTypes";
import { getCookie } from "utils/cookieHelper";
// import appCheck from "utils/firebaseAppCheck";
// import { getToken } from 'firebase/app-check';

// function* GetActiveQuizzes({ payload: { limit } }) {
//   const error = "Failed to fetch active quizzes";
//   // let token = yield fetch("/api/getToken", {
//   //   method: "get",
//   //   headers: {
//   //     "Content-Type": "application/json"
//   //   }
//   // }).then(res => res.json())/*.then(({
//   //   encryptedToken
//   // }) => encryptedToken || "")*/;
//   // if(token &&  typeof token === 'string' ) {
//   //   token  = JSON.parse(token);
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }else if(typeof token === 'object') {
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }
//   const token = getTokenFromCookies(document.cookie);
//   const client = yield getAuthorizedApi(token);
//   try {
//     yield put(actions.startCall());
//     const { data } = yield call(
//       client.get,
//       `${baseUrl}/quiz/active-quizzes?limit=${limit}&skip=1`
//     );

//     if (data.status) {
//       const { quizzes, previousQuizeArr, upcomingQuizArr } = data.result;
//       yield put(
//         actions.callCompleted({ message: "Quizzes fetched successfully." })
//       );
//       yield put(
//         actions.quizzesFetched({
//           activeQuizzes: quizzes,
//           previousQuizzes: previousQuizeArr,
//           upcomingQuizzes: upcomingQuizArr,
//         })
//       );
//     } else yield put(actions.catchError(error));
//   } catch (e) {
//     logger.error(e);
//     yield put({
//       type: SHOW_MODAL_POPUP,
//       payload: {
//         error: true,
//         message: new Error(e).message,
//       },
//     });
//   }
// }

// export async function getAppToken (){
//   let appCheckTokenResponse;
//   try {
//       appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
//       return appCheckTokenResponse
//   } catch (err) {
//       // Handle any errors if the token was not retrieved.
//       logger.error(err);
//       return null;
//   }
// }
// function* getCurrentQuiz ({ payload: { quizId } }){
//   const error = "Failed to fetch previous quizzes";
//   // const token = yield getAppToken();
//   // let token = yield fetch("/api/getToken", {
//   //   method: "get",
//   //   headers: {
//   //     "Content-Type": "application/json"
//   //   }
//   // }).then(res => res.json())
//   /*.then(({
//     encryptedToken
//   }) => encryptedToken || "")*/;
//   // if(token &&  typeof token === 'string' ) {
//   //   token  = JSON.parse(token);
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }else if(typeof token === 'object') {
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }
//   const bearer = getTokenFromCookies(document.cookie);
//   const client = yield getAuthorizedApiWithAppCheck(bearer,token.token);
//   try {
//     yield put(actions.startCall());

//     const { data } = yield call(
//       client.get,
//       `${process.env.NEXT_PUBLIC_APP_API_URL}/v2/quiz/details?_id=${quizId}`
//     );
//     console.log(data)
//     if (data.status) {
//       const rawData= data.result;
//       console.log(rawData)
//       yield put(
//         actions.callCompleted({
//           message: "Current quizz fetched successfully.",
//         })
//       );
//       yield put(actions.quizzesFetched({ currentQuiz: rawData }));
//     }
//     else yield put(actions.catchError(error));
//   } catch (e) {
//     logger.error(e);
//     // yield put({
//     //   type: SHOW_MODAL_POPUP,
//     //   payload: {
//     //     error: true,
//     //     message: new Error(e).message,
//     //   },
//     // });
//   }
// }

// function* GetPreviousQuizzes({ payload: { limit } }){
//   const error = "Failed to fetch previous quizzes";
//   getAppToken();
//   // let token = yield fetch("/api/getToken", {
//   //   method: "get",
//   //   headers: {
//   //     "Content-Type": "application/json"
//   //   }
//   // }).then(res => res.json())
//   /*.then(({
//     encryptedToken
//   }) => encryptedToken || "")*/;
//   // if(token &&  typeof token === 'string' ) {
//   //   token  = JSON.parse(token);
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }else if(typeof token === 'object') {
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }

//   const client = yield getAuthorizedApi();

//   try {
//     yield put(actions.startCall());

//     const { data } = yield call(
//       client.get,
//       `${process.env.NEXT_PUBLIC_APP_API_URL}/v2/quiz/status?limit=50&skip=1&state=prevNotPlayed`
//     );
//     if (data.status) {
//       const { quizCounts, quizzes } = data.result;
//       yield put(
//         actions.callCompleted({
//           message: "Previous quizzes fetched successfully.",
//         })
//       );
//       yield put(actions.quizzesFetched({ previousQuizzes: quizzes }));
//     } else yield put(actions.catchError(error));
//   } catch (e) {
//     logger.error(e);
//     // yield put({
//     //   type: SHOW_MODAL_POPUP,
//     //   payload: {
//     //     error: true,
//     //     message: new Error(e).message,
//     //   },
//     // });
//   }
// }

// function* GetUpcomingQuizzes({ payload: { limit } }) {
//   const error = "Failed to fetch upcoming quizzes";
//   // let token = yield fetch("/api/getToken", {
//   //   method: "get",
//   //   headers: {
//   //     "Content-Type": "application/json"
//   //   }
//   // }).then(res => res.json())/*.then(({
//   //   encryptedToken
//   // }) => encryptedToken || "")*/;
//   // if(token &&  typeof token === 'string' ) {
//   //   token  = JSON.parse(token);
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }else if(typeof token === 'object') {
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }
//   const token = getTokenFromCookies(document.cookie);
//   const client = yield getAuthorizedApi(token);
//   try {
//     yield put(actions.startCall());
//     const { data } = yield call(
//       client.get,
//       `${baseUrl}/quiz/upcoming-quizzes?limit=${limit}&skip=1`
//     );

//     if (data.status) {
//       const { quizzes } = data.result;
//       yield put(
//         actions.callCompleted({
//           message: "Upcoming quizzes fetched successfully.",
//         })
//       );
//       yield put(actions.quizzesFetched({ upcomingQuizzes: quizzes }));
//     } else yield put(actions.catchError(error));
//   } catch (e) {
//     logger.error(e);
//     yield put({
//       type: SHOW_MODAL_POPUP,
//       payload: {
//         error: true,
//         message: new Error(e).message,
//       },
//     });
//   }
// }

// function* GetQuizPlay({ payload: { quizId } }) {
//   const error = "Failed to fetch quiz play";
//   // let token = yield fetch("/api/getToken", {
//   //   method: "get",
//   //   headers: {
//   //     "Content-Type": "application/json"
//   //   }
//   // }).then(res => res.json())/*.then(({
//   //   encryptedToken
//   // }) => encryptedToken || "")*/;
//   // if(token &&  typeof token === 'string' ) {
//   //   token  = JSON.parse(token);
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }else if(typeof token === 'object') {
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }
//   const token = getTokenFromCookies(document.cookie);
//   const client = yield getAuthorizedApi(token);
//   try {
//     yield put(actions.startCall());
//     const { data } = yield call(
//       client.get,
//       `${baseUrl}/quiz/details?_id=${quizId}`
//     );

//     if (data.status) {
//       yield put(
//         actions.callCompleted({ message: "Quiz play fetched successfully." })
//       );
//       yield put(actions.quizzesFetched({ quizPlay: data.result }));
//     } else yield put(actions.catchError(error));
//   } catch (e) {
//     logger.error(e);
//     yield put({
//       type: SHOW_MODAL_POPUP,
//       payload: {
//         error: true,
//         message: new Error(e).message,
//       },
//     });
//   }
// }

function* PostUserResponse({ payload: userResponse }) {
  const error = "Failed to save user response";
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json"
  //   }
  // }).then(res => res.json())/*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/;
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const appCheck = getCookie("appCheckToken");
  const client = yield getAuthorizedApiForQuiz(token, appCheck);
  try {
    yield put(actions.startCall());
    const { data } = yield call(
      client.post,
      `${process.env.NEXT_PUBLIC_APP_API_URL}/v2/quiz/save-user-response`,
      userResponse
    );

    if (data.status) {
      yield put(
        actions.callCompleted({ message: "User response saved successfully." })
      );
    } else {
      console.log("This is quiz error");
      yield put(actions.catchError(error));
    }
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: "Response won't saved. Multiple responses from same user.",
      },
    });
  }
}

// function* GetQuizScore({ payload: { quizId } }) {
//   const error = "Failed to fetch quiz score";
//   // let token = yield fetch("/api/getToken", {
//   //   method: "get",
//   //   headers: {
//   //     "Content-Type": "application/json"
//   //   }
//   // }).then(res => res.json())/*.then(({
//   //   encryptedToken
//   // }) => encryptedToken || "")*/;
//   // if(token &&  typeof token === 'string' ) {
//   //   token  = JSON.parse(token);
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }else if(typeof token === 'object') {
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }
//   const token = getTokenFromCookies(document.cookie);
//   const client = yield getAuthorizedApi(token);
//   try {
//     yield put(actions.startCall());
//     const { data } = yield call(
//       client.get,
//       `${baseUrl}/quiz/score?_id=${quizId}`
//     );

//     if (data.status) {
//       yield put(
//         actions.callCompleted({ message: "Quiz score fetched successfully." })
//       );
//       yield put(actions.quizzesFetched({ quizScore: data.result }));
//     } else yield put(actions.catchError(error));
//   } catch (e) {
//     logger.error(e);
//     yield put({
//       type: SHOW_MODAL_POPUP,
//       payload: {
//         error: true,
//         message: new Error(e).message,
//       },
//     });
//   }
// }

// function* GetQuizLeaderboard({ payload: { Type } }) {
//   const error = "Failed to fetch quiz leaderboard";
//   // let token = yield fetch("/api/getToken", {
//   //   method: "get",
//   //   headers: {
//   //     "Content-Type": "application/json"
//   //   }
//   // }).then(res => res.json())/*.then(({
//   //   encryptedToken
//   // }) => encryptedToken || "")*/;
//   // if(token &&  typeof token === 'string' ) {
//   //   token  = JSON.parse(token);
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }else if(typeof token === 'object') {
//   //   if(token.encryptedToken) {
//   //     token = token.encryptedToken;
//   //   }
//   // }
//   const token = getTokenFromCookies(document.cookie);
//   const client = yield getAuthorizedApi(token);
//   // const headerParams = {
//   //   "content-type" : "application/json",
//   //   "X-Requested-With": "XMLHttpRequest",
//   //   Authorization : `Bearer ${Token}`,
//   // }
//   // console.log(Token)
//   try {
//     yield put(actions.startCall());
//     const { data } = yield call(
//       client.get,
//       `${process.env.NEXT_PUBLIC_APP_API_URL}/v1/quiz/leader-board2?type=${Type}`,
//       //  {Headers: headerParams}
//     );

//     if (data.status) {
//       yield put(
//         actions.callCompleted({ message: "Quiz leaderboard fetched successfully." })
//       );
//       yield put(actions.quizzesFetched({ quizLeaderboard: data.result }));
//     } else yield put(actions.catchError(error));
//   } catch (e) {
//     logger.error(e);
//     // yield put({
//     //   type: SHOW_MODAL_POPUP,
//     //   payload: {
//     //     error: true,
//     //     message: new Error(e).message,
//     //   },
//     // });
//   }
// }
function* GetRewardHistory({ payload: {} }) {
  const error = "Failed to fetch quiz reward";
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json"
  //   }
  // }).then(res => res.json())/*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/;
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  // const headerParams = {
  //   "content-type" : "application/json",
  //   "X-Requested-With": "XMLHttpRequest",
  //   Authorization : `Bearer ${Token}`,
  // }
  try {
    yield put(actions.startCall());
    const { data } = yield call(
      client.get,
      `${process.env.NEXT_PUBLIC_APP_API_URL}/v1/reward/history?skip=1&limit=100`
      //  {Headers: headerParams}
    );

    if (data.status) {
      yield put(
        actions.callCompleted({
          message: "Quiz Reward History fetched successfully.",
        })
      );
      yield put(actions.quizzesFetched({ rewardHistory: data.result }));
    } else yield put(actions.catchError(error));
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: new Error(e).message,
      },
    });
  }
}
function* GetRewardSummary({ payload: {} }) {
  const error = "Failed to fetch quiz reward";
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json"
  //   }
  // }).then(res => res.json())/*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/;
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    yield put(actions.startCall());
    const { data } = yield call(
      client.get,
      `${process.env.NEXT_PUBLIC_APP_API_URL}/v1/reward/summary`
    );

    if (data.status) {
      yield put(
        actions.callCompleted({
          message: "Quiz Reward Summary fetched successfully.",
        })
      );
      yield put(actions.quizzesFetched({ rewardSummary: data.result }));
    } else yield put(actions.catchError(error));
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: new Error(e).message,
      },
    });
  }
}
function* setQuizClose({ payload }) {
  const error = "Failed to close quiz";
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json"
  //   }
  // }).then(res => res.json())/*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/;
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  // const headerParams = {
  //   "content-type" : "application/json",
  //   "X-Requested-With": "XMLHttpRequest",
  //   Authorization : `Bearer ${Token}`,
  // }
  try {
    yield put(actions.startCall());
    const { data } = yield call(
      client.post,
      `${process.env.NEXT_PUBLIC_APP_API_URL}/v1/quiz/close`,
      payload
      //  {Headers: headerParams}
    );

    if (data.status) {
      yield put(actions.callCompleted({ message: "Quiz closed successfully" }));
      yield put(actions.quizzesFetched({ rewardHistory: data.result }));
    } else yield put(actions.catchError(error));
  } catch (e) {
    console.log("Quiz closing error", e);
  }
}
function* quizSaga() {
  // yield takeEvery(FETCH_ACTIVE_QUIZZES, GetActiveQuizzes);
  // yield takeEvery(FETCH_PREVIOUS_QUIZZES, GetPreviousQuizzes);
  // yield takeEvery(FETCH_UPCOMING_QUIZZES, GetUpcomingQuizzes);
  // yield takeEvery(FETCH_QUIZ_PLAY, GetQuizPlay);
  yield takeEvery(SAVE_USER_RESPONSE, PostUserResponse);
  // yield takeEvery(FETCH_QUIZ_SCORE, GetQuizScore);
  // yield takeEvery(FETCH_QUIZ_LEADERBOARD, GetQuizLeaderboard);
  yield takeEvery(FETCH_REWARD_HISTORY, GetRewardHistory);
  yield takeEvery(FETCH_REWARD_SUMMARY, GetRewardSummary);
  // yield takeEvery(CLOSE_LIVE_QUIZ,setQuizClose);
  // yield takeEvery(FETCH_CURRENT_QUIZ,getCurrentQuiz);
}

export default quizSaga;
