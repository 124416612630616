import {
    HASHTAGS_GET_FAILED,
    HASHTAGS_GET_INIT,
    HASHTAGS_GET_SUCCESS,
  } from "redux/actionTypes/hashtag.actionTypes";
  
  const iniitialState = {
    hashtag: {},
    error: false,
    message: "",
  };
  
  export default function HashtagReducer(state = iniitialState, action) {
  
    switch (action.type) {
      // Do something here based on the different types of actions
  
      case HASHTAGS_GET_INIT:
        return {
          ...state,
          ...action.payload,
        };
  
      case HASHTAGS_GET_SUCCESS:
        return {
          ...state,
          ...action.payload,
          hashtag: action.payload.entity,
          error: false,
        };
        
      case HASHTAGS_GET_FAILED:
        return {
          ...state,
          ...action.payload,
          message: action.payload.message,
          error: true,
        };
  
      default:
        // If this reducer doesn't recognize the action type, or doesn't
        // care about this specific action, return the existing state unchanged
        return state;
    }
  }
  