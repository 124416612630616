import axios from "axios";
import { signOut } from "next-auth/react";
import { decryptData } from "utils/encryption";
import { getMaskedResponse } from "utils/loggerHelper";
// import dynamic from "next/dynamic";
// const appCheck = dynamic(() => import('utils/firebaseAppCheck'));
export const api = () =>
  axios.create({
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Headers": "*",
    },
  });

const handleLogoutViaInceptor = async () => {
  console.log("inceptor using");
  fetch("/api/clearToken", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ encryptedToken: "" }),
  }).then((res) => {
    if (res.status === 200) {
      localStorage.removeItem("lallantopSession2");
      localStorage.removeItem("imageData");
      signOut({ callbackUrl: `${window.location.origin}` });
      //setCookie("lallantop", "", 0, "1970-01-01T00:00:00.000Z");
    }
  });
};

// export async function getTokenForAPI (){
//   let appCheckTokenResponse
//     try {
//       appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
//   } catch (err) {
//     console.log(err)
//       // Handle any errors if the token was not retrieved.
//       return;
//   }
//     console.log(appCheckToken)
//     return appCheckTokenResponse
// }

export const getAuthorizedApi = async (
  encryptedToken,
  // AppCheckToken,
  loggerWithCtx = null,
  AppCheckToken
) => {
  if (typeof encryptedToken === "object") {
    encryptedToken = null;
  }
  const accessToken = await decryptData(encryptedToken);
  const headers = {
    "content-type": "application/json",
    connection: "keep-alive",
    "X-Requested-With": "XMLHttpRequest",
    "X-Firebase-AppCheck": AppCheckToken ? AppCheckToken : "",
    "Cache-Control": `stale-if-error=86400`,
  };
  if (accessToken) {
    headers.authorization = `Bearer ${accessToken}`;
  }
  const instance = axios.create({ headers });
  instance.defaults.headers["accesstype"] = "web";
  instance.interceptors.response.use(
    (response) => {
      if (loggerWithCtx) {
        const maskedResponse = getMaskedResponse(response);
        loggerWithCtx.info(maskedResponse);
      }
      return response;
    },
    async (error) => {
      if (error?.response) {
        if (
          error?.response.status === 401 ||
          error?.response.status === 403 ||
          error?.response.status === 502
        ) {
          await handleLogoutViaInceptor();
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
export const getAuthorizedApiForQuiz = async (
  encryptedToken,
  AppCheckToken
) => {
  if (typeof encryptedToken === "object") {
    encryptedToken = null;
  }
  const accessToken = await decryptData(encryptedToken);
  const headers = {
    "content-type": "application/json",
    connection: "keep-alive",
    "X-Requested-With": "XMLHttpRequest",
    "X-Firebase-AppCheck": AppCheckToken ? AppCheckToken : "",
  };
  if (accessToken) {
    headers.authorization = `Bearer ${accessToken}`;
  }
  const instance = axios.create({ headers });
  instance.defaults.headers["accesstype"] = "web";
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.response) {
        // if (error?.response.status === 401 || error?.response.status === 403 || error?.response.status === 502) {
        //   await handleLogoutViaInceptor();
        // }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
export const getAuthorizedApiForLogin = async (
  googleToken,
  encryptedToken,
  AppCheckToken
) => {
  if (typeof encryptedToken === "object") {
    encryptedToken = null;
  }
  const accessToken = await decryptData(encryptedToken);
  const headers = {
    "content-type": "application/json",
    connection: "keep-alive",
    "X-Requested-With": "XMLHttpRequest",
    "X-Firebase-AppCheck": AppCheckToken ? AppCheckToken : "",
    "Google-Token": googleToken,
  };
  if (accessToken) {
    headers.authorization = `Bearer ${accessToken}`;
  }
  const instance = axios.create({ headers });
  instance.defaults.headers["accesstype"] = "web";
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.response) {
        if (
          error?.response.status === 401 ||
          error?.response.status === 403 ||
          error?.response.status === 502
        ) {
          if (
            error?.response?.data?.message ===
            "Your account has been blocked, Please contact Lallantop Team"
          ) {
            return Promise.resolve(error?.response);
          }
          await handleLogoutViaInceptor();
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
export const getAuthorizedApiWithAppCheck = async (bearer, encryptedToken) => {
  if (typeof encryptedToken === "object") {
    encryptedToken = null;
  }
  const accessToken = encryptedToken;
  const bearerDecrypted = await decryptData(bearer);
  console.log("Token in getAuthorised ", accessToken);
  const headers = {
    "content-type": "application/json",
    connection: "keep-alive",
    "X-Requested-With": "XMLHttpRequest",
    "X-Firebase-AppCheck": accessToken,
  };
  if (accessToken) {
    headers.authorization = `Bearer ${bearerDecrypted}`;
  }
  const instance = axios.create({ headers });
  instance.defaults.headers["accesstype"] = "web";
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.response) {
        if (
          error?.response.status === 401 ||
          error?.response.status === 403 ||
          error?.response.status === 502
        ) {
          await handleLogoutViaInceptor();
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const getApi = () => axios.create();
