import {
  USER_INIT,
  USER_PROFILE_UPDATE_INIT,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAILED,
  USER_PROFILE_BY_ID_INIT,
  USER_PROFILE_BY_ID_SUCCESS,
  USER_PROFILE_BY_ID_FAILED,
  USER_PREFERENCES_UPDATE_INIT,
  USER_PREFERENCES_UPDATE_SUCCESS,
  USER_PREFERENCES_UPDATE_FAILED,
} from "redux/actionTypes/user.actionTypes";

const iniitialState = {
  isLoggedIn: false,
  showSuccessModal: false,
  error: false,
  message: "",
  userData: { preferences: [] },
  encryptedToken:"",
  profileUpdate: false, 

};

export default function UserReducer(state = iniitialState, action) {
  switch (action.type) {
    // Do something here based on the different types of actions

    case USER_INIT:
      return {
        ...state,
        ...action.payload,
      };

    case USER_PROFILE_UPDATE_INIT:
      return {
        ...state,
        ...action.payload,
      };

    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        // profileUpdate: true,
      };
    case USER_PROFILE_UPDATE_FAILED:
      return {
        ...state,
        ...action.payload,
        message: action.payload.message,
        error: true,
      };

    case USER_PROFILE_BY_ID_INIT:
      return {
        ...state,
        ...action.payload,
      };

    case USER_PROFILE_BY_ID_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: false,
        userData: action.payload.userData,
        showSuccessModal: true,
        encryptedToken: action.payload.encryptedToken,
        
      };
    case USER_PROFILE_BY_ID_FAILED:
      return {
        ...state,
        message: action.payload.message,
        error: true,
      };

    case USER_PREFERENCES_UPDATE_INIT:
      return {
        ...state,
        ...action.payload,
      };

    case USER_PREFERENCES_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
      };
    case USER_PREFERENCES_UPDATE_FAILED:
      return {
        ...state,
        ...action.payload,
        message: action.payload.message,
        error: true,
      };

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
