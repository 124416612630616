import Head from "next/head";
import React from "react";

const AmpHeader = () => {
  let data = ["abc", "def", "ghi", "jkl", "kmo"];
  return (
    <>
      <Head></Head>
      <div className="header-top">
        <div className="header-left">
          <button className="hm-button" role="button" on="tap:sidebar1">
            <span className="hb-menu-line"></span>
            <span className="hb-menu-line"></span>
            <span className="hb-menu-line-last"></span>
          </button>
          <amp-img
            src="https://static.thelallantop.com/images/post/1695637095493_lallantop-logo_(1).webp?width=80"
            width="35px"
            height="35px"
            alt="Logo"
            role="button"
            tabindex="0"
            on="tap:AMP.navigateTo(url='https://www.thelallantop.com/')"
          ></amp-img>
        </div>
        <div className="header-right">
          <amp-img
            src="https://static.thelallantop.com/images/post/1701156767752_icons8-search-50.webp"
            width="30px"
            height="30px"
            style={{ marginRight: "25px" }}
            alt="Logo"
            role="button"
            tabindex="0"
            on="tap:AMP.navigateTo(url='https://www.thelallantop.com/search')"
          ></amp-img>
          <amp-img
            src="https://static.thelallantop.com/images/post/1701341262584_download_app_mobile[1].webp"
            width="35px"
            height="35px"
            alt="Logo"
            role="button"
            tabindex="0"
            on="tap:AMP.navigateTo(url='https://onelink.to/hsnwrm')"
          ></amp-img>
        </div>
      </div>
      {/* <div className="header-below">
        <button className="hm-button" role="button" on="tap:sidebar1">
          <span className="hb-menu-line"></span>
          <span className="hb-menu-line"></span>
          <span className="hb-menu-line-last"></span>
        </button>
        <div className="category-nav">
          <ul className="category-list">
            <li>
              <a href="https://www.thelallantop.com//" className="nav-link">
                होम
              </a>
            </li>
            <li>
              <a
                href="https://www.thelallantop.com//all/trending"
                className="nav-link"
              >
                लेटेस्ट
              </a>
            </li>
            <li>
              <a
                href="https://www.thelallantop.com//all/news"
                className="nav-link"
              >
                न्यूज़
              </a>
            </li>
            <li>
              <a
                href="https://www.thelallantop.com//all/entertainment"
                className="nav-link"
              >
                एंटरटेनमेंट
              </a>
            </li>
            <li>
              <a
                href="https://www.thelallantop.com//all/sports"
                className="nav-link"
              >
                स्पोर्ट्स{" "}
              </a>
            </li>
            <li>
              <a
                href="https://www.thelallantop.com//all/lallankhas"
                className="nav-link"
              >
                लल्लनख़ास
              </a>
            </li>
            <li>
              <a
                href="https://www.thelallantop.com//all/technology"
                className="nav-link"
              >
                टेक्नोलॉजी
              </a>
            </li>
            <li>
              <a
                href="https://www.thelallantop.com//all/business"
                className="nav-link"
              >
                बिज़नेस
              </a>
            </li>
            <li>
              <a
                href="https://www.thelallantop.com//all/oddnaari"
                className="nav-link"
              >
                ऑडनारी
              </a>
            </li>
            <li>
              <a
                href="https://www.thelallantop.com//all/factcheck"
                className="nav-link"
              >
                पड़ताल
              </a>
            </li>
            <li>
              <a
                href="https://www.thelallantop.com//all/education"
                className="nav-link"
              >
                रंगरूट
              </a>
            </li>
            <li>
              <a
                href="https://www.thelallantop.com//all/election"
                className="nav-link"
              >
                चुनाव
              </a>
            </li>
          </ul>
        </div>
      </div> */}
      <amp-sidebar
        id="sidebar1"
        layout="nodisplay"
        side="left"
        style={{ background: "white" }}
      >
        <div className="sidebar-top">
          <div className="sidebar-close"></div>
          <div className="profile-box">
            <div className="user-pic">
              <img
                src={
                  "https://static.thelallantop.com/images/post/1701340360036_user-circle[1].webp?width=60"
                }
                width="80"
                height="80"
                alt="user"
              />
            </div>
            <div className="pro-text">
              <div className="m-signin">
                <a href="https://www.thelallantop.com/login?callbackUrl=https%3A%2F%2Fwww.thelallantop.com">
                  <div style={{ fontSize: "26px", fontWeight: "600" }}>
                    लॉगिन करें
                  </div>
                </a>
              </div>
              <div className="user-name"></div>
              <div className="user-contact"></div>
            </div>
          </div>
          <div className="head-top">
            {/* <div className="menu-head-box">
              <a href="https://www.thelallantop.com/hashtags">
                <img
                  src="https://static.thelallantop.com/images/post/1695113335086_group_313.webp?width=60"
                  alt="Lallantop"
                  width="50px"
                  height="50px"
                  loading="lazy"
                  className="menu-head-icon"
                />
                <div className="menu-head-text">हैशटैग्स</div>
              </a>
            </div> */}
            <div className="menu-head-box">
              <a href="https://www.thelallantop.com/bookmarks">
                <img
                  src="https://static.thelallantop.com/images/post/1695113608333_group_312.webp?width=60"
                  alt="Lallantop"
                  width="50px"
                  height="50px"
                  loading="lazy"
                  className="menu-head-icon"
                />
                <div className="menu-head-text">बुकमार्क्स</div>
              </a>
            </div>
            <div className="menu-head-box">
              <a href="https://www.thelallantop.com/text">
                <img
                  src="https://static.thelallantop.com/images/post/1701341439237_text-white.webp?width=60"
                  alt="Lallantop"
                  loading="lazy"
                  width="50px"
                  height="50px"
                  className="menu-head-icon"
                />
                <div className="menu-head-text">पढ़िए</div>
              </a>
            </div>
            <div className="menu-head-box">
              <a href="https://www.thelallantop.com/video">
                <img
                  src="https://static.thelallantop.com/images/post/1701341498909_video-white.webp?width=60"
                  alt="Lallantop"
                  width="50px"
                  height="50px"
                  loading="lazy"
                  className="menu-head-icon"
                />
                <div className="menu-head-text">देखिए</div>
              </a>
            </div>
          </div>
        </div>
        <div className="menu-body">
          <ul>
            <li>
              <img
                src="https://static.thelallantop.com/images/post/1694177399046_group_313.webp?width=60"
                loading="lazy"
                width="40px"
                height="40px"
                alt="quiz icon"
              />
              <a href="https://www.thelallantop.com/quiz/live">
                <span>क्विज़ खेलें</span>
              </a>
            </li>
            <li>
              <img
                src="https://static.thelallantop.com/images/post/1694177098246_group_313.webp?width=60"
                loading="lazy"
                width="40px"
                height="40px"
                alt="Visual Story icon"
              />
              <a href="https://www.thelallantop.com/visualstories">
                <span>विज़ुअल स्टोरीज़</span>
              </a>
            </li>
            <li>
              <img
                src="https://static.thelallantop.com/images/post/1693554253186_group.webp?width=60"
                loading="lazy"
                width="40px"
                height="40px"
                alt="settings-icon"
              />
              <a href="https://www.thelallantop.com/topic">
                <span>टॉपिक्स</span>
              </a>
            </li>
            <li>
              <img
                src="https://static.thelallantop.com/images/post/1694177324013_group_312.webp?width=60"
                loading="lazy"
                width="40px"
                height="40px"
                alt="Polls icon"
              />{" "}
              <a href="https://www.thelallantop.com/polls">
                <span>पोल्स</span>
              </a>
            </li>
            <li>
              <img
                src="https://static.thelallantop.com/images/post/1693554312990_group.webp?width=60"
                loading="lazy"
                width="40px"
                height="40px"
                alt="our-team-icon"
              />
              <a href="https://www.thelallantop.com/team-lallantop">
                <span>टीम लल्लनटॉप</span>
              </a>
            </li>
            <li>
              <img
                src="https://static.thelallantop.com/images/post/1693554253186_group.webp?width=60"
                loading="lazy"
                width="40px"
                height="40px"
                alt="settings-icon"
              />
              <a href="https://www.thelallantop.com/settings">
                <span>सेटिंग्स</span>
              </a>
            </li>
            <li>
              <img
                src="https://static.thelallantop.com/images/post/1694177476065_group_312.webp?width=60"
                loading="lazy"
                width="40px"
                height="40px"
                alt="quiz icon"
              />
              <a href="https://www.thelallantop.com/feedback">
                <span>फीडबैक</span>
              </a>
            </li>
          </ul>
        </div>
      </amp-sidebar>
      <style jsx="">
        {`
          #sidebar1 {
            width: 100vw;
          }

          .nav-link {
            text-decoration: none;
            position: relative;
            margin: 0 1.25rem;
            font-size: 16px;
            white-space: pre;
            color: #333;
          }
          .sidebar-top {
            background-color: #eb0e8c;
            position: relative;
            border-bottom-left-radius: 14px;
            border-bottom-right-radius: 14px;
          }
          .sidebar-top a {
            text-decoration: none;
            color: white;
          }
          .head-top {
            padding-bottom: 1.5rem;
            display: flex;
            justify-content: space-evenly;
          }
          .menu-head-box {
            font-size: 1rem;
            text-align: center;
          }
          .menu-head-text {
            font-size: 14px;
            font-weight: 600;
          }
          .profile-box {
            display: flex;
            flex-direction: column;
            padding: 1rem 3rem 1.6rem;
          }
          .user-pic {
            text-align: center;
            padding: 2px;
            border-radius: 50%;
          }
          .pro-text {
            text-align: center;
            margin-top: 8px;
          }
          .header-top {
            background-color: #d9368b;
            height: 40px;
            display: flex;
            justify-content: space-between;
            padding: 10px 10px 6px 10px;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: 100;
          }
          .menu-body {
            color: white;
          }
          .menu-body ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          .header-right {
            margin-right: 5vw;
          }
          .menu-body ul li {
            border-bottom: 0.1rem solid #c4c4c4;
            display: flex;
            padding-left: 7px;
            height: 50px;
          }
          .header-left {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .menu-body ul li img {
            align-self: center;
          }
          .menu-body ul li a {
            width: 100%;
            font-size: 16px;
            text-decoration: none;
            align-self: center;
            padding-left: 1rem;
          }

          .menu-body ul li span {
            font-weight: 500;
            color: #000;
          }
          .menu-body ul li i {
            color: #d9368b;
            margin-right: 1.125rem;
            width: 5rem;
            font-size: 2rem;
          }

          .header-below {
            background-color: white;
            width: auto;
            height: 40px;
            display: flex;
            border-bottom: 1px solid #e0dddd;
            padding-top: 50px;
          }
          .hb-menu-line {
            background: #fff;
            width: 22px;
            height: 4px;
            display: block;
            border-radius: 22%;
            margin-bottom: 4px;
          }
          .hb-menu-line-last {
            background: #fff;
            width: 15px;
            height: 4px;
            display: block;
            border-radius: 22%;
          }
          .hm-button {
            margin-top: 0px;
            background-color: #d9368b;
            border: none;
            margin-left: 5px;
          }
          .category-list {
            display: flex;
            list-style: none;
            flex-direction: row;
            width: 100%;
            margin-left: -40px;
          }
          .category-list-child {
            margin: 0 1.25rem;
            font-size: 18px;
            white-space: pre;
            color: #333;
          }
          .category-nav {
            width: 100%;
            overflow-x: scroll;
            align-self: center;
          }
          .category-nav::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
    </>
  );
};

export default AmpHeader;
