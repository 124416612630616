export const USER_INIT = "user/init";

// User ProfileTypes

export const USER_PROFILE_UPDATE_INIT = "USER_PROFILE_UPDATE_INIT";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_FAILED = "USER_PROFILE_UPDATE_FAILED";

export const USER_PROFILE_BY_ID_INIT = "USER_PROFILE_BY_ID_INIT";
export const USER_PROFILE_BY_ID_SUCCESS = "USER_PROFILE_BY_ID_SUCCESS";
export const USER_PROFILE_BY_ID_FAILED = "USER_PROFILE_BY_ID_FAILED";

// email notification
export const EMAIL_NOTIFICATION_INIT = "EMAIL_NOTIFICATION_INIT";
export const EMAIL_NOTIFICATION_SUCCESS = "EMAIL_NOTIFICATION_SUCCESS";
export const EMAIL_NOTIFICATION_FAILED = "EMAIL_NOTIFICATION_FAILED";

// User preferences
export const USER_PREFERENCES_UPDATE_INIT = "USER_PREFERENCES_UPDATE_INIT";
export const USER_PREFERENCES_UPDATE_SUCCESS =
  "USER_PREFERENCES_UPDATE_SUCCESS";
export const USER_PREFERENCES_UPDATE_FAILED = "USER_PREFERENCES_UPDATE_FAILED";
