import { getAuthorizedApi } from "api/apiInstance";
import { baseUrl } from "api/constants";
import { getTokenFromCookies } from "constants/loginConstants";
import { logger } from "logging/log-util";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  CATEGORY_GET_FAILED,
  CATEGORY_GET_INIT,
  CATEGORY_GET_SUCCESS,
  SUBCATEGORY_FAILED_DATA,
  SUBCATEGORY_INIT_DATA,
  SUBCATEGORY_SUCCESS_DATA,
} from "redux/actionTypes/category.actionTypes";
import { SHOW_MODAL_POPUP } from "../actionTypes/common.actionTypes";
import { getCookie, setCookie } from "utils/cookieHelper";

function* getCategory() {
  //  let token = yield fetch("/api/getToken", {
  //     method: "get",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   }).then((res) => res.json());
  //   //  .then(({ encryptedToken }) => encryptedToken || "");
  //   if( typeof token === 'string' ) {
  //     token  = JSON.parse(token);
  //     if(token.encryptedToken) {
  //       token = token.encryptedToken;
  //     }
  //   }else if(typeof token === 'object') {
  //     if(token.encryptedToken) {
  //       token = token.encryptedToken;
  //     }
  //   }
  //.then(({encryptedToken})=> encryptedToken || "")
  try {
    if (typeof window.localStorage !== "undefined") {
      // let categories = window.localStorage.getItem("categoriesNew2");
      let categories = decodeURIComponent(getCookie("categoriesNew2"));
      if (categories) {
        console.log("category data from cache");
        yield put({
          type: CATEGORY_GET_SUCCESS,
          payload: {
            entity: JSON.parse(categories),
          },
        });
      } else {
        const token = getTokenFromCookies(document.cookie);
        const client = yield getAuthorizedApi(token);
        const { data } = yield call(client.get, `${baseUrl}/category`);

        if (data.status) {
          // window.localStorage.setItem(
          //   "categoriesNew2",
          //   JSON.stringify(data?.result)
          // );
          let ct = []
          data?.result?.categories?.map((item)=>{
            ct.push({
              categoryRank:item.categoryRank,
              count: item?.count,
              name: item?.name,
              nameHn: item?.nameHn,
              postsCount: item?.postsCount,
              slug: item?.slug
            })
          })
          let obj = {
            categories: ct
          }
          var stringified = encodeURIComponent(JSON.stringify(obj));
          setCookie("categoriesNew2",stringified,2);
          yield put({
            type: CATEGORY_GET_SUCCESS,
            payload: {
              entity: data?.result,
            },
          });
        } else {
          yield put({
            type: CATEGORY_GET_FAILED,
            payload: {
              error: true,
              message: data?.message || "Error Occured",
            },
          });
        }
      }
    } else {
      const token = getTokenFromCookies(document.cookie);
      const client = yield getAuthorizedApi(token);
      const { data } = yield call(client.get, `${baseUrl}/category`);

      if (data.status) {
        console.log("category data from api");
        yield put({
          type: CATEGORY_GET_SUCCESS,
          payload: {
            entity: data?.result,
          },
        });
      } else {
        yield put({
          type: CATEGORY_GET_FAILED,
          payload: {
            error: true,
            message: data?.message || "Error Occured",
          },
        });
      }
    }
  } catch (e) {
    console.log(e, error?.response?.status);
    if (error?.response.status === 401 || error?.response.status === 403) {
      yield put({
        type: SHOW_MODAL_POPUP,
        payload: {
          error: true,
          message: "Session Expired. Please relogin again.",
        },
      });
    } else {
      if (error?.response.status <= 500) {
        yield put({
          type: SHOW_MODAL_POPUP,
          payload: {
            error: true,
            message: new Error(e).message,
          },
        });
      } else {
        console.log("@@Error occured -", error.response?.data?.message);
      }
    }
  }
}

function* subCategoriesItems({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json())
  //     /*.then(({encryptedToken})=> encryptedToken || "")*/;
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/category/sub-categories`,
      payload
    );
    if (data.status) {
      yield put({
        type: SUBCATEGORY_SUCCESS_DATA,
        payload: {
          message: data.message,
          entity: data?.result,
        },
      });
    } else {
      yield put({
        type: SUBCATEGORY_FAILED_DATA,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
  }
}

function* categorySaga() {
  yield takeLatest(CATEGORY_GET_INIT, getCategory);
  yield takeLatest(SUBCATEGORY_INIT_DATA, subCategoriesItems);
}

export default categorySaga;