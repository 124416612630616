import { useEffect, useState } from 'react';
import { useSession, signIn } from 'next-auth/react';

export function useOneTapSignin(opt) {
  const { status } = useSession();
  const isSignedIn = status === 'authenticated';
  const { parentContainerId } = opt || {};
  const [isLoading, setIsLoading] = useState(false);
  const google = typeof window !== 'undefined'? window?.google:null;

  useEffect(() => {
    if (!isLoading && !isSignedIn && typeof window !== 'undefined') {
      const { google } = window;
      console.log("google", google)
      if (google) {
        // console.log("google found",google)
        google?.accounts?.id?.initialize({
          client_id: process.env.NEXT_PUBLIC_GOOGLE_ID,
          callback: async (response) => {
            setIsLoading(true);

            // Here we call our Provider with the token provided by google
            await signIn('googleonetap', {
              credential: response.credential,
              redirect: true,
              ...opt,
            });
            setIsLoading(false);
          },
          prompt_parent_id: parentContainerId,
          style:
            'position: absolute; top: 100px; right: 30px;width: 0; height: 0; z-index: 1001;',
        });

        // Here we just console.log some error situations and reason why the google one tap
        // is not displayed. You may want to handle it depending on yuor application
        google?.accounts?.id?.prompt((notification) => {
          console.log("goood going")
          if (notification.isNotDisplayed()) {
            console.log("4543",notification,notification.getNotDisplayedReason());
          } else if (notification.isSkippedMoment()) {
            console.log("1234",notification.getSkippedReason());
          } else if (notification.isDismissedMoment()) {
            console.log("9898",notification.getDismissedReason());
          }
        });
      } else{
        console.log("google not found")
      }
    }
  }, [google,isLoading, isSignedIn, parentContainerId]);

  return { isLoading };
};

// export default useOneTapSignin;