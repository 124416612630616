import { getAuthorizedApi } from "api/apiInstance";
// import { baseUrl } from "api/constants";
import { call, put, takeLatest } from "redux-saga/effects";
import { getTokenFromCookies } from "constants/loginConstants";
import { BLOGS_LIST_SUCCESS,GET_BLOGS_LIST } from "redux/actionTypes/liveblogactionTypes";
function* getLiveBlogList({payload}){
    console.log("Live Blog Inside ",payload)
    const token = getTokenFromCookies(document.cookie);
    const client = yield getAuthorizedApi(token);
    try{
        const {data} = yield call(
            client.get,
            `${process.env.NEXT_PUBLIC_LIVE_BLOG_BASE_URL}/liveBlog/list?appSlug=${payload.appSlug}&pageNumer=${payload.pageNumber}&pageSize=${payload.pageSize}`,
        );
        console.log("Live Blog Data ",data.result);
        if(data && data.result){
            yield put({
                type: BLOGS_LIST_SUCCESS,
                payload: {
                  entity: data?.result,
                },
              });
        }
        

    } catch(e){
        logger.error(e);
    }
}

function* liveblogSaga() {
    yield takeLatest(GET_BLOGS_LIST, getLiveBlogList);
  }
  
export default liveblogSaga;