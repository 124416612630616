import { getAuthorizedApi, 
  // api 
} from "api/apiInstance";
import { baseUrl } from "api/constants";
import { getTokenFromCookies } from "constants/loginConstants";
import { logger } from "logging/log-util";
import { call, put, takeLatest } from "redux-saga/effects";
// import { catchError } from "redux/actions/quizActions";
import {
  DELETE_BOOKMARKS_FAILED,
  DELETE_BOOKMARKS_INIT,
  DELETE_BOOKMARKS_SUCCESS,
  GET_BOOKMARKS_BY_TYPE_FAILED,
  GET_BOOKMARKS_BY_TYPE_INIT,
  GET_BOOKMARKS_BY_TYPE_SUCCESS,
  SAVE_BOOKMARKS_FAILED,
  SAVE_BOOKMARKS_INIT,
  SAVE_BOOKMARKS_SUCCESS,
} from "redux/actionTypes/bookmarks.actionTypes";
import { GET_POST_BY_ID_INIT } from "redux/actionTypes/post.actionTypes";
// import { decryptData } from "utils/encryption";
import {
  REFRESH_PAGE_INIT,
  //SHOW_MODAL_POPUP,
} from "../actionTypes/common.actionTypes";

function* getBookmarksByType({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json());
  // /*.then(({ encryptedToken }) => encryptedToken || "");*/

  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }

  const token = getTokenFromCookies(document.cookie);

  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/bookmark/list?type=${payload.activeTab}&limit=10&skip=1`
    );
    if (data.status) {
      yield put({
        type: GET_BOOKMARKS_BY_TYPE_SUCCESS,
        payload: {
          bookmarkTypeData: data?.result.bookmars,
          message: data?.message,
        },
      });
    } else {
      yield put({
        type: GET_BOOKMARKS_BY_TYPE_FAILED,
        payload: {
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    // yield put({
    //   type: SHOW_MODAL_POPUP,
    //   payload: {
    //     error: true,
    //     message: new Error(e).message,
    //   },
    // });
  }
}

function* deleteAllBookmarks({ payload }) {
  // const client = yield getAuthorizedApi(token);
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json()); /*
  //   .then(({ encryptedToken }) => encryptedToken || "")*/
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  // const accessToken = yield decryptData(token);
  // const  data  = yield fetch(`${baseUrl}/bookmark`, {
  //   method: "DELETE",
  //   headers: {
  //     "content-type": "application/json",
  //     "X-Requested-With": "XMLHttpRequest",
  //     authorization: `Bearer ${accessToken}`,
  //   },
  //   body: JSON.stringify(payload),
  // }).then((res) => res.json());
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(client.delete, `${baseUrl}/bookmark`, {
      data: payload,
    });
    if (data.status) {
      yield put({
        type: DELETE_BOOKMARKS_SUCCESS,
        payload: {
          message: data?.message || "Error Occured",
        },
      });
      yield put({
        type: GET_BOOKMARKS_BY_TYPE_INIT,
        payload: { activeTab: payload.type },
      });
    } else {
      yield put({
        type: GET_BOOKMARKS_BY_TYPE_FAILED,
        payload: {
          message: data?.message || "Error Occured",
        },
      });
      yield put({
        type: DELETE_BOOKMARKS_FAILED,
        payload: {
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
  }
}

function* saveBookmark({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json()); /*
  //   .then(({ encryptedToken }) => encryptedToken || "")*/
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(client.post, `${baseUrl}/bookmark`, payload);
    if (data.status) {
      yield put({
        type: SAVE_BOOKMARKS_SUCCESS,
        payload: {
          message: data?.message,
        },
      });
      yield put({
        type: GET_POST_BY_ID_INIT,
        //payload: { _id },
        payload: { _id: payload.postId },
        //payload: { payload: { _id } },
      });
      yield put({
        type: REFRESH_PAGE_INIT,
      });
      yield put({
        type: GET_BOOKMARKS_BY_TYPE_INIT,
        payload: { activeTab: payload.type },
      });
    } else {
      yield put({
        type: SAVE_BOOKMARKS_FAILED,
        payload: {
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    // yield put({
    //   type: SHOW_MODAL_POPUP,
    //   payload: {
    //     error: true,
    //     message: new Error(e).message,
    //   },
    // });
  }
}

function* bookmarkSaga() {
  yield takeLatest(GET_BOOKMARKS_BY_TYPE_INIT, getBookmarksByType);
  yield takeLatest(DELETE_BOOKMARKS_INIT, deleteAllBookmarks);
  yield takeLatest(SAVE_BOOKMARKS_INIT, saveBookmark);
}

export default bookmarkSaga;
