import { SHORT_LIST_INIT } from "redux/actionTypes/shorts.ActionTypes";
const iniitialState = {
  shortList: [],
};

export default function ShortsReducer(state = iniitialState, action) {
  switch (action.type) {
    case SHORT_LIST_INIT:
      return {
        shortList: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
