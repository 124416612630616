import hiMessages from "./messages/hi";
import enMessages from "./messages/en";

export const allMessages = {
  hi: hiMessages,
  en: enMessages,
};

export const languages = {
  hi: "hi",
  en: "en",
};

export const defaultLocale = languages.hi;
const i18nConfig = "i18nConfig";

export function setLanguage(locale) {
  if (locale !== getLanguage()) {
    localStorage.setItem(i18nConfig, JSON.stringify({ selectedLang: locale }));
    window.location.reload();
  }
}

export function getLanguage() {
  const lang = localStorage.getItem(i18nConfig);
  if (!lang) return defaultLocale;
  return JSON.parse(lang).selectedLang;
}

export default {
  defaultLocale,
  defaultMessages: allMessages[defaultLocale],
  allMessages,
  setLanguage,
  getLanguage,
};
