import { getAuthorizedApi } from "api/apiInstance";
import { baseUrl } from "api/constants";
// import { result } from "lodash";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getTokenFromCookies } from "constants/loginConstants";
import {
  GET_TRENDING_POST_FAILED,
  GET_TRENDING_POST_INIT,
  GET_TRENDING_POST_SUCCESS,
  LIVESHOWPOST_GET_FAILED,
  LIVESHOWPOST_GET_INIT,
  LIVESHOWPOST_GET_SUCCESS,
  LIVE_STREAM_DATA_INIT,
  LIVE_STREAM_DATA_FAILED,
  LIVE_STREAM_DATA_SUCCESS,
  POST_LIKE_FAILED,
  POST_LIKE_INIT,
  POST_LIKE_SUCCESS,
  GET_POST_BY_ID_INIT,
  GET_POST_BY_ID_SUCCESS,
  GET_POST_BY_ID_FAILED,
  POST_VIEW_COUNT_INIT,
  POST_VIEW_COUNT_FAILED,
  POST_VIEW_COUNT_SUCCESS,
  GET_ALL_POST_SUCCESS,
  GET_ALL_POST_FAILED,
  GET_ALL_POST_INIT,
  GET_ALL_FEATURED_POST_SUCCESS,
  GET_ALL_FEATURED_POST_FAILED,
  GET_ALL_FEATURED_POST_INIT,
  VISUALSTORY_GET_INIT,
  GET_VISUALSTORY_BY_ID_SUCCESS,
  GET_VISUALSTORY_BY_ID_FAILED,
  HOME_CATEGORY_POST,
  GET_RELATED_ARTICLES_INIT,
  GET_RELATED_ARTICLES_SUCCESS,
  GET_RELATED_ARTICLES_FAILED,
  LIVEPOSTSOCKET_GET_INIT,
  LIVEPOSTSOCKET_GET_SUCCESS,
  GET_STATISTICS_INIT,
} from "redux/actionTypes/post.actionTypes";
import { STORY_LIKE_SUCCESS } from "redux/actionTypes/stories.actionTypes";
import {
  REFRESH_PAGE_INIT,
  SHOW_MODAL_POPUP,
} from "../actionTypes/common.actionTypes";
import { logger } from "logging/log-util";

function* getLiveShowPost() {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json()); /*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/live/listWithStreamUrl?limit=10&skip=1`
    );
    console.log("getting data");
    if (data.status) {
      yield put({
        type: LIVESHOWPOST_GET_SUCCESS,
        payload: {
          entity: data?.result,
        },
      });
    } else {
      yield put({
        type: LIVESHOWPOST_GET_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: new Error(e).message,
      },
    });
  }
}
function* getLiveSocketPost({ payload }) {
  console.log("saga", payload);
  yield put({
    type: LIVEPOSTSOCKET_GET_SUCCESS,
    payload,
  });
}
function* getRelatedArtciles({ payload }) {
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.get,
      `${process.env.NEXT_PUBLIC_APP_API_URL}/v2/web/related-article?_id=${payload?.id}`
    );
    if (data.status) {
      yield put({
        type: GET_RELATED_ARTICLES_SUCCESS,
        payload: {
          entities: data?.result,
        },
      });
    } else {
      yield put({
        type: GET_RELATED_ARTICLES_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: new Error(e).message,
      },
    });
  }
}

function* getliveStreamData({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json()); /*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/live/getStreamingUrl`,
      payload
    );
    if (data.status) {
      yield put({
        type: LIVE_STREAM_DATA_SUCCESS,
        payload: {
          entity: data?.result,
          alreadyPlaying: true,
        },
      });
    } else {
      yield put({
        type: LIVE_STREAM_DATA_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: new Error(e).message,
      },
    });
  }
}

function* getPostDetailsById({ payload: { _id } }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json()); /*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    let data = {};
    if (typeof _id != "undefined" && _id) {
      ({ data } = yield call(
        client.get,
        `${baseUrl}/post/detail?id=${_id}&type=post`
      ));
    }

    if (data && data.status) {
      // const { data } = yield call(
      //   client.get,
      //   `${baseUrl}/post/detail?id=${_id}&type=post`
      // );

      // if (data.status) {
      yield put({
        type: GET_POST_BY_ID_SUCCESS,
        payload: {
          postDetails: data?.result,
        },
      });
    } else {
      yield put({
        type: GET_POST_BY_ID_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
  }
}

function* getTrendingPosts() {
  const client = yield getAuthorizedApi();
  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/post/trending?limit=6&skip=1&type=audio,video,text`
    );

    if (data.status) {
      yield put({
        type: GET_TRENDING_POST_SUCCESS,
        payload: {
          trendingPost: data?.result?.entities,
        },
      });
    } else {
      yield put({
        type: GET_TRENDING_POST_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    // yield put({
    //   type: SHOW_MODAL_POPUP,
    //   payload: {
    //     error: true,
    //     message: new Error(e).message,
    //   },
    // });
  }
}

function* postLike({ payload, postType, recordPerPage }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json()); /*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/${postType || "post"}/like-unlike`,
      payload
    );

    if (data.status) {
      yield put({
        type: POST_LIKE_SUCCESS,
        payload: {
          message: data?.message || "",
        },
      });
      if (postType === "story") {
        const { data } = yield call(client.post, `${baseUrl}/story/filter`, {
          limit: recordPerPage || 12,
          skip: 1,
          categories: [],
        });
        const filteredStoryData = data.result?.stories?.filter(
          (storyData) => storyData?._id === payload?.storyId
        )[0];
        if (filteredStoryData) {
          yield put({
            type: STORY_LIKE_SUCCESS,
            payload: { storyById: filteredStoryData },
          });
        }
      }

      yield put({
        type: REFRESH_PAGE_INIT,
      });
      yield put({
        type: GET_POST_BY_ID_INIT,
        payload: {
          _id:
            postType === "column"
              ? payload.columnId
              : postType === "story"
              ? payload.storyId
              : payload._id,
        },
      });
    } else {
      yield put({
        type: POST_LIKE_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error("error,", e);
    yield put({
      type: POST_LIKE_FAILED,
      payload: {
        error: true,
        message: data?.message || "Error Occured",
      },
    });
  }
}
function* postViewCount({ payload, postType }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json()); /*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/${postType || "post"}/view`,
      payload
    );

    if (data.status) {
      yield put({
        type: POST_VIEW_COUNT_SUCCESS,
        payload: {
          message: data?.message || "",
        },
      });
      if (postType === "story") {
        const { data } = yield call(client.post, `${baseUrl}/story/filter`, {
          limit: recordPerPage || 12,
          skip: 1,
          categories: [],
        });
        const filteredStoryData = data.result?.stories?.filter(
          (storyData) => storyData?._id === payload?.storyId
        )[0];
        if (filteredStoryData) {
          yield put({
            type: POST_VIEW_COUNT_SUCCESS,
            payload: { storyById: filteredStoryData },
          });
        }
      }

      // yield put({
      //   type: REFRESH_PAGE_INIT,
      // });
      // yield put({
      //   type: GET_POST_BY_ID_INIT,
      //   payload: {
      //     _id: postType === "column" ? payload.columnId : postType === "story" ? payload.storyId : payload._id,
      //   },
      // });
      yield put({
        type: REFRESH_PAGE_INIT,
      });
      yield put({
        type: GET_POST_BY_ID_INIT,
        payload: {
          _id:
            postType === "column"
              ? payload.columnId
              : postType === "story"
              ? payload.storyId
              : payload._id,
          type: postType === "story" ? "story" : postType,
        },
      });
    } else {
      yield put({
        type: POST_VIEW_COUNT_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error("error,", e);
    yield put({
      type: POST_VIEW_COUNT_FAILED,
      payload: {
        error: true,
        message: data?.message || "Error Occured",
      },
    });
  }
}
function* getPostAllData({ payload: { contentType } }) {
  /*let token = yield fetch("/api/getToken", {
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  }).then(res => res.json())/!*.then(({
    encryptedToken
  }) => encryptedToken || "")*!/;
  if( typeof token === 'string' ) {
    token  = JSON.parse(token);
    if(token.encryptedToken) {
      token = token.encryptedToken;
    }
  }else if(typeof token === 'object') {
    if(token.encryptedToken) {
      token = token.encryptedToken;
    }
  }*/
  const client = yield getAuthorizedApi();
  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/post/list?type=${
        contentType === "all" ? "video,text" : contentType
      }&limit=12&skip=1`
    );

    if (data.status) {
      yield put({
        type: GET_ALL_POST_SUCCESS,
        payload: {
          allPosts: data.result,
          message: data?.message || "",
        },
      });
    } else {
      yield put({
        type: GET_ALL_POST_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error("error,", e);
    yield put({
      type: GET_ALL_POST_FAILED,
      payload: {
        error: true,
        message: data?.message || "Error Occured",
      },
    });
  }
}
function* getAllFeaturedData({ payload: { contentType } }) {
  /*let token = yield fetch("/api/getToken", {
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  }).then(res => res.json())/!*.then(({
    encryptedToken
  }) => encryptedToken || "")*!/;
  if( typeof token === 'string' ) {
    token  = JSON.parse(token);
    if(token.encryptedToken) {
      token = token.encryptedToken;
    }
  }else if(typeof token === 'object') {
    if(token.encryptedToken) {
      token = token.encryptedToken;
    }
  }*/
  const client = yield getAuthorizedApi();
  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/featured/rankFeatured?limit=12&skip=1&type=${
        contentType === "all" ? "video,text" : contentType
      }`
    );

    if (data.status) {
      yield put({
        type: GET_ALL_FEATURED_POST_SUCCESS,
        payload: {
          featuredPosts: data.result.entities,
          message: data?.message || "",
        },
      });
    } else {
      yield put({
        type: GET_ALL_FEATURED_POST_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error("error,", e);
    yield put({
      type: GET_ALL_FEATURED_POST_FAILED,
      payload: {
        error: true,
        message: data?.message || "Error Occured",
      },
    });
  }
}
function* getAllCategoryHomePosts({ payload: { contentType } }) {}

function* getVisualStoryDetailsById({ payload }) {
  const { storyId } = payload;
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json()); /*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/story/details?storyId=${storyId}`
    );

    if (data.status && Object.keys(data?.result).length !== 0) {
      yield put({
        type: GET_VISUALSTORY_BY_ID_SUCCESS,
        payload: {
          story: data?.result,
        },
      });
    } else {
      yield put({
        type: GET_VISUALSTORY_BY_ID_FAILED,
      });
    }
  } catch (e) {
    logger.error(e);
    yield put({
      type: GET_VISUALSTORY_BY_ID_FAILED,
    });
  }
}

function* getStatistics({ payload }) {
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.post,
      `${process.env.NEXT_PUBLIC_APP_API_URL}/v2/web/getStatistics`,
      {
        _id: payload,
      }
    );

    console.log("calling stats ", data);

    if (data.status && data.result) {
      yield put({
        type: GET_STATISTICS_INIT,
        payload: {
          stats: data?.result,
        },
      });
    }
  } catch (e) {
    // logger.error(e);
    // yield put({
    //   type: GET_VISUALSTORY_BY_ID_FAILED,
    // });
  }
}
function* liveShowPostSaga() {
  yield takeLatest(LIVESHOWPOST_GET_INIT, getLiveShowPost);
  yield takeLatest(LIVEPOSTSOCKET_GET_INIT, getLiveSocketPost);
  yield takeLatest(GET_TRENDING_POST_INIT, getTrendingPosts);
  yield takeEvery(POST_LIKE_INIT, postLike);
  yield takeEvery(GET_RELATED_ARTICLES_INIT, getRelatedArtciles);
  yield takeLatest(LIVE_STREAM_DATA_INIT, getliveStreamData);
  yield takeEvery(GET_POST_BY_ID_INIT, getPostDetailsById);
  yield takeLatest(POST_VIEW_COUNT_INIT, postViewCount);
  yield takeEvery(GET_ALL_POST_INIT, getPostAllData);
  yield takeEvery(GET_ALL_FEATURED_POST_INIT, getAllFeaturedData);
  yield takeEvery(VISUALSTORY_GET_INIT, getVisualStoryDetailsById);
  yield takeEvery(HOME_CATEGORY_POST, getAllCategoryHomePosts);
  yield takeEvery(GET_STATISTICS_INIT, getStatistics);
}

export default liveShowPostSaga;
