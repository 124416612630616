import { getLanguage } from "localization/i8intl";
import React from "react";

// This is the custom wrapper component you would build and use just like `next/link`:

export class MyLink extends React.Component {
  render() {
    const { onCustomClick, ...props } = this.props;
    return <a {...props} onClick={this.handleClick} />;
  }

  handleClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (this.props.onCustomClick) {
      this.props.onCustomClick(event);
    }
  };
}
const getPostType = (postType) => {
  if (postType === "text") {
    return "Read";
  } else if (postType === "video") {
    return "Video";
  } else if (postType === "audio") {
    return "Audio";
  } else if (postType) {
    return "Visual Stories";
  } else {
    return "Undefined";
  }
};

const loginTypeMapping = (loginType) => {
  switch (loginType) {
    case 1:
      return "Phone";
    case 2:
      return "Facebook";
    case 3:
      return "Google";
    case 4:
      return "Apple";
    case 5:
      return "Email";
    default:
      return "Phone";
  }
};

// MobileWeb

export const createCardPayload = (eventName, postStateData) => {
  const payload = {
    "Content ID": postStateData?._id,
    "Content Title": postStateData?.title,
    "Content Type": getPostType(postStateData?.type),
    "Content Category Name": postStateData?.categoryName,
    "Content Sub Category Name": postStateData?.subCategoryName,
    "Content Author name": postStateData?.postedByName,
    "App Language": getLanguage() === "en" ? "English" : "Hindi",
    "Episode Number": "",
    "Show/Series Name": postStateData?.seriesTitle,
  };
  return payload;
};
export const createViewCommentPayload = (eventName, postStateData) => {
  const payload = {
    "Content ID": postStateData?._id,
    "Content Title": postStateData?.title,
    "Content Type": getPostType(postStateData?.type),
    "Content Category Name": postStateData?.categoryName,
    "Content Sub Category Name": postStateData?.subCategoryName,
    "Content Author name": postStateData?.postedByName,
    "Episode Number": "",
    "Show/Series Name": postStateData?.seriesTitle,
  };
  return payload;
};

export const createHashtagPayload = (eventName, hashtag) => {
  const payload = {
    "Hashtag Unique ID": hashtag?._id,
    "Published Date": hashtag?.updatedAt
      ? hashtag?.updatedAt
      : hashtag?.createdAt,
    "Number of Searches with Hashtag": 0,
    "Content Views with Hashtag": 0,
    "Content Created with Hastag (count)": hashtag?.postCount,
    "Hashtag Name": hashtag?.title,
  };
  return payload;
};
export const createBookmarkPayload = (eventName, postStateData) => {
  return {
    "Content ID": postStateData?._id,
    "Content Story Title": postStateData?.title,
    "Content Type": getPostType(postStateData?.type),
    "Category Name": postStateData?.categoryName,
    "Sub Category Name": postStateData?.subCategoryName,
    "Author name": postStateData?.postedByName,
    // 			Season Number, if any"
  };
};
export const createPollPayload = (opinionData, postStateData) => {
  const payload = {
    "Content ID": postStateData?.storyId,
    "Content Story Title": postStateData?.storyText,
    "Poll ID": postStateData?._id,
    "Poll Question": postStateData?.question,
    "Number of option": postStateData?.opinions?.length,
    "Content Type": "Poll",
    "Category Name": postStateData?.categoryName,
    "Sub Category Name": postStateData?.subCategoryName,
    "Author name": postStateData?.postedByName
      ? postStateData?.postedByName
      : opinionData?.postedById,
  };
  return payload;
};

export const createPollPagePayload = (postStateData) => {
  const payload = {
    "Content ID": postStateData?.storyId,
    "Content Story Title": postStateData?.storyText,
    "Poll ID": postStateData?._id,
    "Poll Question": postStateData?.question,
    "Number of option": postStateData.opinions?.length,
    "Content Type": "Poll",
    "Category Name": postStateData?.categoryName,
    "Author name": postStateData?.postedByName,
  };
  return payload;
};

export const createStoryPayload = (eventName, postStateData) => {
  return {
    "Story Content ID": postStateData?._id,
    "Story Title": postStateData?.title,
    "Content Type": "Read",
    "Story Category Name": postStateData?.categoryId.name,
    "Story Author name": postStateData?.author.name,
    // "Story Series Name":postStateData?.seriesTitle,
  };
};
export const createStoryCardPayload = (eventName, postStateData) => {
  return {
    "Story Content ID": postStateData?._id,
    "Story Title": postStateData?.title,
    "Story Category Name": postStateData?.categoryId.name,
    "Story Author name": postStateData?.author.name,
  };
};

export const createHomeFatafatPayload = (
  eventName,
  postStateData,
  totalLength
) => {
  const payload = {
    "Content ID": postStateData?._id,
    "Fatafat News Title": postStateData?.title,
    "Content Type": getPostType(postStateData?.type),
    "Content Category Name": postStateData?.categoryName,
    "Content Author name": postStateData?.postedByName,
    "Date of Publish": postStateData?.createdAt,
    "Number of fatafat news stories": totalLength,
    "Content Series name": postStateData?.seriesTitle,
  };
  return payload;
};

export const createHomeLivePayload = (eventName, postStateData) => {
  const payload = {
    "Live ID": postStateData?._id,
    "Vimeo ID": postStateData?.vimeoId,
    "Live Title": postStateData?.title,
    "Live Content Type": "Video",
    "Category Name": postStateData?.categoryName,
    "Live Schedule Time": postStateData?.liveAt,
    "hashtags linked": postStateData?.hashtags.join(" "),
    "Chat enabled": postStateData.isChatEnabled ? "Yes" : "No",
  };
  return payload;
};
export const createLiveStartedPayload = (eventName, postStateData) => {
  const payload = {
    "Content ID": postStateData?._id,
    "Live Title": postStateData?.title,
    "Live Content Type": "Video",
    "Category Name": postStateData?.categoryName,
    "Live Schedule Time": postStateData?.liveAt,
    "Content Duration": "",
    "Episode Number": "",
    "Show name": postStateData?.seriesTitle,
    "Network Type": "",
    "App Language": getLanguage() === "en" ? "English" : "Hindi",
  };
  return payload;
};
export const createLiveClosePayload = (eventName, postStateData) => {
  const payload = {
    "Content ID": postStateData?._id,
    "Live Title": postStateData?.title,
    "Live Content Type": "Video",
    "Category Name": postStateData?.categoryName,
    "Live Schedule Time": postStateData?.liveAt,
    "Show name": postStateData?.seriesTitle,
    "Watch time/Listening time": "",
    "Episode Number": "",
    "Network Type": "",
    "App Language": getLanguage() === "en" ? "English" : "Hindi",
  };
  return payload;
};
export const createHomeCardPayload = (eventName, postStateData) => {
  const payload = {
    "Content ID": postStateData?._id,
    "Story Title": postStateData?.title,
    "Content Type": getPostType(postStateData?.type),
    "Category Name": postStateData?.categoryName,
    "Sub Category Name": postStateData?.subCategoryName,
    // "Author name": "",
    "Publish date": postStateData?.createdAt,
    "hashtags linked": postStateData?.hashtags.join(" "),
    "Published By": postStateData?.postedByName,
  };
  return payload;
};

export const createEditProfilePayload = (eventName, user) => {
  const payload = {
    "User ID": user?._id,
    "User Profile Name": user?.fullname,
    "User name": user?.username,
    Email: user?.email,
    Phone: user?.phoneNumber,
    "Login Method": loginTypeMapping(user?.loginType),
    Status: "Successful",
  };
  return payload;
};
export const createOpenSettingPayload = (eventName, user) => {
  const payload = {
    "User ID": user?._id,
    "User Profile Name": user?.fullname,
    Email: user?.email,
    Phone: user?.phoneNumber,
    "Login Status": "Login Successful",
  };
  return payload;
};
export const createEditDetailPayload = (eventName, user) => {
  const payload = {
    "User ID": user?._id,
    "Login Status": "Login Successful",
    "Login method": loginTypeMapping(user?.loginType),
  };
  return payload;
};
export const createLogoutPayload = (eventName, user) => {
  const payload = {
    "Login method": loginTypeMapping(user?.loginType),
    "Login Status": "Login Successful",
    "Login User Details": user?._id,
  };
  return payload;
};
export const createShareSettingPayload = (eventName, user) => {
  const payload = {
    "User ID": user?._id,
    "User Profile Name": user?.fullname,
    Email: user?.email,
    Phone: user?.phoneNumber,
    "Login Status": "Login Successful",
  };
  return payload;
};
export const createRatingSettingPayload = (eventName, user, rating) => {
  const payload = {
    "User ID": user?._id,
    "User Profile Name": user?.fullname,
    Email: user?.email,
    Phone: user?.phoneNumber,
    Status: rating,
  };
  return payload;
};

export const createPrefrencesPayload = (eventName, ids, names, status) => {
  const payload = {
    "Category ID": ids.join(" "),
    "Category Name": names?.join(" "),
    "Status of Preference Save": status,
  };
  return payload;
};

export const createNotificationPayload = (eventName, text, status) => {
  const payload = {
    // "Notification ID": "",
    "Notification Type": "Email",
    "Notification Title/Text": text,
    // "Notification Description":"",
    // "Notification Image":"",
    // "Notification Event":"",
    "Notification Allow Status": status ? "ON" : "OFF",
  };
  return payload;
};

export const createSeriesPayload = (eventName, show) => {
  const payload = {
    "Series Title/Name": show.title,
    "Series Description": show.articleHTML,
    "Series Type": getPostType(show.type),
    "Follow Status": show.isFollowed ? "Followed" : "Unfollowed",
    "Series ID": show._id,
    "Category Name": show.categoryName,
    "Author Name ": "",
    "No of Episodes": show.episodesCount,
  };
  return payload;
};

export const createVideoPayload = (
  eventName,
  postStateData,
  streamingStatus
) => {
  const payload = {
    "Content ID": postStateData?._id,
    "Content Title": postStateData?.title,
    "Content Type": "Video",
    "Category Name": postStateData?.categoryName,
    "App Language": getLanguage() === "en" ? "English" : "Hindi",
    "Episode Number": postStateData?.seriesId,
    "streamed upto 25% pc": streamingStatus.percent25,
    "streamed upto 50% pc": streamingStatus.percent50,
    "streamed upto 75% pc": streamingStatus.percent75,
    "Finished Watching": streamingStatus.percent100 ? "Yes" : "No",
    "Network Type": "",
    "Watch time": postStateData?.duration, // not accurate , need to implement percent logic
  };
  return payload;
};
export const createVideoPlayPayload = (eventName, postStateData) => {
  const payload = {
    "Content ID": postStateData?._id,
    "Content Title": postStateData?.title,
    "Content Type": "Video",
    "Category Name": postStateData?.categoryName,
    "App Language": getLanguage() === "en" ? "English" : "Hindi",
    "Content Duration": postStateData?.duration,
    "Episode Number": postStateData?.seriesId,
    "Network Type": "",
  };
  return payload;
};
