import {
  OPINIONDATA_GET_INIT,
  OPINIONDATA_GET_SUCCESS,
  OPINIONDATA_GET_FAILED,
  PREVIOUS_POLL_INIT,
  PREVIOUS_POLL_SUCCESS,
  PREVIOUS_POLL_FAILED,
  POST_COMMENT_INIT,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_FAILED,
  GET_POST_COMMENT_SUCCESS,
  GET_POST_COMMENT_FAILED,
  POLL_RESPONSE_RESET,
  SAVE_POST_POLL_SUCCESS,
  SAVE_POST_POLL_FAILED,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILED,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILED,
  POST_UPDATE_COMMENT_SUCCESS,
  POST_UPDATE_COMMENT_FAILED,
  GET_POLLS_FETCHED,
} from "redux/actionTypes/opinion.actionTypes";

const iniitialState = {
  opinion: {},
  error: false,
  message: "",
  previousPolls: [],
  refreshPage: false,
  commentsData: [[], [], [], [], []],
  pollResponse: {},
  commentsUpdate: [],
  pollsData: [],
};

export default function OpinionReducer(state = iniitialState, action) {
  switch (action.type) {
    // Do something here based on the different types of actions

    case OPINIONDATA_GET_INIT:
      return {
        ...state,
        ...action.payload,
      };

    case OPINIONDATA_GET_SUCCESS:
      return {
        ...state,
        ...action.payload,
        opinion: action.payload.entity,
        error: false,
      };
    case OPINIONDATA_GET_FAILED:
      return {
        ...state,
        ...action.payload,
        message: action.payload.message,
        error: true,
      };

    case PREVIOUS_POLL_INIT:
      return {
        ...state,
        ...action.payload,
        message: "fetching data",
        loading: true,
      };

    case PREVIOUS_POLL_SUCCESS:
      return {
        ...state,
        previousPolls: action.payload.previousPolls,
        message: "",
        loading: false,
        error: false,
      };
    case PREVIOUS_POLL_FAILED:
      return {
        ...state,
        message: "Failed to fetch",
        error: true,
        loading: false,
      };

    // post comment by user
    case POST_COMMENT_INIT:
      return {
        ...state,
        ...action.payload,
        message: "fetching data",
        loading: true,
      };

    case POST_COMMENT_SUCCESS:
      return {
        ...state,
        message: "Updated Successfully!",
        loading: false,
        error: false,
      };
    case POST_COMMENT_FAILED:
      return {
        ...state,
        message: "Failed to fetch",
        error: true,
        loading: false,
      };

    // Get post commentsnde

    case GET_POST_COMMENT_SUCCESS: {
      return {
        ...state,
        message: "Updated Successfully!",
        commentsData: state.commentsData.map((comment, i) =>
          i === action.payload?.index ? action.payload.commentsData : comment
        ),
        loading: false,
        error: false,
      };
    }
    case GET_POST_COMMENT_FAILED:
      return {
        ...state,
        message: "Failed to fetch",
        error: true,
        loading: false,
      };

    // Get post poll response
    case POLL_RESPONSE_RESET:
      return {
        ...state,
        pollResponse: {},
      };

    case SAVE_POST_POLL_SUCCESS:
      return {
        ...state,
        error: false,
        message: "",
        pollResponse: action.payload.pollResponse,
      };
    case SAVE_POST_POLL_FAILED:
      return {
        ...state,
        message: "Failed to fetch",
        error: true,
        loading: false,
      };

    //comment delete
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        error: false,
        message: "",
      };
    case DELETE_COMMENT_FAILED:
      return {
        ...state,
        message: "Failed to fetch",
        error: true,
        loading: false,
      };

    //comment update
    case UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        error: false,
        message: "",
      };
    case UPDATE_COMMENT_FAILED:
      return {
        ...state,
        message: "Failed to fetch",
        error: true,
        loading: false,
      };
    // update post comment
    case POST_UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        commentsUpdate: action.payload.entity,
        error: false,
        message: "",
      };
    case POST_UPDATE_COMMENT_FAILED:
      return {
        ...state,
        message: "Failed to fetch",
        error: true,
        loading: false,
      };

    case GET_POLLS_FETCHED:
      return {
        ...state,
        ...action.payload,
        error: false,
      };

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
