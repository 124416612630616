import {
  AUTH_LOGGEDIN_INIT,
  AUTH_OTP_INIT,
  AUTH_OTP_RESEND_INIT,
  AUTH_OTP_SUBMIT_INIT,
  AUTH_SIGNIN_INIT,
  AUTH_SIGNIN_SUBMIT,
  AUTH_SIGNOUT,
  AUTH_SIGNUP_CHECK_EMAIL,
  AUTH_SIGNUP_INIT,
  AUTH_SIGNUP_OTP_INIT,
  AUTH_SIGNUP_SUBMIT,
  AUTH_MOBILE_POPUP_SUCCESS,
  AUTH_DELETE_ACCOUNT
  // AUTH_SIGNUP_USER_DETAILS,
} from "redux/actionTypes/auth.actionTypes";

//login actions
export const LoggedIn = (payload) => {
  console.log("LoggedIn", payload)
  return { type: AUTH_LOGGEDIN_INIT, payload };
};

export const SubmitOTP = (payload) => {
  console.log("SubmitOTP", payload)
  return { type: AUTH_OTP_SUBMIT_INIT, payload };
};

export const OtpGenerate = (payload) => {
  console.log("OtpGenerate", payload)
  return { type: AUTH_OTP_INIT, payload };
};

export const ResendOtpGenerate = (payload) => {
  console.log("ResendOtpGenerate", payload)
  return { type: AUTH_OTP_RESEND_INIT, payload };
};

export const LoginInit = () => {
  return { type: AUTH_SIGNIN_INIT };
};
export const SigninSubmit = (signinObj) => {
  return { type: AUTH_SIGNIN_SUBMIT, payload: signinObj };
};
export const LoginAction = (signinObj) => {
  return { type: AUTH_SIGNIN_INIT, payload: signinObj };
};

//Signup actions
export const SignupInit = () => {
  return { type: AUTH_SIGNUP_INIT };
};
export const SignupCheckEmail = (obj) => {
  return { type: AUTH_SIGNUP_CHECK_EMAIL, payload: obj };
};
export const resetPasswordInit = () => {
  return { type: AUTH_SIGNUP_INIT, payload: { currentStep: "resetPassword" } };
};
export const forgotPasswordInit = () => {
  return { type: AUTH_SIGNUP_INIT, payload: { currentStep: "forgotPassword" } };
};
export const SignupOtpInit = (obj) => {
  return { type: AUTH_SIGNUP_OTP_INIT, payload: obj };
};
export const SignupSubmit = (signupObj) => {
  return { type: AUTH_SIGNUP_SUBMIT, payload: signupObj };
};

//logout action
export const handleLogout = () => {
  localStorage.removeItem("lallantopUser");
  return { type: AUTH_SIGNOUT, payload: { isLoggedIn: false } };
};

export const deleteAccount = () =>{
  return {type: AUTH_DELETE_ACCOUNT};
}

// Mobile Pop UP
export const handleMobilePopUpSuccess = () => {
  return { type: AUTH_MOBILE_POPUP_SUCCESS };
};