import {
  EMAIL_NOTIFICATION_INIT,
  USER_PREFERENCES_UPDATE_INIT,
  USER_PROFILE_BY_ID_INIT,
  USER_PROFILE_UPDATE_INIT,
} from "redux/actionTypes/user.actionTypes";

//profile actions

export const getProfileById = (payload) => {
  return { type: USER_PROFILE_BY_ID_INIT, payload };
};

export const updateProfile = (payload) => {
  return { type: USER_PROFILE_UPDATE_INIT, payload };
};

//email notification
export const getEmailNotification = (payload) => {
  return { type: EMAIL_NOTIFICATION_INIT, payload };
};

// user preference
export const updatePreferences = (payload) => {
  return { type: USER_PREFERENCES_UPDATE_INIT, payload };
};
