import { getAuthorizedApi } from "api/apiInstance";
import { baseUrl } from "api/constants";
import { call, put, takeEvery } from "redux-saga/effects";
import { LIKE_APP, RATE_APP } from "redux/actionTypes/review.actionTypes";
import * as actions from "redux/actions/reviewActions";
import { SHOW_MODAL_POPUP } from "../actionTypes/common.actionTypes";
import { getTokenFromCookies } from "constants/loginConstants";
import { logger } from "logging/log-util";

function* RateApp({ payload }) {
  const error = "Failed to rate the app";
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json"
  //   }
  // }).then(res => res.json())/*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/;
  // if(token &&  token && typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(token && token && typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    yield put(actions.startCall());
    const { data } = yield call(client.post, `${baseUrl}/common/rate`, payload);

    if (data.status) {
      yield put(actions.callCompleted({ rateConfirm: true, rateResult: data }));
    } else yield put(actions.catchError(error));
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: new Error(e).message,
      },
    });
  }
}

function* LikeApp({ payload }) {
  const error = "Failed to like the app";
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // })
  //   .then((res) => res.json());
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(token &&  typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    yield put(actions.startCall());
    const { data } = yield call(
      client.post,
      `${baseUrl}/common/like-app`,
      payload
    );

    if (data.status) {
      yield put(actions.callCompleted({ likeResult: data }));
    } else yield put(actions.catchError(error));
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: new Error(e).message,
      },
    });
  }
}

function* reviewSaga() {
  yield takeEvery(RATE_APP, RateApp);
  yield takeEvery(LIKE_APP, LikeApp);
}

export default reviewSaga;
