// poll opinion data

export const OPINIONDATA_GET_INIT = "OPINIONDATA_GET_INIT";
export const OPINIONDATA_GET_SUCCESS = "OPINIONDATA_GET_SUCCESS";
export const OPINIONDATA_GET_FAILED = "OPINIONDATA_GET_FAILED";

// previous poll actions
export const PREVIOUS_POLL_INIT = "PREVIOUS_POLL_INIT";
export const PREVIOUS_POLL_SUCCESS = "PREVIOUS_POLL_SUCCESS";
export const PREVIOUS_POLL_FAILED = "PREVIOUS_POLL_FAILED";

// post comments actions
export const POST_COMMENT_INIT = "POST_COMMENT_INIT";
export const POST_COMMENT_SUCCESS = "POST_COMMENT_SUCCESS";
export const POST_COMMENT_FAILED = "POST_COMMENT_FAILED";

// post comments actions
export const UPDATE_COMMENT_INIT = "UPDATE_COMMENT_INIT";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILED = "UPDATE_COMMENT_FAILED";

// post comments actions
export const DELETE_COMMENT_INIT = "DELETE_COMMENT_INIT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED";

// get comments by Id postId
export const GET_POST_COMMENT_INIT = "GET_POST_COMMENT_INIT";
export const GET_POST_COMMENT_SUCCESS = "GET_POST_COMMENT_SUCCESS";
export const GET_POST_COMMENT_FAILED = "GET_POST_COMMENT_FAILED";

// save post poll response
export const POLL_RESPONSE_RESET = "POLL_RESPONSE_RESET";
export const SAVE_POST_POLL_INIT = "SAVE_POST_POLL_INIT";
export const SAVE_POST_POLL_SUCCESS = "SAVE_POST_POLL_SUCCESS";
export const SAVE_POST_POLL_FAILED = "SAVE_POST_POLL_FAILED";

// update comment response
export const POST_UPDATE_COMMENT_INIT = "POST_UPDATE_COMMENT_INIT";
export const POST_UPDATE_COMMENT_SUCCESS = "POST_UPDATE_COMMENT_SUCCESS";
export const POST_UPDATE_COMMENT_FAILED = "POST_UPDATE_COMMENT_FAILED";

// get polls
export const GET_POLLS_INIT = "GET_POLLS_INIT";
export const GET_POLLS_FETCHED = "GET_POLLS_FETCHED";
