export const CATEGORY_INIT = "category/init";

// User ProfileTypes

export const CATEGORY_GET_INIT = "CATEGORY_GET_INIT";
export const CATEGORY_GET_SUCCESS = "CATEGORY_GET_SUCCESS";
export const CATEGORY_GET_FAILED = "CATEGORY_GET_FAILED";

// subCategory items
export const SUBCATEGORY_INIT_DATA = "SUBCATEGORY_INIT_DATA";
export const SUBCATEGORY_SUCCESS_DATA = "SUBCATEGORY_SUCCESS_DATA";
export const SUBCATEGORY_FAILED_DATA = "SUBCATEGORY_FAILED_DATA";
