import React from "react";
import Head from "next/head";

const AmpFooter = () => {
  return (
    <>
      <Head>
        {/* <script
          async
          custom-element="amp-sidebar"
          src="https://cdn.ampproject.org/v0/amp-sidebar-0.1.js"
        ></script>
        <script
          async
          custom-element="amp-list"
          src="https://cdn.ampproject.org/v0/amp-list-0.1.js"
        ></script>
        <script
          async
          custom-element="amp-bind"
          src="https://cdn.ampproject.org/v0/amp-bind-0.1.js"
        ></script> */}
      </Head>
      <div className="bottom-menu">
        <ul>
          <li>
            <a href="https://www.thelallantop.com/">
              <img
                src="https://static.thelallantop.com/images/post/1701341687160_home.webp"
                className="icon-padding"
                width="20px"
                height="20px"
                alt="footer-home-image"
              />
              <span>होम</span>
            </a>
          </li>
          <li>
            <a href="https://www.thelallantop.com/?fatafat=true">
              <img
                src="https://static.thelallantop.com/images/post/1719994936583_fatafat_black.webp?width=20&height=20"
                className="icon-padding"
                width="20px"
                height="20px"
                alt="footer-quiz-image"
              />
              <span>फटाफट</span>
            </a>
          </li>
          <li>
            <a href="https://www.thelallantop.com/audio">
              <img
                src="https://static.thelallantop.com/images/post/1701341765224_audio-mike.webp"
                className="icon-padding"
                width="20px"
                height="20px"
                alt="footer-audio-image"
              />
              <span>ऑडियोज़</span>
            </a>
          </li>
          <li>
            <a href="https://www.thelallantop.com/short-video">
              <img
                src="https://static.thelallantop.com/images/post/1701341799283_short-icon-dark.webp"
                className="icon-padding"
                width="20px"
                height="20px"
                alt="footer-short-video-image"
              />
              <span>शॉर्ट्स</span>
            </a>
          </li>
          <li>
            <a href="https://www.thelallantop.com/show/all">
              <img
                src="https://static.thelallantop.com/images/post/1701341835518_show1.webp"
                className="icon-padding"
                width="20px"
                height="20px"
                alt="footer-show-image"
              />
              <span>शो</span>
            </a>
          </li>
        </ul>
      </div>
      <style jsx="">
        {`
          .bottom-menu {
            border-top: 1px solid #ddd;
            filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
            position: fixed;
            bottom: 0;
            right: 0;
            width: 100%;
            z-index: 9;
            background-color: #fff;
          }
          .bottom-menu ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .bottom-menu ul li {
            text-align: center;
            width: 100%;
          }
          .bottom-menu ul li a {
            text-align: center;
            padding: 6px 0;
            color: #2a2a2a;
            display: flex;
            flex-flow: column;
            text-decoration: none;
          }

          .bottom-menu .icon-padding {
            padding-right: 0;
            margin: 0 auto;
          }
          .bottom-menu ul li a span {
            margin: 0;
            font-weight: 500;
          }
        `}
      </style>
    </>
  );
};

export default AmpFooter;
