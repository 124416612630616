import pino from "pino";

const hooks = {
  logMethod(inputArgs, method) {
    if (inputArgs.length >= 2) {
      const arg1 = inputArgs.shift();
      const arg2 = inputArgs.shift();

      if (typeof arg2 !== "object" || arg2 instanceof Array) {
        return method.apply(this, [{ data: arg2 }, arg1, ...inputArgs]);
      }

      return method.apply(this, [arg2, arg1, ...inputArgs]);
    }
    return method.apply(this, inputArgs);
  },
};
export const logger = pino(
  {
    level: process.env.NEXT_PUBLIC_LOG_LEVEL ? process.env.NEXT_PUBLIC_LOG_LEVEL : "info",
    formatters: {
      level: (label) => {
        return {
          level: label,
        };
      },
    },
    hooks,
    transport: process.env.NEXT_PUBLIC_PRETTY_LOG === "true" ? {
      target: "pino-pretty",
      options: {
        colorize: true,
        levelFirst: true,
        translateTime: "yyyy-dd-mm, h:MM:ss TT",
      },
    } : null
  },
);

export const overRideDefaultConsole = () => {
  console.log = function (...args) {
    logger.info(...args);
  };
  console.debug = function (...args) {
    logger.debug(...args);
  };
  console.warn = function (...args) {
    logger.warn(...args);
  };
  console.trace = function (...args) {
    logger.trace(...args);
  };
  console.error = function (...args) {
    logger.error(...args);
    // if (
    //   args.some(
    //     (arg) =>
    //       arg.includes("Deprecation") ||
    //       arg.includes("deprecation") ||
    //       arg.includes("Warning") ||
    //       arg.includes("warn") ||
    //       arg.includes("migration") ||
    //       arg.includes("migrate")
    //   )
    // ) {
    //   logger.warn(...args);
    // } else {
    //   logger.error(...args);
    // }
  };
};
