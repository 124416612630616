export const LIVESHOWPOST_GET_INIT = "LIVESHOWPOST_GET_INIT";
export const LIVESHOWPOST_GET_SUCCESS = "LIVESHOWPOST_GET_SUCCESS";
export const LIVESHOWPOST_GET_FAILED = "LIVESHOWPOST_GET_FAILED";
export const LIVEPOSTSOCKET_GET_INIT = "LIVEPOSTSOCKET_GET_INIT";
export const LIVEPOSTSOCKET_GET_SUCCESS = "LIVEPOSTSOCKET_GET_SUCCESS";

// trending posts
export const GET_TRENDING_POST_INIT = "GET_TRENDING_POST_INIT";
export const GET_TRENDING_POST_SUCCESS = "GET_TRENDING_POST_SUCCESS";
export const GET_TRENDING_POST_FAILED = "GET_TRENDING_POST_FAILED";

// trending posts
export const GET_POST_BY_ID_INIT = "GET_POST_BY_ID_INIT";
export const GET_POST_BY_ID_SUCCESS = "GET_POST_BY_ID_SUCCESS";
export const GET_POST_BY_ID_FAILED = "GET_POST_BY_ID_FAILED";

// like-unlike post
export const POST_LIKE_INIT = "POST_LIKE_INIT";
export const GET_STATISTICS_INIT = "GET_STATISTICS_INIT";
export const POST_LIKE_SUCCESS = "POST_LIKE_SUCCESS";
export const POST_LIKE_FAILED = "POST_LIKE_FAILED";
// live stream data
export const LIVE_STREAM_DATA_INIT = "LIVE_STREAM_DATA_INIT ";
export const LIVE_STREAM_DATA_SUCCESS = "LIVE_STREAM_DATA_SUCCESS";
export const LIVE_STREAM_DATA_FAILED = "LIVE_STREAM_DATA_FAILED";

// view-count data
export const POST_VIEW_COUNT_INIT = "POST_VIEW_COUNT_INIT";
export const POST_VIEW_COUNT_SUCCESS = "POST_VIEW_COUNT_SUCCESS";
export const POST_VIEW_COUNT_FAILED = "POST_VIEW_COUNT_FAILED";

// post by categoryType
export const GET_ALL_POST_INIT = "GET_ALL_POST_INIT";
export const GET_ALL_POST_SUCCESS = "GET_ALL_POST_SUCCESS";
export const GET_ALL_POST_FAILED = "GET_ALL_POST_FAILED";

//Homepage category Posts
export const HOME_CATEGORY_POST = "HOME_CATEGORY_POST";

//HomePage Top Shows
export const HOME_TOP_SHOWS = "HOME_TOP_SHOWS"

// featured post data
export const GET_ALL_FEATURED_POST_INIT = "GET_ALL_FEATURED_POST_INIT";
export const GET_ALL_FEATURED_POST_SUCCESS = "GET_ALL_FEATURED_POST_SUCCESS";
export const GET_ALL_FEATURED_POST_FAILED = "GET_ALL_FEATURED_POST_FAILED";

// visual story
export const VISUALSTORY_GET_INIT = "VISUALSTORY_GET_INIT";
export const GET_VISUALSTORY_BY_ID_SUCCESS = "GET_VISUALSTORY_BY_ID_SUCCESS";
export const GET_VISUALSTORY_BY_ID_FAILED = "GET_VISUALSTORY_BY_ID_FAILED";
export const CLEAR_VISUALSTORY = "CLEAR_VISUALSTORY";

// Related Articles
export const GET_RELATED_ARTICLES_INIT = "GET_RELATED_ARTICLES_INIT";
export const GET_RELATED_ARTICLES_SUCCESS = "GET_RELATED_ARTICLES_SUCCESS";
export const GET_RELATED_ARTICLES_FAILED = "GET_RELATED_ARTICLES_FAILED";
