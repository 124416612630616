import {
  CATEGORY_GET_INIT,
  SUBCATEGORY_INIT_DATA,
} from "redux/actionTypes/category.actionTypes";

//category actions

export const getCategory = () => {
  return { type: CATEGORY_GET_INIT };
};
// subCategory items
export const subCategoriesItems = (payload) => {
  return { type: SUBCATEGORY_INIT_DATA, payload };
};
