// hashtags

export const HASHTAGS_GET_INIT = "HASHTAGS_GET_INIT";
export const HASHTAGS_GET_SUCCESS = "HASHTAGS_GET_SUCCESS";
export const HASHTAGS_GET_FAILED = "HASHTAGS_GET_FAILED";

// Follow/unfollow hashtags

export const FOLLOW_UNFOLLOW_HASTAGS_INIT = "FOLLOW_UNFOLLOW_HASTAGS_INIT";
export const FOLLOW_UNFOLLOW_HASTAGS_SUCCESS =
  "FOLLOW_UNFOLLOW_HASTAGS_SUCCESS";
export const FOLLOW_UNFOLLOW_HASTAGS_FAILED = "FOLLOW_UNFOLLOW_HASTAGS_FAILED";
