import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import UserReducer from "./userReducer";
import CategoryReducer from "./categoryReducer";
import OpinionReducer from "./opinionReducer";
import HashtagReducer from "./hashtagReducer";
import PostReducer from "./postReducer";
import BookmarkReducer from "./bookmarkReducer";
import SeriesReducer from "./seriesReducer";
import FeedbackReducer from "./feedbackReducer";
import QuizReducer from "./quizReducer";
import CommonReducer from "./commonReducer";
import AdsReducer from "./adsReducer";
import ReviewReducer from "./reviewReducer";
import SearchReducer from "./searchReducer";
import ShortsReducer from "./shortsReducer";
import liveblogReducer from "./liveblogReducer";

const RootReducer = combineReducers({
  AuthReducer,
  CommonReducer,
  UserReducer,
  CategoryReducer,
  OpinionReducer,
  HashtagReducer,
  PostReducer,
  BookmarkReducer,
  SeriesReducer,
  FeedbackReducer,
  QuizReducer,
  AdsReducer,
  ReviewReducer,
  SearchReducer,
  ShortsReducer,
  liveblogReducer
});

export default RootReducer;
