import { getAuthorizedApi } from "api/apiInstance";
import { getAuthorizedApiForLogin } from "api/apiInstance";
import { baseUrl } from "api/constants";
import { logger } from "logging/log-util";
import { signOut } from "next-auth/react";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  AUTH_LOGGEDIN_INIT,
  AUTH_LOGGEDIN_FAILED,
  AUTH_LOGGEDIN_SUCCESS,
  AUTH_OTP_FAILED,
  AUTH_OTP_INIT,
  AUTH_OTP_SUBMIT_INIT,
  AUTH_OTP_SUCCESS,
  AUTH_OTP_SUBMIT_SUCCESS,
  AUTH_OTP_SUBMIT_FAILED,
  AUTH_OTP_RESEND_INIT,
  AUTH_OTP_RESEND_SUCCESS,
  AUTH_OTP_RESEND_FAILED,
  AUTH_DELETE_ACCOUNT,
} from "redux/actionTypes/auth.actionTypes";
import { getTokenFromCookies } from "constants/loginConstants";
// import ClevertapReact from "utils/clevertapHelper";
import { encryptData } from "utils/encryption";
import { SHOW_MODAL_POPUP } from "../actionTypes/common.actionTypes";

function* handleLoggedIn(action) {
  let googleToken = action.payload.GoogleToken
  let isMobile = action.payload.isMobile
  const client = yield getAuthorizedApiForLogin(googleToken);
  delete action.payload.GoogleToken
  delete action.payload.isMobile
  console.log("isMobile", isMobile)

  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/auth/login`,
      action.payload
    );
    console.log("login from redux", data)

    if (data?.status) {
      const encryptedToken = encryptData(data?.result?.token);
      const userId = data?.result?._id;
      console.log("login success from redux-> encrypted token", encryptedToken, "data", data)
      yield put({
        type: AUTH_LOGGEDIN_SUCCESS,
        payload: {
          encryptedToken: encryptedToken,
          entity: data?.result,
          sessionObj: {
            id: userId,
            email: data.result?.email,
            image: data.result?.profileImage,
            name: data.result?.firstname,
          },
        },
      });
      yield fetch("/api/saveToken", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ encryptedToken, userId }),
      });

      localStorage.setItem(
        "lallantopSession2",
        JSON.stringify({
          id: userId,
          email: data.result?.email,
          image: data.result?.profileImage,
          name: data.result?.firstname,
        })
      );
    } else {
      // if (!isMobile) {
      //   yield put({
      //     type: AUTH_LOGGEDIN_FAILED,
      //     payload: {
      //       error: true,
      //       message: data?.message || "Error Occured",
      //     },
      //   });
      // }
      localStorage.removeItem("lallantopSession2");
      localStorage.removeItem("imageData");
      signOut({ redirect: false });
      yield put({
        type: AUTH_LOGGEDIN_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.warn(e);
    localStorage.removeItem("lallantopSession2");
    localStorage.removeItem("imageData");
    signOut({ redirect: false });
    yield put({
      type: AUTH_LOGGEDIN_FAILED,
      payload: {
        error: true,
        message: e?.message || "Error Occured",
      },
    });
    // console.warn(e);
  }
}

function* handleSubmitOTP(action) {
  const client = yield getAuthorizedApi();
  let isMobile = action?.payload?.isMobile
  delete action?.payload?.isMobile
  console.log("isMobile handleSubmitOTP", isMobile)
  try {
    const { data } = yield call(
      client.put,
      `${baseUrl}/auth/verify-otp`,
      action.payload
    );
    if (data.status) {
      const encryptedToken = encryptData(data?.result?.token);
      const userId = data?.result?._id;
      // ClevertapReact.event("app_login_method", {
      //   Facebook: false,
      //   Google: false,
      //   Email: false,
      //   Phone: true,
      // });
      // ClevertapReact.event("app_login_otp_verify");

      yield put({
        type: AUTH_OTP_SUBMIT_SUCCESS,
        payload: {
          encryptedToken: encryptedToken,
          entity: data?.result,
          //userLocalId: data?.result._id
          sessionObj: {
            id: userId,
            email: data.result?.email,
            image: data.result?.profileImage,
            name: data.result?.firstname,
          },
        },
      });
      yield fetch("/api/saveToken", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ encryptedToken, userId }),
      });

      localStorage.setItem(
        "lallantopSession2",
        JSON.stringify({
          id: userId,
          email: data.result?.email,
          image: data.result?.profileImage,
          name: data.result?.firstname,
        })
      );
      // yield put({
      //   type: SHOW_MODAL_POPUP,
      //   payload: {
      //     error: false,
      //     message: "Logged In Successfully!",
      //   },
      // });
    } else {
      // ClevertapReact.event("app login confirm");
      // ClevertapReact.event("app_login_otp_verify");
      yield put({
        type: isMobile ? AUTH_OTP_SUBMIT_FAILED : SHOW_MODAL_POPUP,
        payload: {
          error: true,
          message: data?.message || "Invaild OTP",
        },
      });
    }
  } catch (e) {
    // ClevertapReact.event("app login confirm");
    let count = localStorage.getItem("loginCount") ? Number(localStorage.getItem("loginCount")) : 0; 
    localStorage.setItem("loginCount", count + 1)
    yield put({
      type: isMobile ? AUTH_OTP_SUBMIT_FAILED : SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: "Otp does not match"
      },
      callbackurl: "/login",
    });
    logger.warn(e);
  }
}

function* handleOTP(action) {
  let isMobile = action?.payload?.isMobile
  delete action?.payload?.isMobile
  const client = yield getAuthorizedApi();
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/auth/login`,
      action.payload
    );

    if (data.status) {
      yield put({
        type: AUTH_OTP_SUCCESS,
        payload: {
          entity: data?.result,
        },
      });
    } else {
      yield put({
        type: isMobile ? AUTH_OTP_FAILED : SHOW_MODAL_POPUP,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    const message = e.toString().includes('400') ? 'OTP limit exceeded' : (e.toString().includes('422') ? "Check your number" : new Error(e).message)
    yield put({
      type: isMobile ? AUTH_OTP_FAILED : SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message,
      },
    });
  }
}

function* handleResendOTP(action) {
  const client = yield getAuthorizedApi();
  let isMobile = action?.payload?.isMobile
  delete action?.payload?.isMobile

  try {
    const { data } = yield call(
      client.put,
      `${baseUrl}/auth/resend-otp`,
      action.payload
    );

    if (data.status) {
      // ClevertapReact.event("app_login_otp_verify");
      yield put({
        type: AUTH_OTP_RESEND_SUCCESS,
        payload: {
          entity: data?.result,
        },
      });
    } else {
      yield put({
        type: isMobile ? AUTH_OTP_RESEND_FAILED : SHOW_MODAL_POPUP,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    const message = e.toString().includes('400') ? 'OTP limit exceeded' : (e.toString().includes('422') ? "Check your number" : new Error(e).message)
    yield put({
      type: isMobile ? AUTH_OTP_RESEND_FAILED : SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: message,
      },
    });
  }
}
function* handleDelete() {
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  // let isMobile = action?.payload?.isMobile
  // delete action?.payload?.isMobile
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/user/delete-profile`,
    );

    if (data.status) {
      localStorage.removeItem("lallantopUser");
      localStorage.removeItem("lallantopSession2");
      yield put({
        type: SHOW_MODAL_POPUP,
        payload: {
          error: false,
          message: data?.message,
        },
      });
    } else {
      yield put({
        type: SHOW_MODAL_POPUP,
        payload: {
          error: true,
          message: data?.message,
        },
      });
    }
    return;
  } 
  catch (e) {
    const message = e.toString().includes('400') ? 'Some Issue Occured' : (e.toString().includes('422') ? "Some Issue Occured" : new Error(e).message)
    yield put({
      type: isMobile ? AUTH_OTP_RESEND_FAILED : SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: message,
      },
    });
  }
}

function* authSaga() {
  yield takeLatest(AUTH_OTP_INIT, handleOTP);
  yield takeLatest(AUTH_OTP_RESEND_INIT, handleResendOTP);
  yield takeLatest(AUTH_OTP_SUBMIT_INIT, handleSubmitOTP);
  yield takeLatest(AUTH_LOGGEDIN_INIT, handleLoggedIn);
  yield takeLatest(AUTH_DELETE_ACCOUNT,handleDelete);
}

export default authSaga;
