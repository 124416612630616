import { getAuthorizedApi } from "api/apiInstance";
import { isHindiText } from "api/commonConstants";
import { baseUrl } from "api/constants";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_SEARCH_DROPDOWN_FAILED,
  GET_SEARCH_DROPDOWN_INIT,
  GET_SEARCH_DROPDOWN_SUCCESS,
  RESET_SEARCH_DROPDOWN_SUCCESS,
  RESET_SEARCH_DROPDOWN
} from "redux/actionTypes/search.actionTypes";
import { SHOW_MODAL_POPUP } from "../actionTypes/common.actionTypes";
import { getTokenFromCookies } from "constants/loginConstants";
import { logger } from "logging/log-util";

function* getSearch(action) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json());
  // //  .then(({ encryptedToken }) => encryptedToken || "");
  // if (typeof token === 'string') {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === 'object') {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(client.get, `${baseUrl}/search/dropdown?page=1&limit=25${isHindiText(action.payload) ? `&language=Hindi` : ""}&search=${action.payload}`);

    if (data.status) {
      yield put({
        type: GET_SEARCH_DROPDOWN_SUCCESS,
        payload: {
          dropdown: data?.result,
        },
      });
    } else {
      yield put({
        type: GET_SEARCH_DROPDOWN_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: new Error(e).message,
      },
    });
  }
}
function* resetSearch() {
  yield put({
    type: RESET_SEARCH_DROPDOWN_SUCCESS,
  });
}


function* searchSaga() {
  yield takeLatest(GET_SEARCH_DROPDOWN_INIT, getSearch);
  yield takeLatest(RESET_SEARCH_DROPDOWN, resetSearch);
}

export default searchSaga;
