import { getAuthorizedApi } from "api/apiInstance";
import { baseUrl } from "api/constants";
import { call, put, takeLatest } from "redux-saga/effects";
import { SHOW_MODAL_POPUP } from "../actionTypes/common.actionTypes";
import { getTokenFromCookies } from "constants/loginConstants";
import {
  GET_FEEDBACK_LIST_FAILED,
  GET_FEEDBACK_LIST_INIT,
  GET_FEEDBACK_LIST_SUCCESS,
  SAVE_FEEDBACK_FAILED,
  SAVE_FEEDBACK_INIT,
  SAVE_FEEDBACK_SUCCESS,
} from "../actionTypes/feedbackTypes";
import { logger } from "logging/log-util";

function* getFeedbackList() {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json())
  //     /*.then(({encryptedToken})=> encryptedToken || "")*/;
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(client.get, `${baseUrl}/feedback/reason`);
    if (data.status) {
      yield put({
        type: GET_FEEDBACK_LIST_SUCCESS,
        payload: {
          feedbacks: data?.result?.feedbacks,
        },
      });
    } else {
      yield put({
        type: GET_FEEDBACK_LIST_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: new Error(e).message,
      },
    });
  }
}

function* saveFeedback({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json())
  //     /*.then(({encryptedToken})=> encryptedToken || "")*/;
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/feedback`,
      payload.postData
    );

    if (data.status) {
      yield put({
        type: SAVE_FEEDBACK_SUCCESS,
        payload: {
          message: data.message,
        },
      });
      // yield put({
      //   type: SHOW_MODAL_POPUP,
      //   payload: {
      //     message: "Submitted",
      //   },
      // });
    } else {
      yield put({
        type: SAVE_FEEDBACK_FAILED,
        payload: {
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    yield put({
      type: SHOW_MODAL_POPUP,
      payload: {
        error: true,
        message: new Error(e).message,
      },
    });
  }
}

function* feedbackSaga() {
  yield takeLatest(GET_FEEDBACK_LIST_INIT, getFeedbackList);
  yield takeLatest(SAVE_FEEDBACK_INIT, saveFeedback);
}

export default feedbackSaga;
