import { getAuthorizedApi } from "api/apiInstance";
import { baseUrl } from "api/constants";
import { call, put, takeLatest } from "redux-saga/effects";
import { getTokenFromCookies } from "constants/loginConstants";
import {
  EMAIL_NOTIFICATION_FAILED,
  EMAIL_NOTIFICATION_INIT,
  EMAIL_NOTIFICATION_SUCCESS,
  USER_PREFERENCES_UPDATE_FAILED,
  USER_PREFERENCES_UPDATE_INIT,
  USER_PREFERENCES_UPDATE_SUCCESS,
  USER_PROFILE_BY_ID_FAILED,
  USER_PROFILE_BY_ID_INIT,
  USER_PROFILE_BY_ID_SUCCESS,
  USER_PROFILE_UPDATE_FAILED,
  USER_PROFILE_UPDATE_INIT,
  // USER_PROFILE_UPDATE_SUCCESS,
} from "redux/actionTypes/user.actionTypes";
import { encryptData } from "utils/encryption";
import { SHOW_MODAL_POPUP } from "../actionTypes/common.actionTypes";
import { logger } from "logging/log-util";

function* getProfileById({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // })
  //   .then((res) => res.json());
  //   /* .then(({ encryptedToken }) => encryptedToken || "");*/
  //   if(token &&  typeof token === 'string' ) {
  //     token  = JSON.parse(token);
  //     if(token.encryptedToken) {
  //       token = token.encryptedToken;
  //     }
  //   }else if(typeof token === 'object') {
  //     if(token.encryptedToken) {
  //       token = token.encryptedToken;
  //     }
  //   }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);

  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/user/detail/${payload._id}`
    );

    if (data.status) {
      // localStorage.setItem("userToken", data.data?.userToken);
      data?.result?.profileImage &&
        localStorage.setItem("imageData", data?.result?.profileImage);
      yield put({
        type: USER_PROFILE_BY_ID_SUCCESS,
        payload: {
          userData: data?.result,
        },
      });
    } else {
      yield put({
        type: USER_PROFILE_BY_ID_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    if (e?.response?.status <= 500) {
      yield put({
        type: SHOW_MODAL_POPUP,
        payload: {
          error: true,
          message: e.response?.data?.message,
        },
      });
    } else {
      console.log("@@Error occured -", e.response?.data?.message);
    }
  }
}

function* handleProfile({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // })
  //   .then((res) => res.json())/*
  //   .then(({ encryptedToken }) => encryptedToken || "")*/;
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.put,
      `${baseUrl}/user/update-profile`,
      payload
    );
    if (data.status) {
      const encryptedToken = encryptData(data?.result?.token);
      data?.result?.profileImage &&
        localStorage.setItem("imageData", data?.result?.profileImage);
      // localStorage.setItem("userToken", data.data?.userToken);
      yield put({
        type: USER_PROFILE_BY_ID_SUCCESS,
        payload: {
          encryptedToken: encryptedToken,
          userData: data?.result,
        },
      });
      // const language = JSON.parse(localStorage.getItem('i18nConfig'))
      // yield put({
      //   type: SHOW_MODAL_POPUP,
      //   payload: {
      //     error: false,
      //     message: language?.selectedLang === "hi" ? "प्रोफ़ाइल सफलतापूर्वक अपडेट की गई": "Profile updated succesfully!",
      //   },
      // });
    } else {
      yield put({
        type: USER_PROFILE_UPDATE_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    console.log("@@error", e.response);
    if (e?.response?.status <= 500) {
      yield put({
        type: SHOW_MODAL_POPUP,
        payload: {
          error: true,
          message: e.response?.data?.message || "Error Occured",
        },
      });
    } else {
      console.log("@@Error occured -", e.response?.data?.message);
    }
  }
}

function* getEmailNotification({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json"
  //   }
  // }).then(res => res.json())/*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/;
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/user/updateEmailNotificationStatus`,
      payload
    );
    if (data.status) {
      // localStorage.setItem("userToken", data.data?.userToken);
      yield put({
        type: EMAIL_NOTIFICATION_SUCCESS,
      });
      // yield put({
      //   type: SHOW_MODAL_POPUP,
      //   payload: {
      //     error: false,
      //     message: "Setting saved!",
      //   },
      // });
    } else {
      yield put({
        type: EMAIL_NOTIFICATION_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    if (e?.response?.status <= 500) {
      yield put({
        type: SHOW_MODAL_POPUP,
        payload: {
          error: true,
          message: e.response?.data?.message,
        },
      });
    } else {
      console.log("@@Error occured -", e.response?.data?.message);
    }
  }
}

function* handlePreferences({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json"
  //   }
  // }).then(res => res.json())/*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/;
  // if(token &&  typeof token === 'string' ) {
  //   token  = JSON.parse(token);
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }else if(typeof token === 'object') {
  //   if(token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.put,
      `${baseUrl}/user/update-preferences`,
      payload
    );
    if (data.status) {
      // localStorage.setItem("userToken", data.data?.userToken);
      yield put({ type: USER_PREFERENCES_UPDATE_SUCCESS });
      const language = JSON.parse(localStorage.getItem("i18nConfig"));
      yield put({
        type: SHOW_MODAL_POPUP,
        payload: {
          error: false,
          callbackurl: "/",
          message:
            language?.selectedLang === "en"
              ? "Preferences updated successfully"
              : "प्रेफ़ेरन्सेस सफलतापूर्वक अपडेट की गई",
        },
      });
    } else {
      yield put({
        type: USER_PREFERENCES_UPDATE_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    if (e?.response?.status <= 500) {
      yield put({
        type: SHOW_MODAL_POPUP,
        payload: {
          error: true,
          message: e.response?.data?.message,
        },
      });
    } else {
      console.log("@@Error occured -", e.response?.data?.message);
    }
  }
}

function* userSaga() {
  yield takeLatest(USER_PROFILE_UPDATE_INIT, handleProfile);
  yield takeLatest(USER_PROFILE_BY_ID_INIT, getProfileById);
  yield takeLatest(EMAIL_NOTIFICATION_INIT, getEmailNotification);
  yield takeLatest(USER_PREFERENCES_UPDATE_INIT, handlePreferences);
}

export default userSaga;
