import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
};
// const firebaseConfig = {
//   apiKey: "AIzaSyBd9I1B7T3yq5xXY5HoU69k1OIcGSBbnHE",
//   authDomain: "lallantop-dev-c5e4c.firebaseapp.com",
//   databaseURL: "https://lallantop-dev-c5e4c-default-rtdb.firebaseio.com",
//   projectId: "lallantop-dev-c5e4c",
//   storageBucket: "lallantop-dev-c5e4c.appspot.com",
//   messagingSenderId: "606538662701",
//   appId: "1:606538662701:web:b9fd13f9130e72f8f689a4",
//   measurementId: "G-KGFH4PGD46"
// };

const app = initializeApp(firebaseConfig,"appcheck");

export default app;
