
export function getCtxPayload(context, extraParams = {}) {
  if (context.req) {
    return {
      url: context?.req?.url,
      method: context?.req?.method,
      "User-Agent": context?.req?.headers["user-agent"],
      params: context?.params,
      query: context?.query,
      props: context?.pageProps,
      ...extraParams,
    };
  }
  return {};
}

export function maskTokenFromStart(token) {
  if (token) {
    const visibleLength = Math.ceil(token.length / 20); // Keep 1/5th of the length visible from the beginning
    const maskedLength = token.length - visibleLength;
    const maskedPart = "*".repeat(maskedLength / 20);
    const visiblePart = token.slice(token.length - visibleLength, token.length);
    return `${maskedPart}${visiblePart}`;
  }
  return "";
}
export function maskHalfKey(token) {
  if (token) {
    const visibleLength = Math.ceil(token.length / 2);
    const maskedLength = token.length - visibleLength;
    const maskedPart = "*".repeat(maskedLength / 2);
    const visiblePart = token.slice(0, visibleLength);
    return `${maskedPart}${visiblePart}`;
  }
  return "";
}
export function getMaskedHeader(header) {
  return {
    ...header,
    authorization: maskTokenFromStart(header?.authorization),
    "X-Firebase-AppCheck": maskHalfKey(header["X-Firebase-AppCheck"]),
  };
}
export function getMaskedResponse(response) {
  const logHeaders = getMaskedHeader(response?.config?.headers)
  return {
    headers: logHeaders,
    method: response?.config?.method,
    url: response?.config?.url,
    data: response?.config?.data,
    status: response?.status,
    statusText: response?.statusText,
    label: "SSR : API Request"
  };
}

export function maskPhoneNumberFromStart(phoneNumber) {
  const visibleDigits = Math.ceil(phoneNumber.length / 5); // Keep 1/5th of the digits visible from the end
  const maskedDigits = phoneNumber.length - visibleDigits;
  const maskedPart = "*".repeat(maskedDigits);
  const visiblePart = phoneNumber.slice(-visibleDigits);
  return `${maskedPart}${visiblePart}`;
}
export function maskEmail(email) {
  const [username, domain] = email.split("@");
  const maskedUsername =
    username.substring(0, 2) + "*".repeat(username.length - 2);
  return `${maskedUsername}@${domain}`;
}
