import {
    BLOGS_LIST_SUCCESS
  } from "redux/actionTypes/liveblogactionTypes";
  
  const iniitialState = {
    blogsList: [],
    error: false,
    message: "",
  };
  
  export default function liveblogReducer(state = iniitialState, action) {
    switch (action.type) {
      // Do something here based on the different types of actions
  
      case BLOGS_LIST_SUCCESS:
        return {
          ...state,
          blogsList: action.payload.entity.entities,
          error: false,
        };
  
      default:
        // If this reducer doesn't recognize the action type, or doesn't
        // care about this specific action, return the existing state unchanged
        return state;
    }
  }
  