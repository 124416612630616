import { useOneTapSignin } from "hooks/useOneTapSignin";

const OneTapGoogleSignIn = () => {

  const { isLoading } = useOneTapSignin({
    redirect: false,
    parentContainerId: "oneTap",
  });

  return (
    <div id="oneTap" style={{ position: "fixed", top: "20%", right: "0", zIndex: 99999 }} />
  );
};

export default OneTapGoogleSignIn;
